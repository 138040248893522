import React, { forwardRef, MouseEvent, ReactNode, useEffect, useMemo, useRef, useState, Ref } from 'react';
import styled from 'styled-components/macro';
import { format, parseISO } from 'date-fns';
import Linkify from 'linkifyjs/react';
import he from 'he';
import {
  ChannelViewChannelMessageFragment,
  MessageType,
  UserType,
  ChannelMessageAttachmentType,
  MessageSource,
  ChannelViewChannelFragment,
  Role,
  useUpdateChannelAssigneesMutation,
  ChannelStatusAction,
  ClinicEntityCreationSource,
  ClinicUserAvatarFragment,
  useHasDeviceThatCanRecievePushQuery,
  useFindManyClinicBlockedPhoneNumberQuery,
  SmsNotificationStatusType,
  GetClinicChannelViewChannelQuery,
  ChannelViewChannelMemberFragment,
  SmsNotificationStatusFragment,
} from 'shared/types/graphql';
import useClinicUser from 'shared/hooks/useClinicUser';
import useSmsNotificationStatus from '../../../hooks/useSmsNotificationStatus';
import MessageBubble from '../MessageBubble';
import WidgetRequestMessage from './WidgetRequestMessage';
import AppointmentRequestMessage from './AppointmentRequestMessage';
import { getClinicPetParentDisplayName, removePhoneCountryCode } from 'shared/utils';
import { TwilioIdentity } from '@televet/shared-types/twilio';
import { IMap } from 'shared/interfaces/IMap';
import Dropdown, { IOption, PopoverPlacement } from 'shared/components/Dropdown';
import DeleteMessageAlertDialog from '../DeleteMessageAlertDialog';
import MoreOptionsButton from 'shared/components/MoreOptionsButton';
import { useUnreadMessageCountProvider } from 'shared/providers/UnreadMessageCountProvider';
import MessageReactions from './MessageReactions';
import { Mixpanel } from 'shared/utils/mixpanel';
import { INotificationErrorTextProps } from '../../ChannelView';
import {
  Box,
  Icon,
  useToast,
  Text,
  VStack,
  UnorderedList,
  ListItem,
  HStack,
  Tooltip,
  Popover,
  Flex,
  useDisclosure,
} from '@televet/kibble-ui';
import { formatPhoneNumber } from 'shared/utils';
import ServiceReminderNotificationAttachment from '../MessageBubble/Attachments/ServiceReminderNotification';
import { ClinicAvatar, PetParentAvatar } from 'shared/components/Avatars';
import { GraphQLFetchPolicies } from 'shared/enums';
import BlockPhoneNumberModal from 'pages/Settings/pages/TextMessagingSettings/BlockedPhoneNumbers/BlockPhoneNumberModal';

import { useTriggerMessageEventAndSubscribe } from 'shared/hooks/useTriggerMessageEventAndSubscribe';
import { SqsQueueNames } from '@televet/shared-types/SqsQueueNames';
import { NotificationRoutingKey, NotificationType } from '@televet/shared-types';
import DeliveryStatus from './DeliveryStatus';

const retryAbleSmsStatuses: SmsNotificationStatusType[] = [
  SmsNotificationStatusType.Error,
  SmsNotificationStatusType.SendError,
];
export interface IAuthor {
  id: string;
  firstName: string;
  lastName: string;
  profilePic?: string;
  type: UserType;
  nameDisplay?: string;
  displayName?: string;
  creationSource?: ClinicEntityCreationSource | null | undefined;
  isTest?: boolean | null | undefined;
}

interface IMessageGroupProps {
  message: ChannelViewChannelMessageFragment;
  isFirstMessage: boolean;
  isLastMessage: boolean;
  isOneBeforeLastMessage: boolean;
  isLastMessageSystemMessage: boolean;
  channelData?: ChannelViewChannelFragment | null;
  clinicPetParentToken?: string;
  workflowEventSettingsById: IMap<string>;
  setPreviewData: (fileUrl: string, attachmentType: ChannelMessageAttachmentType, fileName: string) => void;
  onFirstMessageMount: () => void;
  onLastMessageMount: (message: ChannelViewChannelMessageFragment, element: HTMLDivElement) => void;
  setCurrentInvoiceId: (invoiceId: string) => void;
  setInvoiceClinicPetParentId: (invoiceClinicPetParentId: string) => void;
  phoneWarnings: INotificationErrorTextProps[];
  emailWarnings: INotificationErrorTextProps[];
  channelMemberId: string | undefined;
  clinicPetParents: NonNullable<
    GetClinicChannelViewChannelQuery['findUniqueChannel']
  >['channelMembers'][0]['clinicPetParent'][];
  seenByMembers: ChannelViewChannelMemberFragment[];
  isLastVisibleMessage: boolean;
  isTeamChannel: boolean;
  currentUserId?: string;
  onSmsStatusChange: (status: SmsNotificationStatusFragment, messageId: string) => void;
  smsNotificationStatus: SmsNotificationStatusFragment;
}

enum MessageDropdown {
  DeleteMessage = 'Delete Message',
  MarkAsUnread = 'Mark Message as Unread',
  BlockNumber = 'Block this Number',
  RetrySend = 'Resend',
}

interface INotificationErrorText {
  phoneWarnings: INotificationErrorTextProps[];
  emailWarnings: INotificationErrorTextProps[];
}

const SharedErrorComponent = ({ warnings }: { warnings: INotificationErrorTextProps[] }): JSX.Element => {
  return (
    <VStack spacing={1} justify="start" align="start">
      <Text size="sm" fontWeight="bold" variant="onContrast">
        {warnings[0].headers[1]}
      </Text>
      <UnorderedList fontWeight="regular" pl={'6'}>
        {warnings.map((warning: INotificationErrorTextProps): JSX.Element | JSX.Element[] => {
          return warning.reason.map((warningText, reasonIndex) => (
            <ListItem fontWeight="regular" key={reasonIndex}>
              <Text fontWeight="regular" variant="onContrast" size="sm">
                {warning.title}: {warningText}
              </Text>
            </ListItem>
          ));
        })}
      </UnorderedList>
    </VStack>
  );
};

const NotificationsErrorText = ({ phoneWarnings, emailWarnings }: INotificationErrorText): JSX.Element => {
  return (
    <VStack justify="start" align="start" spacing={1}>
      {phoneWarnings !== undefined && phoneWarnings?.length > 0 && <SharedErrorComponent warnings={phoneWarnings} />}

      {emailWarnings !== undefined && emailWarnings?.length > 0 && <SharedErrorComponent warnings={emailWarnings} />}
    </VStack>
  );
};

type MessageGroupType = 'default' | 'note' | 'automated';

interface MessageGroupContainerProps {
  children: ReactNode;
  messageType: MessageGroupType;
}
const MessageGroupContainer = forwardRef((props: MessageGroupContainerProps, ref: Ref<HTMLDivElement>): JSX.Element => {
  const { messageType, children } = props;

  const bg: Record<MessageGroupType, string> = {
    default: 'background.default',
    automated: 'background.primaryAlpha',
    note: 'background.note',
  };
  const bgHover: Record<MessageGroupType, string> = {
    default: 'background.subtle',
    automated: 'background.primaryAlpha',
    note: 'background.note',
  };
  return (
    <HStack
      ref={ref}
      justify="flex-start"
      align="stretch"
      py={4}
      px={5}
      role="group"
      position="relative"
      bg={bg[messageType]}
      _hover={{ bg: bgHover[messageType] }}
    >
      {children}
    </HStack>
  );
});

MessageGroupContainer.displayName = 'MessageGroupContainer';

const MessageGroup = ({
  message,
  channelData,
  isFirstMessage,
  isLastMessage,
  isOneBeforeLastMessage,
  isLastMessageSystemMessage,
  workflowEventSettingsById,
  setPreviewData,
  onFirstMessageMount,
  onLastMessageMount,
  setCurrentInvoiceId,
  setInvoiceClinicPetParentId,
  phoneWarnings,
  emailWarnings,
  channelMemberId,
  clinicPetParents,
  seenByMembers,
  isLastVisibleMessage,
  isTeamChannel,
  currentUserId,
  onSmsStatusChange,
  smsNotificationStatus,
}: IMessageGroupProps): JSX.Element | null => {
  const { isOpen: isBlockNumberOpen, onOpen: onBlockNumberOpen, onClose: onBlockNumberClose } = useDisclosure();
  const { clinicUser, currentClinic } = useClinicUser();
  const toast = useToast();
  const { updateLastConsumedMessageIndex } = useUnreadMessageCountProvider();

  const { data: blockedNumbersData } = useFindManyClinicBlockedPhoneNumberQuery({
    variables: { where: { clinicId: { equals: currentClinic?.id } } },
  });

  const isNumberBlocked = useMemo(() => {
    const blockedNumbers = blockedNumbersData?.findManyClinicBlockedPhoneNumber
      ?.filter((entry) => !entry?.unblockedBy)
      .map((blockedNumber) => {
        return blockedNumber?.phoneNumber;
      });
    const cleanNumber = removePhoneCountryCode(message.sourcePhoneNumber || '');
    return blockedNumbers?.indexOf(cleanNumber) !== -1;
  }, [blockedNumbersData?.findManyClinicBlockedPhoneNumber, message.sourcePhoneNumber]);

  const authorIsPetParent = useMemo(() => !!message.author?.clinicPetParent, [message]);
  const petParentClinicEntityPhoneNumber = useMemo(
    () =>
      clinicPetParents?.[0]?.phoneNumbers.find((x) => x.isPrimary) ||
      clinicPetParents?.[0]?.phoneNumbers.find((x) => x.isMobile),
    [clinicPetParents],
  );
  const shouldCallFirstMessageMount = useRef(isFirstMessage);
  const shouldCallLastMessageMount = useRef(isLastMessage);
  const [messageIdToDelete, setMessageIdToDelete] = useState<string | null>(null);
  const { triggerMessageEvent, isTriggerMessageEventLoading } = useTriggerMessageEventAndSubscribe();
  const [updateChannelAssignees] = useUpdateChannelAssigneesMutation();

  const containerRef = (element: HTMLDivElement): void => {
    if (isFirstMessage && element && shouldCallFirstMessageMount.current) {
      shouldCallFirstMessageMount.current = false;
      onFirstMessageMount();
    }

    if (isLastMessage && element && shouldCallLastMessageMount.current) {
      shouldCallLastMessageMount.current = false;
      onLastMessageMount(message, element);
    }
  };
  const handleMessageMenuOptionClick = (e: MouseEvent, option: IOption): void => {
    if (option.text === MessageDropdown.RetrySend) {
      triggerMessageEvent({
        variables: {
          event: NotificationRoutingKey.SendMessageNotification,
          queueName: SqsQueueNames.NotificationJobs,
          useSubscription: true,
          data: {
            messageId: message.id,
            type: NotificationType.Text,
            event: 'NewMessageSent',
            forceSend: true,
          },
        },
      });
    }
    if (option.text === MessageDropdown.DeleteMessage) {
      setMessageIdToDelete(option.value);
    }

    if (option.text === MessageDropdown.MarkAsUnread && channelMemberId) {
      const notificationSettings = clinicUser?.userSetting?.browserNotificationFilter;
      const isAssignedToChannel = channelData?.assignees?.filter((assignee) => assignee.id === clinicUser?.id);
      const hasNotificationFilterSetToAssignedOnly = notificationSettings?.assignees.length;
      const hasNotificationFilterSetToNoOne =
        !notificationSettings?.assignees.length && notificationSettings?.includeUnassigned;

      if (hasNotificationFilterSetToNoOne && channelData?.assignees?.length) {
        toast({
          title:
            "Based on your settings, you currently only receive notifications for conversations that aren't assigned to anyone.",
          description:
            "This conversation is currently assigned to other team members. If you'd still like to mark this message as unread, please remove the other team members or update your preferences by visiting the My Notifications settings page.",
          status: 'info',
          duration: null,
          position: 'top',
        });
      }

      if (!isAssignedToChannel?.length && hasNotificationFilterSetToAssignedOnly) {
        try {
          toast({
            title: "You've been assigned to the conversation",
            description:
              "Based on your settings you currently only receive notifications for conversations you're assigned to. If you'd like to update your preferences please visit the My Notifications settings page.",
            status: 'info',
            duration: 10000,
            position: 'top',
          });

          if (!clinicUser?.id) return;
          const newAssigneesList = channelData?.assignees.map(({ id }) => ({ id })).concat({ id: clinicUser?.id });
          updateChannelAssignees({
            variables: {
              where: {
                id: message.channel?.id,
              },
              data: {
                twilioChannelSid: channelData?.twilioChannelSid,
                assignees: {
                  set: newAssigneesList,
                },
              },
            },
          });
        } catch {
          toast({
            title: 'Sorry',
            description: 'We are having trouble processing your request. Please try again',
            status: 'error',
            duration: 10000,
            position: 'top',
          });
        }
      }

      updateLastConsumedMessageIndex(option.value - 1, channelMemberId);

      Mixpanel.track('Message in conversation marked as unread');
    }
    if (option.text === MessageDropdown.BlockNumber) {
      onBlockNumberOpen();
    }
  };

  const author = useMemo(() => {
    const channelMember = message.author;
    const isPetParent = !!channelMember?.clinicPetParent || channelMember?.user?.type === UserType.Owner;
    return {
      ...(channelMember?.user || channelMember?.clinicPetParent),
      type: isPetParent ? UserType.Owner : UserType.Vet,
    } as IAuthor;
  }, [message]);

  const authorIsClinic = useMemo(
    () =>
      !!message.author?.twilioIdentity &&
      (message.author.twilioIdentity === TwilioIdentity.Clinic ||
        message.author.twilioIdentity === TwilioIdentity.AutoResponse ||
        message.author.twilioIdentity === TwilioIdentity.System),
    [message],
  );

  const authorFullName = authorIsPetParent
    ? getClinicPetParentDisplayName(author)
    : authorIsClinic
    ? channelData?.clinic?.name || 'Your Clinic'
    : author.nameDisplay || [author?.firstName, author?.lastName].join(' ').trim();

  const clinicPetParentInfo = channelData?.channelMembers
    ?.filter((m) => m.clinicPetParent && m.clinicPetParent.isDeleted === false)
    .map((m) => m.clinicPetParent);

  const clinicPetParentId = clinicPetParentInfo && clinicPetParentInfo.find((m) => !!m?.id)?.id;

  useEffect(() => {
    setInvoiceClinicPetParentId(clinicPetParentId || '');
  }, [clinicPetParentId, setInvoiceClinicPetParentId]);

  const hasSmsConversations = currentClinic?.hasSmsConversations;

  // grab sms notification for last or one before last if its before a system message
  const { isLoading: isSmsNotificationStatusLoading } = useSmsNotificationStatus({
    messageId: message?.id,
    skip: !hasSmsConversations || (!isOneBeforeLastMessage && !isLastMessage),
    onSmsStatusChange
  });

  const isSystemMessage = message.author?.twilioIdentity === TwilioIdentity.System;
  const isAutomationMessage = authorIsClinic && message.attributes !== null;
  const isWidgetRequest =
    isSystemMessage &&
    !!message.attachments?.length &&
    (message.attachments[0].attachmentType === ChannelMessageAttachmentType.WidgetRequest ||
      message.attachments[0].attachmentType === ChannelMessageAttachmentType.PetParentRequest);
  const isServiceReminderNotification =
    isSystemMessage &&
    message.attachments?.[0]?.attachmentType === ChannelMessageAttachmentType.ServiceReminderNotification;
  const isServiceReminderSubmission =
    isSystemMessage &&
    !!message.attachments?.length &&
    message.attachments[0].attachmentType === ChannelMessageAttachmentType.ServiceReminderFormSubmission;
  const isMessageDeleted = message.deletedAt !== null;
  const isAdmin = useMemo(() => clinicUser?.vetInfo?.roles?.some((r) => r.role === Role.Admin), [clinicUser]);
  const canRetrySend = useMemo(() => {
    return (
      !isAutomationMessage &&
      message.messageType === MessageType.Message &&
      !message.author?.clinicPetParent &&
      !isTriggerMessageEventLoading &&
      ((petParentClinicEntityPhoneNumber?.smsStatus &&
        petParentClinicEntityPhoneNumber.smsStatus?.startsWith('Rejected')) ||
        retryAbleSmsStatuses.includes(smsNotificationStatus?.status || SmsNotificationStatusType.Delivered))
    );
  }, [
    message,
    isTriggerMessageEventLoading,
    smsNotificationStatus,
    isAutomationMessage,
    petParentClinicEntityPhoneNumber,
  ]);
  const canDeleteMessage = useMemo(() => {
    return (message.author?.user?.id === clinicUser?.id || isAdmin) && !message.author?.clinicPetParent;
  }, [clinicUser, isAdmin, message]);
  const canMarkMessageUnread = useMemo(() => {
    return (
      message.author?.user?.id !== clinicUser?.id &&
      channelData?.channelStatus?.channelStatusAction !== ChannelStatusAction.InactivePermanently &&
      (message.author?.clinicPetParent || message.messageType === MessageType.Note)
    );
  }, [clinicUser, message, channelData]);

  const canBlockNumber = useMemo(() => {
    return message.source === MessageSource.Sms && message.sourcePhoneNumber && isAdmin && !isNumberBlocked;
  }, [message.source, message.sourcePhoneNumber, isAdmin, isNumberBlocked]);
  const messageOptions = useMemo(() => {
    return [
      ...(canRetrySend
        ? [
            {
              text: MessageDropdown.RetrySend,
              value: message.id,
            },
          ]
        : []),
      ...(canDeleteMessage
        ? [
            {
              text: MessageDropdown.DeleteMessage,
              value: message.id,
            },
          ]
        : []),
      ...(canMarkMessageUnread
        ? [
            {
              text: MessageDropdown.MarkAsUnread,
              value: message.index,
            },
          ]
        : []),
      ...(canBlockNumber
        ? [
            {
              text: MessageDropdown.BlockNumber,
              value: undefined,
            },
          ]
        : []),
    ];
  }, [canRetrySend, message.id, message.index, canDeleteMessage, canMarkMessageUnread, canBlockNumber]);

  const { data: deviceData } = useHasDeviceThatCanRecievePushQuery({
    variables: {
      clinicPetParentIds: clinicPetParentId,
    },
    skip: !clinicPetParentId,
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    nextFetchPolicy: GraphQLFetchPolicies.CacheFirst,
  });

  const hasPushEnabledDevice = useMemo(
    () => !!deviceData?.findFirstClinicPetParentDevice,
    [deviceData?.findFirstClinicPetParentDevice],
  );

  const pushIsEnabled = useMemo(() => {
    const petParent = clinicPetParentInfo && clinicPetParentInfo[0];
    if (hasPushEnabledDevice) {
      return petParent?.petParentSetting?.pushNotifications;
    }
    return false;
  }, [clinicPetParentInfo, hasPushEnabledDevice]);

  const showNotificationError = useMemo(
    () =>
      !authorIsPetParent &&
      message.messageType !== MessageType.Note &&
      isLastMessage &&
      (!!phoneWarnings.length || !!emailWarnings.length),
    [authorIsPetParent, message.messageType, isLastMessage, phoneWarnings, emailWarnings],
  );
  if (isWidgetRequest) {
    return (
      <MessageGroupContainer ref={containerRef} messageType="automated">
        <WidgetRequestMessage message={message} />
      </MessageGroupContainer>
    );
  } else if (isServiceReminderNotification) {
    return <ServiceReminderNotificationAttachment message={message} />;
  } else if (isServiceReminderSubmission) {
    return (
      <MessageGroupContainer ref={containerRef} messageType="automated">
        <AppointmentRequestMessage
          message={message}
          clinicPetParentId={clinicPetParentInfo?.[0]?.id || ''}
          title="Appointment Request"
          subtitle="From Service Reminder"
        />
      </MessageGroupContainer>
    );
  } else if (isSystemMessage && !!message.body) {
    return (
      <SystemMessageContainer ref={containerRef}>
        <Tooltip label={format(parseISO(message.createdAt), "cccc',' PP 'at' p")}>
          <Box textAlign="center" mx={8} my={8} className="SystemMessage">
            <Linkify tagName="span" options={{ target: '_blank' }}>
              <Text size="xs" variant="subtle">
                {he.decode(message.body)}
              </Text>
            </Linkify>
          </Box>
        </Tooltip>
      </SystemMessageContainer>
    );
  } else {
    return (
      <MessageGroupContainer ref={containerRef} messageType={message.messageType === 'Note' ? 'note' : 'default'}>
        <AvatarContainer>
          {authorIsPetParent ? (
            <PetParentAvatar clinicPetParent={author} />
          ) : authorIsClinic ? (
            <ClinicAvatar isClinic />
          ) : (
            <ClinicAvatar clinicMember={author as ClinicUserAvatarFragment} />
          )}
        </AvatarContainer>
        <MessageBubblesContainer data-testid="message-bubble-container">
          <HStack className="MessageGroup__AuthorName" align="center" mb={2}>
            <Text fontWeight="bold">{authorFullName}</Text>
            <Tooltip label={format(parseISO(message.createdAt), "cccc',' PP 'at' p")}>
              <Timestamp>{format(parseISO(message.createdAt), 'h:mma')}</Timestamp>
            </Tooltip>
            {message.source === MessageSource.Sms && authorIsPetParent && (
              <Tooltip
                label={`${getClinicPetParentDisplayName(author)} sent this as a text message from ${
                  message?.sourcePhoneNumber ? formatPhoneNumber(message?.sourcePhoneNumber) : 'Unknown number'
                }`}
              >
                <Flex>
                  <Icon size="sm" variant="subtle" name="cellphone" />
                </Flex>
              </Tooltip>
            )}
            {message?.attributes?.workflowSettingId && (
              <Tooltip
                label={`${workflowEventSettingsById[message?.attributes?.workflowSettingId] || 'Automation'} Message`}
              >
                <Flex>
                  <Icon name="robot" size="sm" variant="subtle" />
                </Flex>
              </Tooltip>
            )}
            {showNotificationError && (
              <Popover
                size="sm"
                trigger="hover"
                anchor={
                  <Flex>
                    <Icon name="warningSign" size="sm" variant="warning" />
                  </Flex>
                }
              >
                <NotificationsErrorText phoneWarnings={phoneWarnings} emailWarnings={emailWarnings} />
              </Popover>
            )}
            {message?.attachments?.[0]?.attachmentType === ChannelMessageAttachmentType.AutomationRun && (
              <Tooltip label={'Automation Message'}>
                <Flex>
                  <Icon name="robot" size="sm" variant="subtle" />
                </Flex>
              </Tooltip>
            )}
            {isNumberBlocked && (
              <Tooltip
                label={`${message.sourcePhoneNumber} is currently blocked by your clinic. To unblock this number, please visit the Text Messaging page in your settings.`}
              >
                <Flex>
                  <Icon name="cancel" size="sm" variant="subtle" />
                </Flex>
              </Tooltip>
            )}
          </HStack>
          {isMessageDeleted ? (
            <Text fontStyle="italic" variant="subtle">
              This message is no longer available.
            </Text>
          ) : (
            <>
              <MessageBubble
                key={message.id}
                message={message}
                clinicPetParentId={clinicPetParentInfo?.[0]?.id || ''}
                setPreviewData={setPreviewData}
                channel={channelData}
                setCurrentInvoiceId={setCurrentInvoiceId}
                isLastMessage={isLastMessage}
                isOneBeforeLastMessage={isOneBeforeLastMessage}
                isLastMessageSystemMessage={isLastMessageSystemMessage}
              />
              <DeliveryStatus
                message={message}
                seenByMembers={seenByMembers}
                author={author}
                hasSmsConversations={!!hasSmsConversations}
                isAutomationMessage={isAutomationMessage}
                pushIsEnabled={!!pushIsEnabled}
                smsNotificationStatus={smsNotificationStatus}
                isSmsNotificationStatusLoading={isSmsNotificationStatusLoading}
                phoneWarnings={phoneWarnings}
                isLastVisibleMessage={isLastVisibleMessage}
                isTeamChannel={isTeamChannel}
                currentUserId={currentUserId}
              />
            </>
          )}
        </MessageBubblesContainer>
        {!isMessageDeleted && (
          <MenuItemContainer _groupHover={{ display: 'flex' }}>
            <MessageReactions message={message} showQuickReactions />
            {!isMessageDeleted && messageOptions.length ? (
              <Dropdown
                options={messageOptions}
                tooltipEnabled={false}
                placement={PopoverPlacement.BottomStart}
                onSelect={handleMessageMenuOptionClick}
              >
                <MoreOptionsButton />
              </Dropdown>
            ) : (
              ''
            )}
          </MenuItemContainer>
        )}
        <DeleteMessageAlertDialog messageId={messageIdToDelete} onClose={(): void => setMessageIdToDelete(null)} />
        <BlockPhoneNumberModal
          initialNumber={removePhoneCountryCode(message.sourcePhoneNumber || '')}
          isBlockNumberOpen={isBlockNumberOpen}
          onBlockNumberClose={onBlockNumberClose}
        />
      </MessageGroupContainer>
    );
  }
};

const SystemMessageContainer = styled.div``;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const MessageBubblesContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
`;

const Timestamp = styled.div`
  color: var(--chakra-colors-text-info);
  font-size: 0.8em;
  font-weight: normal;
  margin-left: 6px;
  margin-right: 4px;
  text-transform: lowercase;
`;

const MenuItemContainer = styled(Box)`
  display: none;
  position: absolute;
  right: 7px;
  top: 12px;
  z-index: 2;
  align-items: center;
  background: var(--chakra-colors-background-default);
  border: 1px solid var(--chakra-colors-border-default);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  max-height: 36px;
`;

export default MessageGroup;
