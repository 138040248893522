import { differenceInMonths } from 'date-fns';
import {
  CareBenefitType,
  CarePlanCycleType,
  CarePlanEnrollment,
  CarePlanInfoFragment,
  PaymentsMade,
} from 'shared/types/graphql';
import { centsToDollars, roundTo } from './currency';
import { getRenewalCycleInMonths } from './planRenewalCycle';
import { UsePetBenefitCareBenefit } from '../hooks/usePetBenefits';

export interface ItemInfo {
  usedValue: number;
  savings?: number;
  discountedValue: number;
  quantity?: number;
  price?: number;
}

interface CalcDiscountInput {
  benefitUsages: UsePetBenefitCareBenefit[];
}

export const calcDiscount = ({ benefitUsages }: CalcDiscountInput): ItemInfo => {
  const benefit = benefitUsages?.find((usage) => usage.type === CareBenefitType.Discount);
  const usesInCents = benefit?.costCovered || 0;
  const usesInDollars = centsToDollars(usesInCents);

  return {
    usedValue: usesInDollars,
    discountedValue: usesInDollars,
  };
};

interface CalcAccountCreditInput {
  benefitUsages: UsePetBenefitCareBenefit[];
  planDiscount: number;
}

export const calcAccountCredit = ({ benefitUsages, planDiscount }: CalcAccountCreditInput): ItemInfo => {
  const benefit = benefitUsages.find((usage) => usage.type === CareBenefitType.AccountCredit);
  const usesInCents =
    benefit && benefit.quantity !== undefined && benefit.provided !== undefined
      ? benefit.provided - benefit.quantity
      : 0;
  const usesInDollars = centsToDollars(usesInCents);
  const savings = usesInDollars * planDiscount;
  return {
    usedValue: usesInDollars,
    savings,
    discountedValue: usesInDollars - savings,
  };
};

interface CalcExamsInput {
  benefitUsages: UsePetBenefitCareBenefit[];
  planDiscount: number;
}

export const calcExams = ({ benefitUsages, planDiscount }: CalcExamsInput): ItemInfo => {
  const benefits = benefitUsages.filter((usage) => usage.type === CareBenefitType.ProvidedService);
  const usesInCents = benefits?.reduce((prev, curr) => prev + curr.costCovered, 0);
  const quantity = benefits?.reduce((prev, curr) => prev + curr.used, 0) || 0;
  const usesInDollars = usesInCents / 100 || 0;
  const savings = usesInDollars * planDiscount || 0;

  return {
    usedValue: usesInDollars,
    savings,
    discountedValue: usesInDollars - savings,
    quantity,
  };
};

interface CalcTeletriageInput {
  startDate: string | Date;
  planBenefits?: CarePlanInfoFragment['planBenefits'];
}

export const calcTeletriage = ({ startDate, planBenefits }: CalcTeletriageInput): ItemInfo => {
  const teletriageBenefit = planBenefits?.find((pb) => pb.benefit.type === CareBenefitType.Teletriage)?.benefit;
  const teletriageFee = centsToDollars(teletriageBenefit?.reportingExpectedValue || 0);

  const monthsEnrolled = differenceInMonths(new Date(), new Date(startDate)) + 1;

  const totalTeletriage = teletriageFee * monthsEnrolled;
  return {
    usedValue: totalTeletriage,
    discountedValue: totalTeletriage,
    quantity: monthsEnrolled,
    price: teletriageFee,
  };
};

interface CalcPaymentsMadeInput {
  payments?: CarePlanEnrollment['enrollmentPayments'];
  pricePerRenewal?: number;
  renewalCycle?: CarePlanCycleType;
}

export const calcPaymentsMade = ({ payments, pricePerRenewal, renewalCycle }: CalcPaymentsMadeInput): PaymentsMade => {
  const planPrice = pricePerRenewal ? centsToDollars(pricePerRenewal) / getRenewalCycleInMonths(renewalCycle) : 0;
  const totalPaid = payments?.reduce((prev, curr) => curr.amount + prev, 0) || 0;
  const totalPaidInDollars = centsToDollars(totalPaid);

  return {
    total: totalPaidInDollars,
    price: planPrice,
    quantity: planPrice ? totalPaidInDollars / planPrice : 0,
  };
};

interface CalcSubtotalInput {
  accountCredit: ItemInfo;
  discount: ItemInfo;
  exams: ItemInfo;
  paymentsMade: PaymentsMade;
  teletriage: ItemInfo;
}

export const calcSubtotal = ({
  accountCredit,
  discount,
  exams,
  paymentsMade,
  teletriage,
}: CalcSubtotalInput): ItemInfo => {
  const subtotalUsed = accountCredit.usedValue + exams.usedValue + discount.usedValue + teletriage.usedValue;
  const subtotalDiscounted =
    accountCredit.discountedValue + exams.discountedValue + discount.usedValue + teletriage.discountedValue;

  return {
    usedValue: subtotalUsed - paymentsMade.total,
    discountedValue: subtotalDiscounted - paymentsMade.total,
  };
};

interface CalcFeesInput {
  includeFees: boolean;
  paymentsMadeTotal: number;
}

export const calcFees = ({ includeFees, paymentsMadeTotal }: CalcFeesInput): ItemInfo => {
  // 5% for all clinics
  const fee = 5 / 100;
  const feeValue = paymentsMadeTotal * fee;

  return {
    usedValue: feeValue,
    savings: !includeFees ? feeValue : 0,
    discountedValue: includeFees ? feeValue : 0,
  };
};

interface CalcTotalSavingsInput {
  accountCreditSavings?: number;
  discountUsedValue: number;
  examsSavings?: number;
}

export const calcTotalSavings = ({
  accountCreditSavings,
  discountUsedValue,
  examsSavings,
}: CalcTotalSavingsInput): number => {
  let savings = 0;

  if (accountCreditSavings) {
    savings += accountCreditSavings;
  }

  if (examsSavings) {
    savings += examsSavings;
  }

  savings += discountUsedValue;

  return savings;
};

interface CalcRemainingMembershipBalanceInput {
  paymentsMadeTotal: number;
  pricePerRenewal?: number;
  renewalCycle?: CarePlanCycleType;
}

export const calcRemainingMembershipBalance = ({
  paymentsMadeTotal,
  pricePerRenewal,
  renewalCycle,
}: CalcRemainingMembershipBalanceInput): PaymentsMade => {
  const planPrice = pricePerRenewal ? centsToDollars(pricePerRenewal) : 0;
  const monthlyPlanPrice = planPrice / getRenewalCycleInMonths(renewalCycle);
  const total = planPrice - paymentsMadeTotal;
  return {
    quantity: monthlyPlanPrice ? total / monthlyPlanPrice : 0,
    price: monthlyPlanPrice,
    total,
  };
};

interface CalcDifferenceAndFeeInput {
  currentPlanMontlhyCost: number;
  selectedPlanMonthlyCost: number;
  paymentsMade: number;
}

export const calcDifferenceAndFee = ({
  currentPlanMontlhyCost,
  selectedPlanMonthlyCost,
  paymentsMade,
}: CalcDifferenceAndFeeInput): { differenceBetweenPlans: number; upgradeFee: number } => {
  const differenceBetweenPlans = selectedPlanMonthlyCost - currentPlanMontlhyCost;
  const upgradeFee = differenceBetweenPlans * paymentsMade;
  return {
    differenceBetweenPlans,
    upgradeFee,
  };
};

interface CalcAndSetBalanceDueInput {
  subtotal: ItemInfo;
  discount: ItemInfo;
  totalSavings: number;
  planDiscount: boolean;
  includeFees: boolean;
  fees: ItemInfo;
  includeManualAdjustment: boolean;
  manualAdjustmentValue: number;
  applyDiscounts: boolean;
}

interface CalcAndSetBalanceDueOutput {
  amountToUseWithoutManualAdjustment: number;
  amountToUse: number;
}

export const calcAndSetBalanceDue = ({
  subtotal,
  discount,
  totalSavings,
  planDiscount,
  includeFees,
  fees,
  includeManualAdjustment,
  manualAdjustmentValue,
  applyDiscounts,
}: CalcAndSetBalanceDueInput): CalcAndSetBalanceDueOutput => {
  let amountToUse = subtotal.usedValue;

  if (applyDiscounts) {
    if (planDiscount) {
      amountToUse -= totalSavings;
    } else {
      amountToUse -= discount.usedValue;
    }
  }

  if (includeFees) {
    amountToUse += fees.usedValue;
  }

  let amountToUseWithoutManualAdjustment = amountToUse;

  if (includeManualAdjustment) {
    amountToUse -= manualAdjustmentValue;
  }

  const parsedAmount = parseFloat(`${amountToUse}`);
  amountToUse = roundTo(parsedAmount, 2);
  amountToUseWithoutManualAdjustment = parseFloat(`${amountToUseWithoutManualAdjustment}`);

  return {
    amountToUseWithoutManualAdjustment,
    amountToUse,
  };
};
