import {
  IConditionalNextStepConfig,
  IMessageButtonOption,
} from '@televet/shared-types/JsonTypes/WorkflowEventActionConfig';
import { AutomationPrompt } from 'pages/Automations/types/AutomationPrompt';
import {
  ButtonErrorDetail,
  DELETED,
  PromptErrorDetail,
  PromptFollowupStepError,
  PromptNextStepError,
} from 'pages/Automations/types/AutomationValidation';
import { WildcardValues } from 'shared/enums/WildcardOptions';
import { AutomationRunActionPromptType } from 'shared/types/graphql';
import { url } from 'shared/utils/validation';

export function validateAutomationPrompts(prompts: AutomationPrompt[]): PromptErrorDetail[] {
  const validateNextStep = ({
    triggerActionNumber,
    triggerWorkflowId,
    conditionalNextStepConfig,
    isLastPrompt,
    isButton = false,
  }: {
    triggerActionNumber?: number | null;
    triggerWorkflowId?: string | null;
    conditionalNextStepConfig?: IConditionalNextStepConfig;
    isLastPrompt: boolean;
    isButton?: boolean;
  }): Pick<PromptErrorDetail, 'nextStep' | 'followupStep'> => {
    const nextStepErrors: PromptNextStepError[] = [];
    const followupStepErrors: PromptFollowupStepError[] = [];

    if (triggerWorkflowId === DELETED) {
      nextStepErrors.push('automationDeleted');
    }

    if (conditionalNextStepConfig?.fallbackNextWorkflowEventSettingId === DELETED) {
      followupStepErrors.push('automationDeleted');
    }

    const getNextStepErrors = ({
      nextActionNumber,
      nextWorkflowId,
    }: {
      nextActionNumber: number | null | undefined;
      nextWorkflowId: string | null | undefined;
    }): PromptNextStepError[] => {
      const errors: PromptNextStepError[] = [];
      if (isLastPrompt) {
        if (nextActionNumber) {
          // Next step is "go to next prompt"
          errors.push('nextPromptMissing');
        }
      } else {
        if ((nextWorkflowId || !nextActionNumber) && !isButton) {
          errors.push('unreachablePrompts');
        }
      }

      return errors;
    };

    if (conditionalNextStepConfig) {
      const { fallbackNextActionNumber, fallbackNextWorkflowEventSettingId } = conditionalNextStepConfig;
      followupStepErrors.push(
        ...getNextStepErrors({
          nextActionNumber: fallbackNextActionNumber,
          nextWorkflowId: fallbackNextWorkflowEventSettingId,
        }),
      );
    } else {
      nextStepErrors.push(
        ...getNextStepErrors({ nextActionNumber: triggerActionNumber, nextWorkflowId: triggerWorkflowId }),
      );
    }

    return { nextStep: nextStepErrors, followupStep: followupStepErrors };
  };

  const validateButtons = (buttons: IMessageButtonOption[], isLastPrompt: boolean): ButtonErrorDetail[] => {
    const hasUnreachablePrompts =
      !isLastPrompt &&
      !buttons.find(
        (button) =>
          button.workflowEventTriggerActionNumber || button.conditionalNextStepConfig?.fallbackNextActionNumber,
      );

    const validateButton = (button: IMessageButtonOption, index: number): ButtonErrorDetail => {
      const {
        workflowEventTriggerActionNumber,
        nextWorkflowEventTriggerSettingId,
        conditionalNextStepConfig,
        body,
        externalUrl,
      } = button;

      const errors: ButtonErrorDetail = {
        buttonIndex: index,
        buttonText: [],
        nextStep: [],
        followupStep: [],
        externalUrl: [],
      };

      const { nextStep, followupStep } = validateNextStep({
        triggerActionNumber: workflowEventTriggerActionNumber,
        triggerWorkflowId: nextWorkflowEventTriggerSettingId,
        conditionalNextStepConfig,
        isLastPrompt,
        isButton: true,
      });

      errors.nextStep = nextStep;
      errors.followupStep = followupStep;

      if (hasUnreachablePrompts) {
        errors[conditionalNextStepConfig ? 'followupStep' : 'nextStep'].push('unreachablePrompts');
      }

      // Button text is empty
      if (!body || !body.length) {
        errors.buttonText.push('buttonTextEmpty');
      }

      if (
        externalUrl !== undefined &&
        !url.test(externalUrl) &&
        externalUrl !== WildcardValues.HillsToHomeRegistrationUrl
      ) {
        errors.externalUrl.push('invalidExternalUrl');
      }

      return errors;
    };

    return buttons.map(validateButton);
  };
  const promptErrors = prompts.map((prompt: AutomationPrompt, index) => {
    const { promptType, triggerActionNumber, config } = prompt;
    const { messageBodyTemplate, nextWorkflowEventTriggerSettingId, conditionalNextStepConfig } = config;
    const isLastPrompt = index + 1 === prompts.length;

    const errors: PromptErrorDetail = {
      promptIndex: index,
      promptType: promptType,
      questionOrMessage: [],
      nextStep: [],
      followupStep: [],
      invoice: [],
      form: [],
      buttonContainer: [],
      buttons: [],
    };

    // Question or message is empty
    if (!messageBodyTemplate || messageBodyTemplate === '') {
      errors.questionOrMessage.push('questionOrMessageEmpty');
    }

    // Prompt specific validation
    switch (promptType) {
      case AutomationRunActionPromptType.Buttons:
        const { buttons } = config;

        // No butttons specified
        if (!buttons || !buttons.length) {
          errors.buttonContainer.push('buttonsEmpty');
        } else {
          errors.buttons = validateButtons(buttons, isLastPrompt);
        }

        break;
      case AutomationRunActionPromptType.Payment:
        const { invoiceAmount } = config;
        let formattedInvoiceAmount;

        if (invoiceAmount) {
          formattedInvoiceAmount = parseInt(invoiceAmount?.replace('.', ''));
        }

        // Invoice amount is empty or 0
        if (!invoiceAmount || invoiceAmount === '0.00' || invoiceAmount === '') {
          errors.invoice.push('invoiceEmpty');
        }

        if (formattedInvoiceAmount && formattedInvoiceAmount < 50) {
          errors.invoice.push('invoiceAmount');
        }

        break;
      case AutomationRunActionPromptType.Form:
        const { formTemplateId } = config;

        // Form field is empty
        if (!formTemplateId) {
          errors.form.push('formEmpty');
        }

        // Form has been deleted
        if (formTemplateId === DELETED) {
          errors.form.push('formDeleted');
        }

        break;
      case AutomationRunActionPromptType.Statement:
        // Statement is not the final prompt
        if (!isLastPrompt) {
          errors.nextStep.push('statementUnreachablePrompts');
        }
        break;
      default:
        break;
    }

    // Validate next step field
    if (
      promptType !== AutomationRunActionPromptType.Buttons &&
      promptType !== AutomationRunActionPromptType.Statement
    ) {
      const { nextStep, followupStep } = validateNextStep({
        triggerActionNumber,
        triggerWorkflowId: nextWorkflowEventTriggerSettingId,
        conditionalNextStepConfig,
        isLastPrompt,
      });
      errors.nextStep = nextStep;
      errors.followupStep = followupStep;
    }

    return errors;
  });

  return promptErrors;
}
