import { TwilioIdentity } from '@televet/shared-types/twilio';
import { useMemo } from 'react';
import {
  ChannelViewChannelFragment,
  ChannelViewChannelMemberFragment,
  ChannelViewChannelMessageFragment,
  MessageType,
} from 'shared/types/graphql';

interface IUseLastRealMessageReadInput {
  channelData?: ChannelViewChannelFragment | null;
  currentUserId?: string;
  messages: ChannelViewChannelMessageFragment[] | undefined;
}

interface IUseLastRealMessageReadOutput {
  lastVisibleMessageReadByMember: Record<number, Array<ChannelViewChannelMemberFragment>>;
  lastVisibleMessageIndex: number;
}

const findVisibleMessage = (
  messages: ChannelViewChannelMessageFragment[],
  minIndex = Infinity,
): ChannelViewChannelMessageFragment | undefined => {
  return messages.find(
    (message) =>
      message.index <= minIndex &&
      message.messageType !== MessageType.Note &&
      message.author?.twilioIdentity !== TwilioIdentity.System,
  );
};

const useLastMessage = ({
  channelData,
  currentUserId,
  messages,
}: IUseLastRealMessageReadInput): IUseLastRealMessageReadOutput => {
  const reversedMessages = useMemo(() => messages?.slice().reverse(), [messages]);

  const lastVisibleMessageReadByMember = useMemo(() => {
    const lastVisibleMessageReadByMember: Record<number, Array<ChannelViewChannelMemberFragment>> = {};

    if (!channelData?.channelMembers || !reversedMessages?.length) {
      return lastVisibleMessageReadByMember;
    }

    const assigneeChannelMembers = channelData.assignees
      .filter((user) => user?.channelMembers?.length)
      .flatMap((user) => user.channelMembers);
    const otherChatMembers = [...channelData.channelMembers, ...assigneeChannelMembers].filter((member) => {
      const isClinicPetParent = member.clinicPetParent;
      const isAssignedClinicUser = assigneeChannelMembers.some(({ id }) => id === member.id);
      return (isClinicPetParent || isAssignedClinicUser) && member.user?.id !== currentUserId;
    });

    for (const member of otherChatMembers) {
      if (member.lastConsumedMessageIndex == null) continue;
      const lastVisibleMessageReadByMemberIndex = findVisibleMessage(
        reversedMessages,
        member.lastConsumedMessageIndex,
      )?.index;
      if (lastVisibleMessageReadByMemberIndex != null) {
        if (lastVisibleMessageReadByMemberIndex in lastVisibleMessageReadByMember) {
          lastVisibleMessageReadByMember[lastVisibleMessageReadByMemberIndex].push(member);
        } else {
          lastVisibleMessageReadByMember[lastVisibleMessageReadByMemberIndex] = [member];
        }
      }
    }

    return lastVisibleMessageReadByMember;
  }, [channelData, currentUserId, reversedMessages]);

  const lastVisibleMessageIndex = useMemo(() => {
    if (!reversedMessages) return -1;
    return findVisibleMessage(reversedMessages)?.index ?? -1;
  }, [reversedMessages]);

  return {
    lastVisibleMessageReadByMember,
    lastVisibleMessageIndex,
  };
};

export default useLastMessage;
