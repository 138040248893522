import React, { useCallback, useState, useMemo, useRef, MouseEvent, useEffect } from 'react';
import styled from 'styled-components/macro';
import { IGif } from '@giphy/js-types';
import useClinicUser from 'shared/hooks/useClinicUser';
import {
  ChannelMessageAttachmentType,
  ChannelMessageAttachmentEntityType,
  MessageType,
  PaymentRelationship,
  FormTemplateType,
  ChannelViewChannelFragment,
  ChannelViewClinicPetParentFragment,
  useGetDirectedCareLoginLinkMutation,
  ClinicPimsIntegrationType,
  ClinicUserClinicFragment,
  useGetMessageComposerFormTemplatesLazyQuery,
  useGetMessageComposerMessageTemplatesLazyQuery,
  SortOrder,
  MessageComposerMessageTemplateFragment,
  MessageComposerFormTemplateFragment,
} from 'shared/types/graphql';

import 'emoji-mart/css/emoji-mart.css';
import { BaseEmoji, Picker } from 'emoji-mart';
import Dropdown, { IOption, PopoverPlacement } from 'shared/components/Dropdown';
import Tooltip from 'shared/components/Tooltip';
import { useSnackbar, MessageTypes } from '@televet/televet-ui';
import { IAttachmentOption } from 'pages/Conversations/interfaces/IAttachmentOption';
import { IKeyboardShortcutElementRefs } from 'pages/Conversations/interfaces/IKeyboardShortcutElementRefs';
import GenericToolbarButton from './GenericToolbarButton';
import { createUniqueId, convertFileToAttachment, toPossessiveWord } from '../../../../utils';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { PopoverNames } from 'shared/enums/PopoverNames';
import { usePopover } from 'shared/providers/PopoverWindowProvider';
import { Mixpanel } from 'shared/utils/mixpanel';
import { getClinicPetParentDisplayName, selectPrimaryPhoneNumberBestGuess } from 'shared/utils';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { IAvatarGroupSelectOption } from 'shared/components/Avatars/AvatarGroupSelect';
import { getPetParentMemberOptions } from 'shared/utils';
import { RouteBasePaths } from 'routes';
import cuid from 'cuid';
import sortBy from 'lodash-es/sortBy';
import GiphyButton from './GiphyButton';
import InvoiceAttachmentPopover from '../Attachments/Invoice/InvoiceAttachmentPopover';

import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import checkBrowserSupport, {
  getTooltipMessage,
} from 'shared/components/UnsupportedBrowserPopover/checkBrowserSupport';
import { CallTypes } from 'shared/enums/CallTypes';

import { MessageTemplateAttachment } from 'pages/MessageTemplates/MessageTemplateView';
import DispatchAutomation from 'pages/Conversations/components/ChannelView/MessageComposer/Toolbar/DispatchAutomationButton';
import usePersistedState from 'shared/hooks/usePersistedState';
import AttachDocumentationModal from 'pages/Conversations/components/ChannelView/MessageComposer/Attachments/Documentation/AttachDocumentationModal';
import { STATUSES, useCustomToast } from 'shared/hooks/useCustomToast';
import { PersistedStateKeys } from 'shared/enums';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';
import { Box, HStack, VStack } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Popover } from '@televet/kibble-ui/build/components/Popover';
import { CountryCode } from 'pages/Settings/pages/GeneralInfoSettings';
import { Icon } from '@televet/kibble-ui';
import { WildcardValues } from 'shared/enums/WildcardOptions';

interface IProps {
  channel?: ChannelViewChannelFragment | null;
  clinicPetParents: ChannelViewClinicPetParentFragment[];
  clinic: ClinicUserClinicFragment | null;
  messageType: MessageType;
  attachments: IAttachmentOption[];
  stagedFiles: File[];
  inputTextareaRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  isChannelLoading: boolean;
  isSendingMessage: boolean;
  isTeamChannel: boolean;
  isInvoicingDrawerOpen: boolean;
  onInvoicingDrawerClose: () => void;
  onInvoicingDrawerToggle: () => void;
  setAttachments: (value: React.SetStateAction<IAttachmentOption[]>) => void;
  setStagedFiles: (value: React.SetStateAction<File[]>) => void;
  setDraftMessageText: (value: React.SetStateAction<string>) => void;
  onEmojiSelect: (emoji: BaseEmoji) => void;
  onSendMessage: () => void;
  isEmojiPickerOpen: boolean;
  setIsEmojiPickerOpen: (value: boolean) => void;
  keyboardShortcutElementRefs: IKeyboardShortcutElementRefs;
  setHasPotentialBenefits: React.Dispatch<React.SetStateAction<boolean>>;
  hasPotentialBenefits: boolean;
  setIsLinkPetToFormModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setInvoiceForSidePanelId: React.Dispatch<React.SetStateAction<string>>;
  setAttachInvoiceClicked?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFormAttachment: React.Dispatch<React.SetStateAction<IAttachmentOption | undefined>>;
  setShouldOpenOpenFormModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const maxImageCount = 6;
const maxCombinedVideoSize = 100000000; // 100 MB

const MessageComposerToolbar = ({
  channel,
  clinicPetParents,
  clinic,
  messageType,
  attachments,
  stagedFiles,
  inputTextareaRef,
  isChannelLoading,
  isSendingMessage,
  isTeamChannel,
  isInvoicingDrawerOpen,
  onInvoicingDrawerClose,
  onInvoicingDrawerToggle,
  setAttachments,
  setStagedFiles,
  onEmojiSelect,
  setDraftMessageText,
  onSendMessage,
  isEmojiPickerOpen,
  setIsEmojiPickerOpen,
  setHasPotentialBenefits,
  hasPotentialBenefits,
  setInvoiceForSidePanelId,
  setIsLinkPetToFormModalOpen,
  keyboardShortcutElementRefs: keyboardShortcutElementRefs,
  setAttachInvoiceClicked,
  setSelectedFormAttachment,
  setShouldOpenOpenFormModal,
}: IProps): JSX.Element => {
  const { clinicUser, currentClinicId, currentClinic } = useClinicUser();
  const { allActiveIntegrations, isInvoiceDiscoverySupported } = useIntegrationsProvider();
  const { toastMessagePop } = useCustomToast();

  const { isFeatureEnabled, isFeatureFlagsLoading } = useFeatureFlag();

  const acceptedFileTypes = `image/*, video/*, *.mp4, video/mp4, video/x-m4v, .pdf, .doc, .docx, .gif, .txt, audio/*, .zip, .7z, */dicom, .dcm, */dcm, .dicom`;

  const hasCareEnabled = useMemo(() => {
    return isFeatureEnabled(FeatureFlagName.CarePlans);
  }, [isFeatureEnabled]);

  // TODO: Move to integrations provider as 'hasActiveBitwerx' integration
  const isBitwerxIntegrated = useMemo(() => {
    if (allActiveIntegrations) {
      for (const integration of allActiveIntegrations) {
        if (integration.type === ClinicPimsIntegrationType.Bitwerx) {
          return true;
        }
      }
    }
    return false;
  }, [allActiveIntegrations]);

  const hasPimsPetParent = useMemo(() => {
    return clinicPetParents.some((parent) => parent.pimsId !== null);
  }, [clinicPetParents]);

  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<string[]>([]);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const { addSnackbar } = useSnackbar();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { openPopover } = usePopover();
  const { isMobile } = useResolutionProvider();
  const emojiPicker = useRef<HTMLDivElement | null>(null);

  const { isBrowserSupported, OS } = useMemo(() => {
    return checkBrowserSupport();
  }, []);

  const submerchantStripeId = clinic?.submerchantStripeId || '';
  const submerchantStripeChargesEnabled = clinic?.submerchantStripeChargesEnabled || false;

  const hasCustomizableFees = useMemo(
    () => !!currentClinic?.clinicSetting?.hasCustomizableFees,
    [currentClinic?.clinicSetting?.hasCustomizableFees],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any): void {
      if (!emojiPicker.current?.contains(event.target)) {
        setIsEmojiPickerOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emojiPicker, setIsEmojiPickerOpen]);

  const handleFileUploadClick = useCallback(() => {
    if (inputFileRef?.current) {
      inputFileRef.current.click();
    }
  }, [inputFileRef]);

  const [getMessageTemplates, { data: messageTemplatesData, loading: isLoadingMessageTemplates }] =
    useGetMessageComposerMessageTemplatesLazyQuery({
      fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    });

  const [getFormTemplates, { data: formTemplatesData, loading: isLoadingFormTemplates }] =
    useGetMessageComposerFormTemplatesLazyQuery({
      fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    });

  const messageTemplateOptions = useMemo(
    () =>
      sortBy(
        messageTemplatesData?.findManyMessageTemplate
          ?.filter(({ isArchived }) => !isArchived)
          .filter(({ body }) => (isTeamChannel ? !new RegExp('{{.*?}}', 'g').test(body) : true))
          .map((messageTemplate) => ({ value: messageTemplate, text: messageTemplate.name })) || [],
        (messageTemplate) => messageTemplate?.value?.name?.toLowerCase(),
      ),
    [messageTemplatesData, isTeamChannel],
  );

  const formOptions = useMemo((): IOption[] => {
    if (!formTemplatesData?.findManyFormTemplate?.length) return [];
    return sortBy(
      formTemplatesData.findManyFormTemplate
        .filter(
          ({ isActive, isDeleted, formTemplateType }: MessageComposerFormTemplateFragment) =>
            isActive && !isDeleted && formTemplateType !== FormTemplateType.WidgetRequest,
        )
        .map((formTemplate: MessageComposerFormTemplateFragment) => {
          const { title, id } = formTemplate;
          return {
            text: title,
            value: {
              attachmentType: ChannelMessageAttachmentType.FormRequest,
              entityType: ChannelMessageAttachmentEntityType.Form,
              entityId: id,
            },
            isSelected: attachments.some(({ value: { attachmentType, entityId } }) => {
              return attachmentType === ChannelMessageAttachmentType.FormRequest && entityId === id;
            }),
          };
        }),
      (form) => form.text.toLowerCase(),
    );
  }, [formTemplatesData, attachments]);

  const handleLoadMessageTemplateOptions = (): void => {
    if (currentClinicId) {
      getMessageTemplates({
        variables: {
          where: {
            clinicId: {
              equals: currentClinicId,
            },
            isArchived: {
              equals: false,
            },
          },
          orderBy: [{ createdAt: SortOrder.Asc }],
        },
      });
    }
  };

  const handleLoadFormTemplateOptions = (): void => {
    if (currentClinicId) {
      getFormTemplates({
        variables: { where: { clinicId: { equals: currentClinicId }, isDeleted: { equals: false } } },
      });
    }
  };

  const onFormAttachmentSelect = useCallback(
    (e: MouseEvent, option: IOption) => {
      const newForm = formTemplatesData?.findManyFormTemplate?.find((t) => t.id === option.value.entityId);
      const newAttachment: IAttachmentOption | undefined = {
        ...option,
        id: cuid(),
        formTemplateContent: newForm?.content || '',
        isFillInTheBlankForm: newForm?.formTemplateType === FormTemplateType.FillInTheBlank,
      };
      setAttachments((prevAttachments) => {
        if (!option.isSelected) {
          return prevAttachments.concat(newAttachment);
        }
        return prevAttachments.filter((a) => a.value.entityId !== option.value.entityId);
      });
      setIsLinkPetToFormModalOpen(true);
      if (newForm?.formTemplateType === FormTemplateType.FillInTheBlank) {
        setSelectedFormAttachment(newAttachment);
        setShouldOpenOpenFormModal(true);
      }
      inputTextareaRef?.current?.focus();
    },
    [
      setAttachments,
      formTemplatesData,
      inputTextareaRef,
      setIsLinkPetToFormModalOpen,
      setSelectedFormAttachment,
      setShouldOpenOpenFormModal,
    ],
  );

  const onGiphyAttachmentSelect = async (gif: IGif): Promise<void> => {
    const blob = await fetch(gif.images.downsized.url).then((r) => r.blob());
    const file = new File([blob], gif.title || 'Animated Gif', { type: 'image/gif' });
    const newAttachment = convertFileToAttachment(file);
    setAttachments((prevAttachments) => {
      return prevAttachments.concat(newAttachment);
    });
  };

  const includeFees = useMemo(() => {
    return hasCustomizableFees && !isFeatureEnabled(FeatureFlagName.TerminalAppOnlySurchargeUpdates);
  }, [hasCustomizableFees, isFeatureEnabled]);

  const hasInvoiceAttachment = useMemo(
    () => attachments.some(({ value }) => value.attachmentType === ChannelMessageAttachmentType.Invoice),
    [attachments],
  );

  const onInvoiceAttachmentSelect = useCallback(() => {
    if (hasInvoiceAttachment) return;

    if (setAttachInvoiceClicked) setAttachInvoiceClicked(true);

    setAttachments((prevAttachments) => {
      return prevAttachments.concat({
        id: createUniqueId(),
        text: 'Invoice',
        value: {
          id: cuid(),
          attachmentType: ChannelMessageAttachmentType.Invoice,
        },
      });
    });
  }, [hasInvoiceAttachment, setAttachInvoiceClicked, setAttachments]);

  const onRemoveAllInvoices = useCallback(() => {
    setAttachments((prevAttachments) => {
      return prevAttachments.filter((a) => a.value.attachmentType !== ChannelMessageAttachmentType.Invoice);
    });
  }, [setAttachments]);

  const onAttachInvoiceWithAmount = useCallback(
    (
      amount: string,
      invoiceIds: string[] = [] as string[],
      isBalanceSelected: boolean,
      discounts?: string,
      paymentRelationship?: PaymentRelationship,
    ) => {
      setAttachments((prevAttachments) => {
        const newPrevAttachments = prevAttachments.filter((a) => {
          return a.value.attachmentType !== ChannelMessageAttachmentType.Invoice;
        });

        const args: IAttachmentOption = {
          id: createUniqueId(),
          text: 'Invoice',
          invoiceIds,
          clientServiceFeePercent:
            (includeFees && clinic?.clinicSetting?.paymentFeeConfig?.onlineClientServiceFeePercent) || 0,
          invoiceAmount: amount === '0.00' ? '' : amount.replace(',', ''),
          isBalanceSelected,
          invoiceTotalDiscounts: discounts ? discounts.replace(',', '') : '',
          value: {
            id: cuid(),
            attachmentType: ChannelMessageAttachmentType.Invoice,
          },
          validationError: parseFloat(amount) < 0.5 ? 'Payment amount must be greater or equal to $0.50' : undefined,
        };

        if (!!paymentRelationship) {
          args.paymentRelationship = paymentRelationship;
        }

        return newPrevAttachments.concat(args);
      });
    },
    [setAttachments, clinic, includeFees],
  );

  const validateTotalVideoSize = (videoSize: number): boolean => {
    return videoSize <= maxCombinedVideoSize;
  };

  const validateTotalImageCount = (imageList: File[]): boolean => {
    return imageList.length <= maxImageCount;
  };

  const onCheckFileDuplication = useCallback(
    (newFiles: File[]) => {
      const stagedFileNames = stagedFiles.map((file) => file.name);

      const checkNewFiles = newFiles.filter((file) => !stagedFileNames.includes(file.name));
      return checkNewFiles;
    },
    [stagedFiles],
  );

  const updateSelectedFiles = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = e.target.files;
    if (!files) return;

    const newFiles: File[] = Object.entries(files).map(([, file]) => file);
    const checkedNewFile = onCheckFileDuplication(newFiles);
    if (checkedNewFile.length === 0) {
      toastMessagePop(
        `${
          newFiles.length === 1 ? 'This file has' : 'These files have'
        } already been attached. Please try another one.`,
        STATUSES.info,
        2000,
      );
      e.target.value = '';
      return;
    } else if (checkedNewFile.length < newFiles.length && newFiles.length > 1) {
      toastMessagePop('Some of the files have already been attached.', STATUSES.info, 2000);
    }

    const allFiles = stagedFiles.concat(checkedNewFile);

    const imageList = allFiles?.filter((file: File) => file?.type?.includes('image'));
    const videoList = allFiles?.filter((file: File) => file?.type?.includes('video'));

    if (videoList.length > 0) {
      const totalVideoSize = videoList
        .map((video: File) => {
          return video.size;
        })
        .reduce((prev, curr) => {
          return prev + curr;
        });

      if (!validateTotalVideoSize(totalVideoSize)) {
        addSnackbar({
          type: MessageTypes.Error,
          message: 'Videos must not exceed 100 MB combined',
        });
        return;
      }
    }

    if (!validateTotalImageCount(imageList)) {
      addSnackbar({
        type: MessageTypes.Error,
        message: `Maximum ${maxImageCount} images allowed per consultation`,
      });
      return;
    }

    const newAttachments = attachments.concat(checkedNewFile.map((file: File) => convertFileToAttachment(file)));
    setAttachments(newAttachments);
    setStagedFiles(allFiles);
    e.target.value = '';
  };

  const petParentChannelMembers: IAvatarGroupSelectOption[] = useMemo(() => {
    return getPetParentMemberOptions(clinicPetParents);
  }, [clinicPetParents]);

  const isPhoneNumberValid = (parent: ChannelViewClinicPetParentFragment): boolean => {
    return !!selectPrimaryPhoneNumberBestGuess(parent.phoneNumbers || []);
  };

  const onShowDisabledMessage = useCallback((parent: ChannelViewClinicPetParentFragment): string => {
    return isPhoneNumberValid(parent)
      ? ''
      : `${parent.firstName} ${parent.lastName} does not have a saved phone number.`;
  }, []);

  const onStartPhoneCall = useCallback(
    (e: MouseEvent, option: IOption): void => {
      e.preventDefault();
      e.stopPropagation();

      if (!(option.value?.phoneNumbers?.length > 0)) {
        addSnackbar({
          type: MessageTypes.Info,
          message: `${[option.value?.firstName, option.value?.lastName]
            .join(' ')
            .trim()} does not have a saved phone number. Please add a phone number for this client and try again.`,
          timeout: 5000,
        });
        return;
      }

      openPopover(isBrowserSupported ? PopoverNames.PhoneCall : PopoverNames.UnsupportedBrowser, {
        clinicPetParent: option.value,
      });
    },
    [isBrowserSupported, addSnackbar, openPopover],
  );

  const onRequestVideoCallSelect = useCallback(() => {
    setAttachments((prevAttachments) => {
      return prevAttachments.concat({
        id: createUniqueId(),
        text: 'Video call request',
        value: {
          attachmentType: ChannelMessageAttachmentType.CallRequest,
          entityType: ChannelMessageAttachmentEntityType.Appointment,
        },
      } as IAttachmentOption);
    });
  }, [setAttachments]);

  useEffect(() => {
    if (
      search.includes('sendVideo') &&
      !attachments.find((attachment) => attachment?.value?.attachmentType === ChannelMessageAttachmentType.CallRequest)
    ) {
      if (search.includes('sendVideo') && !!channel) history.push(pathname.replace('?sendVideo=true', ''));
      // There is a race condition between loading the message and
      // adding the video request when coming from the right panel,
      // I added a timeOut with zero delay to move these two
      // processes lower in the stack to ensure its execution
      setTimeout(() => {
        onRequestVideoCallSelect();
      }, 0);
    }
  }, [search, channel, onRequestVideoCallSelect, attachments, history, pathname]);

  const phoneTooltipMessage = useMemo(() => {
    if (!isBrowserSupported) {
      return getTooltipMessage(CallTypes.Phone, OS);
    }

    const phoneNumber = selectPrimaryPhoneNumberBestGuess(clinicPetParents[0]?.phoneNumbers || []);
    const clientName = getClinicPetParentDisplayName(clinicPetParents[0] || {});
    return phoneNumber ? `Call ${clientName}` : `${clientName} does not have a saved phone number.`;
  }, [isBrowserSupported, clinicPetParents, OS]);

  const clientFullNames = useMemo(() => {
    return channel?.channelMembers
      ?.filter(({ clinicPetParent }) => clinicPetParent)
      .map((p) => [p.clinicPetParent?.firstName, p.clinicPetParent?.lastName].join(' ').trim())
      .join(', ')
      .trim();
  }, [channel]);

  const clientFirstNames = useMemo(() => {
    return channel?.channelMembers
      ?.filter(({ clinicPetParent }) => clinicPetParent)
      .map((p) => p.clinicPetParent?.firstName)
      .join(', ')
      .trim();
  }, [channel]);

  const petNames = useMemo(() => {
    return channel?.pets
      ?.map((p) => p.name.trim())
      .join(', ')
      .trim();
  }, [channel]);

  const petIds = useMemo(() => {
    return channel?.pets?.map((p) => p.id);
  }, [channel]);

  const petPortalClients = useMemo(() => {
    if (!channel?.channelMembers) return [];
    return channel?.channelMembers
      ?.filter(({ clinicPetParent }) => clinicPetParent)
      .map(({ clinicPetParent }) => {
        return {
          id: clinicPetParent?.id,
          firstName: clinicPetParent?.firstName,
          lastName: clinicPetParent?.lastName,
          pets: clinicPetParent?.pets
            ? clinicPetParent.pets.map((pet) => {
                return {
                  id: pet.id,
                  name: pet.name,
                  species: pet.species,
                };
              })
            : [],
        };
      });
  }, [channel]);

  //all the pets in the channel
  const petPortalPets = useMemo(() => {
    if (!channel?.pets) return [];
    return channel?.pets.map(({ id, name }) => {
      return {
        id,
        name,
      };
    });
  }, [channel]);

  const [getLoginLink] = useGetDirectedCareLoginLinkMutation();

  const getPetPortalLinksForClients = useCallback(async () => {
    if (!petPortalClients) return [];
    const result = [];
    for (const petPortalClient of petPortalClients) {
      if (petPortalClient.id) {
        const loginLink = await getLoginLink({
          variables: {
            data: {
              clinicPetParentId: petPortalClient.id,
            },
          },
        });
        if (petPortalClient.lastName) {
          result.push(
            [
              petPortalClient.firstName,
              toPossessiveWord(petPortalClient.lastName),
              'Pet Portal link:',
              loginLink.data?.getDirectedCareLoginLink.link,
            ]
              .join(' ')
              .trim(),
          );
        } else if (petPortalClient.firstName) {
          result.push(
            [
              toPossessiveWord(petPortalClient?.firstName),
              'Pet Portal link:',
              loginLink.data?.getDirectedCareLoginLink.link,
            ]
              .join(' ')
              .trim(),
          );
        } else {
          result.push([`Client's Pet Portal link:`, loginLink.data?.getDirectedCareLoginLink.link].join(' ').trim());
        }
      }
    }
    return result;
  }, [petPortalClients, getLoginLink]);

  const getPetPortalLinksForPets = useCallback(async () => {
    if (!petPortalClients || !petPortalPets) return [];
    if (!hasCareEnabled) return [];
    const loginLinkRequests = petPortalClients.flatMap((petPortalClient) => {
      if (petPortalClient.pets) {
        return petPortalClient.pets
          .filter((pet) => petPortalPets.some((petPortalPet) => petPortalPet.id === pet.id))
          .map(async (petPortalPet) => {
            if (petPortalClient.id) {
              return {
                petName: petPortalPet.name,
                petParentFirstName: petPortalClient.firstName,
                petParentLastName: petPortalClient.lastName,
                link: await getLoginLink({
                  variables: {
                    data: {
                      clinicPetParentId: petPortalClient?.id,
                      clinicPetId: petPortalPet.id,
                    },
                  },
                }),
              };
            }
          });
      }
    });
    const successfulRequests = await Promise.all(loginLinkRequests);
    const strings = successfulRequests.flatMap((request) => {
      if (request?.link && request.petParentFirstName) {
        return [
          [
            toPossessiveWord(request.petParentFirstName),
            `Pet Portal link for`,
            request.petName,
            ': ',
            request.link.data?.getDirectedCareLoginLink.link,
          ]
            .join(' ')
            .trim(),
        ];
      } else if (request?.link) {
        return [
          [`Client's Pet Portal link for`, request.petName, ': ', request.link.data?.getDirectedCareLoginLink.link]
            .join(' ')
            .trim(),
        ];
      }
    });
    return strings;
  }, [petPortalPets, getLoginLink, petPortalClients, hasCareEnabled]);

  const onMessageTemplateSelect = useCallback(
    async (
      e: React.MouseEvent<Element, globalThis.MouseEvent>,
      option: IOption<MessageComposerMessageTemplateFragment>,
    ) => {
      let body = option.value.body;
      const petRegex = new RegExp(WildcardValues.PetName, 'gm');
      const clientFullNameRegex = new RegExp(WildcardValues.ClientName, 'gm');
      const clientFirstNameRegex = new RegExp(WildcardValues.ClientFirstName, 'gm');
      const employeeRegex = new RegExp(WildcardValues.MyName, 'gm');
      const petPortalLinksForClientsRegex = new RegExp(WildcardValues.PetPortalLinksForClients, 'gm');
      const petPortalLinksForPetsRegex = new RegExp(WildcardValues.PetPortalLinksForPets, 'gm');

      if (option.value.attachments.length) {
        const attachmentOptions = option.value.attachments.map((attachment: MessageTemplateAttachment) => {
          return {
            id: cuid(),
            value: {
              attachmentType: attachment.attachmentType,
              entityType: attachment.entityType,
              entityId: attachment.entityId,
            },
          };
        });

        setAttachments([...attachments, ...attachmentOptions]);
      }

      body = body
        .replace(clientFullNameRegex, clientFullNames || '')
        .replace(clientFirstNameRegex, clientFirstNames || '')
        .replace(petRegex, petNames || 'your pet')
        .replace(employeeRegex, clinicUser?.nameDisplay || `${clinicUser?.firstName} ${clinicUser?.lastName}`.trim());

      if (petPortalLinksForClientsRegex.test(body)) {
        const linkMessage = await getPetPortalLinksForClients();
        if (linkMessage) {
          body = body.replace(petPortalLinksForClientsRegex, linkMessage.join('\n') || '');
        }
      }
      if (petPortalLinksForPetsRegex.test(body)) {
        const linkMessage = await getPetPortalLinksForPets();
        if (linkMessage) {
          body = body.replace(petPortalLinksForPetsRegex, linkMessage.join('\n') || '');
        }
      }

      setDraftMessageText((value) => (value ? `${value}\n${body}` : body));
      inputTextareaRef.current?.focus();
      Mixpanel.track('Message template selected', { templateName: option.value.name });
    },
    [
      clientFullNames,
      petNames,
      clinicUser?.nameDisplay,
      clinicUser?.firstName,
      clinicUser?.lastName,
      setDraftMessageText,
      inputTextareaRef,
      setAttachments,
      attachments,
      getPetPortalLinksForClients,
      getPetPortalLinksForPets,
      clientFirstNames,
    ],
  );
  const [isDeveloperModeEnabled] = usePersistedState(PersistedStateKeys.IsDeveloperModeEnabled, false);
  const [isAutomationsSenderEnabled] = usePersistedState(PersistedStateKeys.IsAutomationsSenderEnabled, false);
  const [isDiscoveredInvoiceDemoModeEnabled] = usePersistedState(
    PersistedStateKeys.IsDiscoveredInvoiceDemoModeEnabled,
    false,
  );

  const attachmentsHasCallRequest = useMemo(
    () =>
      attachments.some((attachment) => attachment.value.attachmentType === ChannelMessageAttachmentType.CallRequest),
    [attachments],
  );

  const isUSClinic = useMemo(() => {
    if (!clinicUser?.vetInfo?.currentClinic?.country) return true;
    return clinicUser?.vetInfo?.currentClinic?.country === CountryCode.US;
  }, [clinicUser?.vetInfo?.currentClinic?.country]);

  return (
    <ComposerToolbar>
      <ActionButtonsContainer>
        {!isTeamChannel && (isAutomationsSenderEnabled || isDeveloperModeEnabled) && (
          <>
            <GenericToolbarButton tooltip="Send Automation" data-testid="send-automation-button">
              <DispatchAutomation clinicPetIds={petIds} />
            </GenericToolbarButton>
            <Divider />
          </>
        )}
        <Tooltip />
        <Dropdown
          isLazy={true}
          options={messageTemplateOptions}
          loadingOptions={isLoadingMessageTemplates}
          onLoadOptions={handleLoadMessageTemplateOptions}
          onSelect={onMessageTemplateSelect}
          placement={PopoverPlacement.TopStart}
          emptyMessage={
            <MessageTemplatesEmptyState>
              <b>Message Templates</b>
              <div>
                Save time and create templates for your team&apos;s most common messages with our{' '}
                <Link to={RouteBasePaths.MessageTemplates}>message template builder</Link>.
              </div>
            </MessageTemplatesEmptyState>
          }
        >
          <span>
            <GenericToolbarButton
              tooltip="Select a message template"
              data-testid="select-message-template-button"
              ref={keyboardShortcutElementRefs.t}
              style={{ padding: 6 }}
            >
              <Box flexDir="row" display="flex">
                <Icon name="messageDetail" />
                <Icon size="sm" name="chevronDown" />
              </Box>
            </GenericToolbarButton>
          </span>
        </Dropdown>
        <Divider />
        {isTeamChannel ? null : (
          <Dropdown
            isLazy={true}
            options={formOptions}
            loadingOptions={isLoadingFormTemplates}
            onLoadOptions={handleLoadFormTemplateOptions}
            isMultiSelect={true}
            onSelect={onFormAttachmentSelect}
            placement={PopoverPlacement.TopStart}
          >
            <span>
              <GenericToolbarButton
                ref={keyboardShortcutElementRefs.f}
                tooltip="Attach form(s)"
                data-testid="attach-forms-button"
                style={{ padding: 6 }}
              >
                <Box flexDir="row" display="flex">
                  <Icon name="form" />
                  <Icon size="sm" name="chevronDown" />
                </Box>
              </GenericToolbarButton>
            </span>
          </Dropdown>
        )}
        {isTeamChannel || messageType === MessageType.Note || !isUSClinic ? null : submerchantStripeId &&
          submerchantStripeChargesEnabled ? (
          <>
            {(isBitwerxIntegrated || isDiscoveredInvoiceDemoModeEnabled) &&
            isInvoiceDiscoverySupported &&
            !isFeatureFlagsLoading &&
            isFeatureEnabled(FeatureFlagName.InvoiceDiscovery) &&
            hasPimsPetParent ? (
              <InvoiceAttachmentPopover
                isDrawerOpen={isInvoicingDrawerOpen}
                onDrawerClose={onInvoicingDrawerClose}
                onDrawerToggle={onInvoicingDrawerToggle}
                clinicPetParents={clinicPetParents}
                onInvoiceAttach={onAttachInvoiceWithAmount}
                onRemoveAllInvoices={onRemoveAllInvoices}
                selectedInvoiceIds={selectedInvoiceIds}
                setSelectedInvoiceIds={setSelectedInvoiceIds}
                invoiceButtonRef={keyboardShortcutElementRefs.i}
                setHasPotentialBenefits={setHasPotentialBenefits}
                hasPotentialBenefits={hasPotentialBenefits}
                setInvoiceForSidePanelId={setInvoiceForSidePanelId}
              />
            ) : (
              <GenericToolbarButton
                ref={keyboardShortcutElementRefs.i}
                tooltip={hasInvoiceAttachment ? 'Invoice already attached' : 'Attach invoice'}
                data-testid="attach-invoice-button"
                onClick={onInvoiceAttachmentSelect}
                isDisabled={hasInvoiceAttachment}
              >
                <Icon name="invoice" />
              </GenericToolbarButton>
            )}
          </>
        ) : (
          <GenericToolbarButton
            tooltip={''}
            data-testid="attach-invoice-button"
            popover={
              <div>
                <Icon name="warningSign" size="sm" />
                To begin sending payment requests to clients, first connect your clinic&apos;s bank account on the
                &nbsp;
                <Link to="/settings/clinic/payment">Payments Settings page</Link>.
              </div>
            }
            popoverProps={{
              interactive: true,
              delay: [0, 0],
              maxWidth: 240,
            }}
            isDisabled={true}
          >
            <Icon name="invoice" />
          </GenericToolbarButton>
        )}
        <GenericToolbarButton
          tooltip="Attach file(s)"
          onClick={handleFileUploadClick}
          data-testid="attach-files-button"
          ref={keyboardShortcutElementRefs.a}
        >
          <Icon name="paperclip" />
        </GenericToolbarButton>
        {!isMobile && (
          <GenericToolbarButton tooltip="Attach documentation" data-testid="attach-documentation-button">
            <AttachDocumentationModal setDraftMessageText={setDraftMessageText} />
          </GenericToolbarButton>
        )}
        <Divider />
        {isTeamChannel ? null : petParentChannelMembers?.length > 1 ? (
          <Dropdown
            options={petParentChannelMembers}
            onSelect={onStartPhoneCall}
            placement={PopoverPlacement.BottomStart}
            setDisabled={(parent): boolean => !isPhoneNumberValid(parent)}
            disabledMessage={(parent): string => onShowDisabledMessage(parent)}
          >
            <span>
              <GenericToolbarButton tooltip="Choose Client to Call" data-testid="start-phone-call-button">
                <Icon name="phone" />
              </GenericToolbarButton>
            </span>
          </Dropdown>
        ) : (
          <>
            {!isMobile && (
              <GenericToolbarButton
                tooltip={phoneTooltipMessage}
                onClick={async (e: MouseEvent): Promise<void> => {
                  if (!e) return;

                  onStartPhoneCall(e, petParentChannelMembers[0]);
                }}
                isDisabled={!isPhoneNumberValid(clinicPetParents[0] || {}) || !isBrowserSupported}
                data-testid="start-phone-call-button"
              >
                <Icon name="phone" />
              </GenericToolbarButton>
            )}
          </>
        )}
        {!isMobile && (
          <GenericToolbarButton
            tooltip="Request video call"
            {...(!attachmentsHasCallRequest && { onClick: onRequestVideoCallSelect })}
            data-testid="request-video-call-button"
            isDisabled={attachmentsHasCallRequest}
          >
            <Icon name="videoCamera" />
          </GenericToolbarButton>
        )}

        {
          // Grouping Voice and video calling for mobile
          isMobile && !isTeamChannel && (
            <Box>
              <Popover
                placement="auto"
                size="xs"
                anchor={
                  <Box>
                    <GenericToolbarButton
                      ref={keyboardShortcutElementRefs.f}
                      tooltip="Start a Voice-Video Call"
                      data-testid="attach-forms-button"
                      style={{ padding: 6 }}
                    >
                      <Box flexDir="row" display="flex">
                        <Icon name="phone" />
                        <Icon name="chevronDown" size="sm" />
                      </Box>
                    </GenericToolbarButton>
                  </Box>
                }
              >
                <VStack align="center" justifyContent="space-evenly">
                  <Button
                    iconName="phone"
                    variant="secondary"
                    size="sm"
                    isOnContrast
                    isDisabled={!isPhoneNumberValid(clinicPetParents[0] || {}) || !isBrowserSupported}
                    onClick={async (e: MouseEvent): Promise<void> => {
                      if (!e) return;

                      onStartPhoneCall(e, petParentChannelMembers[0]);
                    }}
                    data-testid="start-phone-call-button"
                  >
                    Voice Call
                  </Button>
                  <Button
                    iconName="videoCamera"
                    variant="secondary"
                    size="sm"
                    isOnContrast
                    isDisabled={attachmentsHasCallRequest}
                    onClick={onRequestVideoCallSelect}
                    data-testid="request-video-call-button"
                  >
                    Video Call
                  </Button>
                </VStack>
              </Popover>
            </Box>
          )
        }

        <HiddenInput
          multiple
          type="file"
          name="file"
          id="file-input"
          accept={acceptedFileTypes}
          ref={inputFileRef}
          onChange={(e): Promise<void> => updateSelectedFiles(e)}
        />
      </ActionButtonsContainer>
      {isMobile && <Button onClick={onSendMessage} iconName="send" variant="ghost" isLoading={isSendingMessage} />}

      {!isMobile && (
        <HStack align="center">
          <GiphyButton buttonRef={keyboardShortcutElementRefs.g} onSelect={onGiphyAttachmentSelect} />
          {isEmojiPickerOpen && (
            <Box ref={emojiPicker}>
              <Picker
                autoFocus
                native
                title="Pick your emoji…"
                emoji="point_up"
                style={{ position: 'absolute', bottom: 30, right: 180 }}
                onSelect={onEmojiSelect}
              />
            </Box>
          )}

          <Box
            ref={keyboardShortcutElementRefs.e}
            onClick={(): void => {
              Mixpanel.track('Emoji drawer clicked', { clinicId: currentClinicId });
              setIsEmojiPickerOpen(!isEmojiPickerOpen);
            }}
          >
            <Button iconName="smileyFace" variant="ghostNeutral" />
          </Box>
          <Button
            data-testid="message-composer-send-button"
            disabled={isChannelLoading}
            isLoading={isSendingMessage}
            onClick={onSendMessage}
            size="sm"
            variant={messageType === MessageType.Note ? 'secondary' : 'primary'}
          >
            {messageType === MessageType.Note ? 'Make Note' : 'Send Message'}
          </Button>
        </HStack>
      )}
    </ComposerToolbar>
  );
};

const MessageTemplatesEmptyState = styled.div`
  padding: 5px 10px;
  line-height: 1.5;

  b {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;

const ComposerToolbar = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  background: var(--chakra-colors-background-alpha);
  padding: 0 6px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HiddenInput = styled.input`
  display: none;
`;

const Divider = styled.div`
  border-left: 1px solid #dde0e0;
  height: 34px;
  margin: 0 5px 0 5px;
  width: 1px;
`;

export default React.memo(MessageComposerToolbar);
