import { Box, Collapse, Divider, Flex, useDisclosure } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { ModalBody, ModalFooter } from '@televet/kibble-ui/build/components/Modal';
import { Popover } from '@televet/kibble-ui/build/components/Popover';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { Switch } from '@televet/kibble-ui/build/components/Switch';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { TextInput } from '@televet/kibble-ui/build/components/TextInput';
import { convertDataToUrl, dataUrlToFile, downloadPdf } from 'pages/Conversations/utils/files';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GraphQLFetchPolicies } from 'shared/enums';
import { GA4Events } from 'shared/enums/GA4Events';
import useGA from 'shared/hooks/useGA';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import {
  CareBenefitType,
  EnrollmentStatus,
  ManageMembershipRequestType,
  SidePanelParentPetDataFragment,
  useExportCancellationSummaryAsPdfLazyQuery,
} from 'shared/types/graphql';
import UAParser from 'ua-parser-js';
import { useCancellationCalculator } from '../../../hooks/useCancellationCalculator';
import { UsePetBenefitCareBenefit, usePetBenefits } from '../../../hooks/usePetBenefits';
import { ManageMembershipStep, useManageMembershipContext } from '../context/ManageMembershipContext';
import * as Sentry from '@sentry/react';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import { Link } from '@televet/kibble-ui/build/components/Link';
import { formatCurrency } from '../../../utils/currency';

interface CancellationCalculatorStepProps {
  enrollmentId: string;
  organizationPetId: string;
  benefitUsages: UsePetBenefitCareBenefit[];
  pet: SidePanelParentPetDataFragment | undefined;
  setValue: (name: string, value: string | number) => void;
}

const CancellationCalculatorStep = ({
  enrollmentId,
  benefitUsages,
  organizationPetId,
  pet,
  setValue,
}: CancellationCalculatorStepProps): JSX.Element => {
  const toast = useToast();
  const { gaTrack } = useGA();
  const { isOpen: isSavingsOpen, onToggle: onSavingsToggle } = useDisclosure();
  const {
    handleNext,
    handleBack,
    manualAdjustment,
    setManualAdjustment,
    applyDiscounts,
    setApplyDiscounts,
    includeFees,
    setIncludeFees,
    isSubmitting,
    includeManualAdjustment,
    setIncludeManualAdjustment,
    setBalanceDue,
  } = useManageMembershipContext();
  const [manualAdjustmentError, setManualAdjustmentError] = useState<string | undefined>();
  const { isMobile } = useResolutionProvider();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nav = navigator as any;
  const isSharingAvailable = useMemo(() => nav.share !== undefined && !!isMobile, [nav.share, isMobile]);
  const { getBrowser } = new UAParser();
  const browser: string = getBrowser().name || '';
  // Safari shows a blank page when the pdf is downloaded immediately, so we need to wait a bit before downloading the pdf
  const delayTime = browser === 'Safari' ? 5000 : 0;

  const { benefits: planBenefits } = usePetBenefits(organizationPetId);
  const hasAccountCredit = useMemo(
    () => planBenefits.find((pb) => pb.type === CareBenefitType.AccountCredit),
    [planBenefits],
  );
  const hasTeletriage = useMemo(
    () => planBenefits.find((pb) => pb.type === CareBenefitType.Teletriage),
    [planBenefits],
  );
  const hasDiscount = useMemo(() => planBenefits.find((pb) => pb.type === CareBenefitType.Discount), [planBenefits]);

  useEffect(() => {
    gaTrack(GA4Events.MEMBERSHIP_MANAGE_VIEW_CALCULATOR_STEP, { includeFees, includeManualAdjustment, applyDiscounts });
    // Disabling so this doesn't trigger every time the toggles change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaTrack]);

  const {
    hasPlanDiscount,
    accountCredit,
    exams,
    discount,
    teletriage,
    fees,
    subtotal,
    paymentsMade,
    balanceDue,
    balanceDueWithoutManualAdjustment,
    totalSavings,
    remainingMembershipBalance,
    isLoading,
  } = useCancellationCalculator({
    enrollmentId,
    benefitUsages,
  });

  const shouldCancel = balanceDue <= 0;

  const handleManualAdjustment = useCallback(
    (val: string): void => {
      setManualAdjustment(val);
      const adjustedAmount = Number(val);
      const isNegativeAmount = adjustedAmount < 0;
      const fixedBalanceDueWithoutManualAdjustment = Math.round(balanceDueWithoutManualAdjustment * 100) / 100;
      if (isNegativeAmount || adjustedAmount > fixedBalanceDueWithoutManualAdjustment) {
        setManualAdjustmentError(
          isNegativeAmount
            ? "Amount can't be less than 0"
            : `Amount can't be bigger than ${formatCurrency(balanceDueWithoutManualAdjustment, true)}`,
        );
      } else {
        setManualAdjustmentError(undefined);
      }
    },
    [balanceDueWithoutManualAdjustment, setManualAdjustment],
  );

  const openPrintWindow = useCallback(
    async (source: string, fileName: string) => {
      const printWindow = document.createElement('iframe');
      printWindow.style.visibility = 'hidden';
      printWindow.id = 'printWindow';

      const src = await convertDataToUrl(source);
      printWindow.src = src;

      document.body.appendChild(printWindow);

      setTimeout(() => {
        if (printWindow.contentWindow) {
          printWindow.contentWindow.focus();
          printWindow.contentWindow.print();
        } else {
          downloadPdf(source, fileName);
        }
      }, delayTime);
    },
    [delayTime],
  );

  const [getPDF, { loading: isPDFLoading }] = useExportCancellationSummaryAsPdfLazyQuery({
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    onCompleted: async (data) => {
      const { base64PDF } = data?.exportCancellationSummaryAsPdf;
      const source = `data:application/pdf;base64,${base64PDF}`;
      const fileName = `care_cancellation_summary${!!pet?.name ? `_${pet.name}` : ''}.pdf`;
      const file = await dataUrlToFile(source, fileName, 'application/pdf');
      if (isSharingAvailable && nav.canShare && nav.canShare({ files: [file] })) {
        nav
          .share({
            files: [file],
          })
          .then(() => {
            toast({
              title: 'Successfully shared cancellation summary.',
              status: 'success',
              duration: 2000,
              position: 'top',
            });
          })
          .catch((e: Error) => {
            console.error('Error sharing cancellation summary', e);
            openPrintWindow(source, fileName);
          });
      } else {
        openPrintWindow(source, fileName);
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast({
        title: 'Error sharing cancellation summary.',
        status: 'error',
        duration: 2000,
        position: 'top',
      });
    },
  });

  const handleDownload = useCallback((): void => {
    const currentEnrollment = pet?.organizationPet?.carePlanEnrollments.find(
      (enrollment) => enrollment.status === EnrollmentStatus.Active,
    );
    getPDF({
      variables: {
        data: {
          petName: pet?.name || '',
          species: pet?.species || 'Other',
          plan: currentEnrollment?.plan.flowAlias || currentEnrollment?.plan.title || '',
          hasAccountCredit: !!hasAccountCredit,
          hasDiscount: !!hasDiscount,
          hasTeletriage: !!hasTeletriage,
          hasPlanDiscount,
          accountCredit,
          exams,
          discount,
          teletriage,
          fees,
          manualAdjustment: !!manualAdjustment.length ? Number(manualAdjustment) : undefined,
          subtotal,
          paymentsMade,
          balanceDue,
          totalSavings,
          includeFees,
          includeManualAdjustment,
          remainingMembershipBalance,
          applyDiscounts,
        },
      },
    });
  }, [
    accountCredit,
    applyDiscounts,
    balanceDue,
    discount,
    exams,
    fees,
    getPDF,
    hasAccountCredit,
    hasDiscount,
    hasPlanDiscount,
    hasTeletriage,
    includeFees,
    includeManualAdjustment,
    manualAdjustment,
    paymentsMade,
    pet,
    remainingMembershipBalance,
    subtotal,
    teletriage,
    totalSavings,
  ]);

  return (
    <>
      <ModalBody py={5}>
        <Flex alignItems="center" justifyContent="space-between" mb={4}>
          <Heading size="md">Cancellation Summary</Heading>
          <Button
            iconName="download"
            variant="ghostNeutral"
            size="xs"
            isLoading={isPDFLoading}
            onClick={(): void => {
              handleDownload();
              gaTrack(GA4Events.MEMBERSHIP_MANAGE_DOWNLOAD_SUMMARY_CLICK);
            }}
          >
            Download Summary
          </Button>
        </Flex>
        {isLoading ? (
          <Flex alignItems="center" justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <Box>
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontWeight="semibold">Benefit Usage</Text>
            </Flex>
            <Divider my={2} color="border.default" />
            <Box>
              {!!hasAccountCredit && (
                <Flex alignItems="center" justifyContent="space-between" mb={1}>
                  <Text>Instant Account Credit</Text>
                  <Text as="p">{formatCurrency(accountCredit.usedValue, true)}</Text>
                </Flex>
              )}
              <Flex alignItems="center" justifyContent="space-between">
                <Text>Exams {!!exams.quantity ? `(${exams.quantity})` : ''}</Text>
                <Text as="p">{formatCurrency(exams.usedValue, true)}</Text>
              </Flex>
              {!!hasDiscount && (
                <Flex justifyContent="space-between" mt={1}>
                  <Text as="p">Bonus Discount</Text>
                  <Text as="p">{formatCurrency(discount.usedValue, true)}</Text>
                </Flex>
              )}
              {!!hasTeletriage && (
                <Flex justifyContent="space-between" mt={1}>
                  <Box>
                    <Text as="p">24/7 Live Chat</Text>
                    <Text as="p" variant="subtle" size="sm">
                      ${teletriage.price}/mo for {teletriage.quantity} months
                    </Text>
                  </Box>
                  <Text>{formatCurrency(teletriage.usedValue, true)}</Text>
                </Flex>
              )}
            </Box>
            <Divider my={2} color="border.default" />
            <Flex justifyContent="space-between" mt={1}>
              <Box>
                <Text as="p">Payments Made</Text>
                <Text as="p" variant="subtle" size="sm">
                  ${paymentsMade.price}/mo for {paymentsMade.quantity} months
                </Text>
              </Box>
              <Text>({formatCurrency(paymentsMade.total, true)})</Text>
            </Flex>
            <Flex justifyContent="space-between" mt={1}>
              <Text fontWeight="semibold">Subtotal:</Text>
              <Text as="p" fontWeight="semibold">
                {formatCurrency(subtotal.usedValue, true)}
              </Text>
            </Flex>
            <Divider my={2} color="border.default" />
            <Flex justifyContent="space-between" pl={2}>
              <Flex alignItems="center">
                <Switch
                  isChecked={includeFees}
                  onChange={(e): void => {
                    setIncludeFees(e.target.checked);
                    gaTrack(GA4Events.MEMBERSHIP_MANAGE_TOGGLE_FEES, { includeFees: e.target.checked });
                  }}
                  formControlStyle={{ w: '33px' }}
                />
                <Flex alignItems="center" ml={2}>
                  <Text as="p" mr={1}>
                    Fees
                  </Text>
                  <Popover
                    headerProps={{ children: 'Fees' }}
                    anchor={
                      <Flex>
                        <Icon name="questionMarkCircle" size="sm" variant="subtle" />
                      </Flex>
                    }
                  >
                    Fees are calculated based on payments made. To waive payment fees, turn this option off.
                  </Popover>
                </Flex>
              </Flex>
              <Text as="p" {...(!includeFees && { variant: 'placeholder', textDecoration: 'line-through' })}>
                {formatCurrency(fees.usedValue, true)}
              </Text>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" mt={2} pl={2}>
              <Flex alignItems="center">
                <Switch
                  isChecked={includeManualAdjustment}
                  onChange={(e): void => {
                    setIncludeManualAdjustment(e.target.checked);
                    gaTrack(GA4Events.MEMBERSHIP_MANAGE_TOGGLE_ADJUSTMENT, {
                      includeManualAdjustment: e.target.checked,
                    });
                    setManualAdjustment('');
                    setManualAdjustmentError(undefined);
                  }}
                  formControlStyle={{ w: '33px' }}
                />
                <Text as="p" ml={2} mr={1}>
                  Manual Adjustment
                </Text>
                <Popover
                  headerProps={{ children: 'Manual Adjustment' }}
                  anchor={
                    <Flex>
                      <Icon name="questionMarkCircle" size="sm" variant="subtle" />
                    </Flex>
                  }
                >
                  This is a custom amount that will be subtracted from the Balance Due.
                </Popover>
              </Flex>
              {includeManualAdjustment ? (
                <Flex alignItems="center">
                  <Text as="p" mr={1} size="lg">
                    (
                  </Text>
                  <TextInput
                    type="number"
                    size="sm"
                    formControlStyle={{ w: '100px' }}
                    leftElement={<Icon name="dollar" size="sm" />}
                    placeholder="0.00"
                    value={manualAdjustment}
                    onChange={(e): void => handleManualAdjustment(e.target.value)}
                    isInvalid={!!manualAdjustmentError?.length}
                    errorText=""
                  />
                  <Text as="p" ml={1} size="lg">
                    )
                  </Text>
                </Flex>
              ) : (
                <Text {...(!includeManualAdjustment && { variant: 'placeholder', textDecoration: 'line-through' })}>
                  ($0.00)
                </Text>
              )}
            </Flex>
            {!!manualAdjustmentError?.length && (
              <Flex justifyContent="end">
                <Text as="p" variant="danger" size="sm">
                  {manualAdjustmentError}
                </Text>
              </Flex>
            )}
            <Box
              bgColor={applyDiscounts ? 'background.primaryAlphaContrast.pressed' : 'background.alpha'}
              borderRadius="base"
              alignItems="center"
              justifyContent="space-between"
              mt={2}
              p={2}
            >
              <Flex alignItems="center" justifyContent="space-between">
                <Flex alignItems="center">
                  <Switch
                    isChecked={applyDiscounts}
                    onChange={(e): void => {
                      setApplyDiscounts(e.target.checked);
                      gaTrack(GA4Events.MEMBERSHIP_MANAGE_TOGGLE_MEMBER_SAVINGS, {
                        applyDiscounts: e.target.checked,
                      });
                    }}
                    formControlStyle={{ w: '33px' }}
                  />
                  <Text fontWeight="semibold" variant={applyDiscounts ? 'info' : 'subtle'} ml={2} mr={1}>
                    Member Savings:
                  </Text>
                  <Popover
                    headerProps={{ children: 'Member Savings' }}
                    anchor={
                      <Flex>
                        <Icon name="questionMarkCircle" size="sm" variant={applyDiscounts ? 'info' : 'subtle'} />
                      </Flex>
                    }
                  >
                    Care plans include a discount to the cost of certain benefits. To collect the full retail value of
                    benefits used, turn this option off.
                  </Popover>
                </Flex>
                <Flex>
                  <Button
                    size="xs"
                    iconName="chevronDown"
                    iconPosition="right"
                    variant={applyDiscounts ? 'ghost' : 'ghostNeutral'}
                    onClick={onSavingsToggle}
                    mr={1}
                  >
                    Show More
                  </Button>
                  <Text
                    variant={applyDiscounts ? 'info' : 'placeholder'}
                    fontWeight="semibold"
                    {...(!applyDiscounts && { textDecoration: 'line-through' })}
                  >
                    ({formatCurrency(totalSavings, true)})
                  </Text>
                </Flex>
              </Flex>
              <Collapse in={isSavingsOpen} animateOpacity>
                <Box pl="42px" pt={1}>
                  {!!hasAccountCredit && hasPlanDiscount && (
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text size="sm">Instant Account Credit</Text>
                      <Text size="sm">({formatCurrency(accountCredit.savings || 0, true)})</Text>
                    </Flex>
                  )}
                  {hasPlanDiscount && (
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text size="sm">Exams</Text>
                      <Text size="sm">({formatCurrency(exams.savings || 0, true)})</Text>
                    </Flex>
                  )}
                  {!!hasDiscount && (
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text size="sm">Bonus Discount</Text>
                      <Text size="sm">({formatCurrency(discount.usedValue, true)})</Text>
                    </Flex>
                  )}
                </Box>
              </Collapse>
            </Box>
            <Divider my={2} color="border.default" />
            <Flex justifyContent="space-between">
              <Text fontWeight="semibold" size="lg">
                Amount Due:
              </Text>
              <Text fontWeight="semibold" size="lg">
                {formatCurrency(balanceDue, true)}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" mt={1}>
              <Box>
                <Text as="p" variant="subtle" fontWeight="semibold" size="sm">
                  Renewal Opt-out Cost:
                </Text>
                <Link
                  size="sm"
                  onClick={(): void => {
                    setValue('requestType', ManageMembershipRequestType.OptOut);
                    setBalanceDue(undefined);
                    handleBack();
                  }}
                >
                  Opt out instead?
                </Link>
              </Box>
              <Flex flexDir="column" alignItems="flex-end">
                <Text as="p" variant="subtle" fontWeight="semibold" size="sm">
                  {formatCurrency(remainingMembershipBalance.total, true)}
                </Text>
                <Text as="p" variant="subtle" size="sm">
                  ${remainingMembershipBalance.price}/mo x {remainingMembershipBalance.quantity}
                </Text>
              </Flex>
            </Flex>
          </Box>
        )}
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Button variant="tertiary" onClick={handleBack} isDisabled={isSubmitting}>
          Back
        </Button>
        <Flex>
          <Button
            variant="ghost"
            iconName="questionMarkCircle"
            mr={3}
            isDisabled={isSubmitting}
            onClick={(): void => handleNext(ManageMembershipStep.GetHelp)}
          >
            Get Help
          </Button>
          <Button
            variant="primary"
            isDisabled={isLoading || !!manualAdjustmentError?.length}
            onClick={(): void =>
              handleNext(shouldCancel ? ManageMembershipStep.CancelMembership : ManageMembershipStep.PaymentStep)
            }
            isLoading={isSubmitting}
          >
            Next
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};

export default CancellationCalculatorStep;
