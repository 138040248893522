import React, { useCallback, useMemo } from 'react';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import { useSidePanelSearch } from '../SidePanel/components/SearchPanel/state/providers/SearchPanelProvider';
import { useCareBenefitFollowup } from '../SidePanel/components/SearchPanel/components/Benefits/hooks/useCareBenefitFollowup';

interface ICareBenefitsBannerProps {
  clinicPetParentId: string;
  organizationPetId?: string;
  hasMultipleEnrolledPets: boolean;
}

export const CareBenefitsBanner = ({
  clinicPetParentId,
  organizationPetId,
  hasMultipleEnrolledPets,
}: ICareBenefitsBannerProps): JSX.Element => {
  const { isFeatureEnabled } = useFeatureFlag();
  const { viewBenefits } = useSidePanelSearch();
  const { followup: incompleteFollowup } = useCareBenefitFollowup(organizationPetId);

  const incompleteFollowupSteps = useMemo(
    () => (!!incompleteFollowup?.followupSteps ? incompleteFollowup?.followupSteps : []),
    [incompleteFollowup?.followupSteps],
  );

  const hasMultipleEnrolledPetsOrIncompleteFollowup = useMemo(() => {
    return hasMultipleEnrolledPets || incompleteFollowupSteps.length > 0;
  }, [hasMultipleEnrolledPets, incompleteFollowupSteps]);

  const handleClick = useCallback(() => {
    viewBenefits({ openBenefitsModal: !hasMultipleEnrolledPetsOrIncompleteFollowup, clinicPetParentId });
  }, [viewBenefits, hasMultipleEnrolledPetsOrIncompleteFollowup, clinicPetParentId]);

  if (isFeatureEnabled(FeatureFlagName.CarePlans)) {
    return (
      <Alert
        title={
          hasMultipleEnrolledPets
            ? 'Multiple pets are enrolled in Care.'
            : incompleteFollowupSteps.length > 0
            ? 'Please finalize previous Care Membership benefit usage before collecting payment.'
            : 'Please calculate Care Membership benefits before collecting payment.'
        }
        {...(hasMultipleEnrolledPets && {
          description: 'Please select a pet to calculate membership benefits before collecting payment.',
        })}
        titleProps={{
          fontSize: 'sm',
        }}
        descriptionProps={{
          fontSize: 'sm',
        }}
        status="warning"
        hideCloseButton
        size="sm"
        buttonProps={{
          onClick: handleClick,
          buttonText: hasMultipleEnrolledPets
            ? 'Select Pet'
            : incompleteFollowupSteps.length > 0
            ? 'Go to Checkboxes'
            : 'Calculate Benefits',
          buttonPosition: 'bottomLeft',
        }}
      />
    );
  } else {
    return <></>;
  }
};
