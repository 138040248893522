import { Button } from '@televet/kibble-ui/build/components/Button';
import { Flex } from '@televet/kibble-ui/build/chakra';
import { Text } from '@televet/kibble-ui/build/components/Text';
import React from 'react';

interface ButtonActionContainerProps {
  label: string;
  remove: () => void;
  children?: JSX.Element | null | undefined;
}

const ButtonActionContainer = ({ label, remove, children }: ButtonActionContainerProps): JSX.Element => {
  return (
    <Flex w="100%" direction="column" px={4} pt={2} pb={4} borderRadius={8} bgColor="background.subtle">
      <Flex w="100%" alignItems="center" justifyContent="space-between" pb={1}>
        <Text fontWeight="semibold">{label}</Text>
        <Button variant="ghostNeutral" size="md" iconName="trashcan" onClick={remove} />
      </Flex>
      {children}
    </Flex>
  );
};

export default ButtonActionContainer;
