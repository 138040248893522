import React, { useMemo } from 'react';
import { Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import ProtectedRoute from 'routes/components/ProtectedRoute';
import { ContentLayout } from 'shared/components/Layouts/DefaultAuthenticated';
import { createBrowserHistory } from 'history';
import Conversations from 'pages/Conversations';
import Team from 'pages/Team';
import Settings from 'pages/Settings';
import Forms from 'pages/Forms';
import AcceptInvitation from 'pages/Login/AcceptInvitation';
import WorkflowsDemo from 'pages/Demo/WorkflowsDemo';
import MessageTemplates from 'pages/MessageTemplates';
import HealthCheck from 'pages/HealthCheck';
import EmailCampaigns from 'pages/EmailCampaigns';
import EmailTemplates from 'pages/EmailTemplates';
import Reporting from 'pages/Reporting';
import Board from 'pages/Board';
import TokenLogin from 'pages/Login/TokenLogin';
import Impersonate from 'pages/Login/Impersonate';
import Survey from 'pages/Survey';
import { env } from 'env';
import LoadingAndPresentation from 'routes/components/LoadingAndPresentation';
import { LoyaltyProgram } from 'pages/LoyaltyProgram';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import { ChannelMessageAttachment } from 'pages/ChannelMessageAttachement/ChannelMessageAttachment';
import SuccessCenter from 'pages/SuccessCenter/SuccessCenter';
import Automations from 'pages/Automations';
import OttoEmailCampaigns from 'pages/OttoEmailCampaigns';
import Appointments from 'pages/Appointments';
import GlobalAutomations from 'pages/GlobalAutomations';

export const history = createBrowserHistory();

export enum RouteBasePaths {
  Index = '/',
  HealthCheck = '/healthcheck',
  AcceptInvitation = '/accept',
  Appointments = '/appointments',
  Board = '/board',
  Conversations = '/conversations',
  Team = '/team',
  VideoCall = '/video-call',
  Settings = '/settings',
  Automations = '/automations',
  MessageTemplates = '/templates',
  Forms = '/forms',
  Demo = '/demo',
  Reporting = '/reporting',
  EmailCampaigns = '/emails',
  OttoEmailCampaigns = '/email-campaigns',
  EmailTemplates = '/email-templates',
  DevTools = '/devtools',
  TokenLogin = '/tokenlogin',
  Survey = '/survey',
  Impersonate = '/impersonate',
  LoyaltyProgram = '/loyalty-program',
  SuccessCenter = '/success-center',
  Support = '/support',
  GlobalAutomations = '/global-automations',
}

export enum SettingsRoutes {
  ClinicGeneral = `/clinic/general`,
  ClinicHours = `/clinic/hours`,
  ClinicAutomations = '/clinic/automations',
  ClinicTeam = '/clinic/team',
  ClinicPhone = '/clinic/phone',
  ClinicPayment = '/clinic/payment',
  ClinicWidget = '/clinic/widget',
  ClinicReminders = '/clinic/reminders',
  ClinicPostcards = '/clinic/postcards',
  Appointments = '/clinic/appointments',
  ClinicConversations = '/clinic/conversations',
  UserProfile = '/user/profile',
  UserNotifications = '/user/notifications',
  UserPreferences = '/user/preferences',
  DevTools = '/devtools',
  ClinicBilling = '/clinic/billing',
  OttoLabs = '/otto-labs',
  SyncStatus = '/clinic/sync-status',
  GlobalAutomations = '/global-automations',
}

export const RouteDefinitions = {
  Index: RouteBasePaths.Index,
  HealthCheck: RouteBasePaths.HealthCheck,
  TokenLogin: `${RouteBasePaths.TokenLogin}/:token`,
  AcceptInvitation: `${RouteBasePaths.AcceptInvitation}/:invitationCode`,
  Appointments: `${RouteBasePaths.Appointments}/:userAppointmentGroupingId?`,
  Board: RouteBasePaths.Board,
  Conversations: `${RouteBasePaths.Conversations}/:channelId?`,
  ChannelMessageAttachments: `/channelattachments/:channelMessageAttachmentId?`,
  Team: `${RouteBasePaths.Team}/:channelId?`,
  VideoCall: `${RouteBasePaths.VideoCall}/:channelId`,
  Settings: RouteBasePaths.Settings,
  Automations: `${RouteBasePaths.Automations}/:workflowEventSettingId?/:automationEditorView?`,
  MessageTemplates: `${RouteBasePaths.MessageTemplates}/:messageTemplateId?`,
  Forms: `${RouteBasePaths.Forms}/:formTemplateId?`,
  DemoWorkflow: `${RouteBasePaths.Demo}/workflow`,
  Reporting: RouteBasePaths.Reporting,
  EmailCampaigns: RouteBasePaths.EmailCampaigns,
  EmailTemplates: RouteBasePaths.EmailTemplates,
  Login: `${env.REACT_APP_LOGIN_URL}`,
  Logout: `${env.REACT_APP_LOGIN_URL}/logout`,
  Impersonate: `${RouteBasePaths.Impersonate}/:token`,
  Survey: RouteBasePaths.Survey,
  LoyaltyProgram: RouteBasePaths.LoyaltyProgram,
  SuccessCenter: `${RouteBasePaths.SuccessCenter}`,
  Support: RouteBasePaths.Support,
  OttoEmailCampaigns: `${RouteBasePaths.OttoEmailCampaigns}/:campaignId?/:campaignAction?`,
  GlobalAutomations: `${SettingsRoutes.GlobalAutomations}/:workflowEventSettingId?/:automationEditorView?`,
};

export const replaceParam = (route: string, param: string, replacement: string): string => {
  return route.replace(param, replacement);
};

const PrivateSwitch = (): JSX.Element => {
  const { isFeatureEnabled } = useFeatureFlag();

  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);

  return (
    <>
      <LoadingAndPresentation />
      <Switch>
        <ProtectedRoute path={RouteDefinitions.Appointments} layout={ContentLayout} component={Appointments} />
        <ProtectedRoute path={RouteDefinitions.Board} layout={ContentLayout} component={Board} />
        <ProtectedRoute path={RouteDefinitions.Conversations} layout={ContentLayout} component={Conversations} />
        <ProtectedRoute path={RouteDefinitions.Team} layout={ContentLayout} component={Team} />
        <ProtectedRoute path={RouteDefinitions.Settings} layout={ContentLayout} component={Settings} />
        <ProtectedRoute path={RouteDefinitions.Automations} layout={ContentLayout} component={Automations} />
        <ProtectedRoute path={RouteDefinitions.MessageTemplates} layout={ContentLayout} component={MessageTemplates} />
        <ProtectedRoute path={RouteDefinitions.Forms} exact layout={ContentLayout} component={Forms} />
        <ProtectedRoute path={RouteDefinitions.DemoWorkflow} exact layout={ContentLayout} component={WorkflowsDemo} />
        <ProtectedRoute path={RouteDefinitions.EmailCampaigns} layout={ContentLayout} component={EmailCampaigns} />
        <ProtectedRoute path={RouteDefinitions.EmailTemplates} layout={ContentLayout} component={EmailTemplates} />
        <ProtectedRoute path={RouteDefinitions.Reporting} layout={ContentLayout} component={Reporting} />
        <ProtectedRoute path={RouteDefinitions.Survey} layout={ContentLayout} component={Survey} />
        <ProtectedRoute path={RouteDefinitions.SuccessCenter} layout={ContentLayout} component={SuccessCenter} />
        <ProtectedRoute
          path={RouteDefinitions.GlobalAutomations}
          layout={ContentLayout}
          component={GlobalAutomations}
        />
        <ProtectedRoute
          path={RouteDefinitions.OttoEmailCampaigns}
          layout={ContentLayout}
          component={OttoEmailCampaigns}
        />
        <ProtectedRoute
          path={RouteDefinitions.ChannelMessageAttachments}
          layout={ContentLayout}
          component={ChannelMessageAttachment}
        />
        {isFeatureEnabled(FeatureFlagName.LoyaltyProgram) && (
          <ProtectedRoute path={RouteDefinitions.LoyaltyProgram} layout={ContentLayout} component={LoyaltyProgram} />
        )}
        <Redirect
          from="*"
          to={params ? `${RouteDefinitions.Conversations}?${params}` : RouteDefinitions.Conversations}
        />
      </Switch>
    </>
  );
};

const Routes = (): JSX.Element => {
  return (
    <Router history={history}>
      <Switch>
        <Route path={RouteDefinitions.TokenLogin} component={TokenLogin} />
        <Route path={RouteDefinitions.Impersonate} component={Impersonate} />
        <Route path={RouteDefinitions.AcceptInvitation} exact component={AcceptInvitation} />
        <Route path={RouteDefinitions.HealthCheck} exact component={HealthCheck} />
        <PrivateSwitch />
      </Switch>
    </Router>
  );
};

export default Routes;
