import uniqBy from 'lodash-es/uniqBy';
import { Appointment } from 'pages/Board';
import React, { useMemo } from 'react';
import useClinicUser from 'shared/hooks/useClinicUser';
import { useGetPaginatedAppointments } from 'shared/hooks/useGetPaginatedAppointments';
import { BoardFilterView, ChannelStatusAction, UpdateUserBoardFilterSettingsMutationFn } from 'shared/types/graphql';
import { useAppSelector } from 'state/hooks';
import BoardCard from '../Cards/BoardCard';
import BoardColumn from './BoardColumn';

interface AppointmentsColumnProps {
  columnName: string;
  columnColor: string;
  onEditAppointment: (appointment: Partial<Appointment>) => void;
  channelStatusId: string;
  unreadChannelIds?: string[];
  fetchMoreColumns: () => Promise<void>;
  isEmpty?: boolean;
  isCollapsed?: boolean;
  channelStatusAction?: ChannelStatusAction;
  updateBoardSettings: UpdateUserBoardFilterSettingsMutationFn;
}

export const NO_STATUS = 'No Status';

const AppointmentsColumn = ({
  columnName,
  columnColor,
  onEditAppointment,
  channelStatusId,
  unreadChannelIds,
  fetchMoreColumns,
  channelStatusAction,
  isEmpty = true,
  isCollapsed = false,
  updateBoardSettings,
}: AppointmentsColumnProps): JSX.Element => {
  const { currentClinicId } = useClinicUser();
  const { boardSettings } = useAppSelector((state) => state.board);
  const hasNoStatus = useMemo(() => channelStatusId === NO_STATUS, [channelStatusId]);

  const { appointments, isLoadingAppointments, appointmentsPage, hasNextPage, totalAppointments, setAppointmentsPage } =
    useGetPaginatedAppointments({
      clinicId: currentClinicId || '',
      dateRange: boardSettings?.specificDateRange,
      shouldSkip: isEmpty,
      shouldFetchCountOnly: isCollapsed,
      ...(hasNoStatus && { hasNoStatus: true, channelStatusIds: undefined }),
      ...(channelStatusId && !hasNoStatus && { channelStatusIds: [channelStatusId] }),
    });

  // Ensure we are only showing unique appointments for this channel status id
  const filteredAppointments = useMemo(() => {
    const uniqueAppts = uniqBy(appointments, 'id');
    if (hasNoStatus) return uniqueAppts;
    return uniqueAppts?.filter((appointment) => appointment?.channel?.channelStatus?.id === channelStatusId);
  }, [channelStatusId, appointments, hasNoStatus]);

  return (
    <BoardColumn
      columnName={columnName}
      columnColor={columnColor}
      {...(channelStatusId === NO_STATUS && { borderColor: 'border.default' })}
      channelStatusId={channelStatusId}
      numberOfCards={totalAppointments || 0}
      isLoading={isLoadingAppointments}
      currentPage={appointmentsPage}
      setPageNumber={setAppointmentsPage}
      hasNextPage={hasNextPage}
      fetchMoreColumns={fetchMoreColumns}
      isEmpty={isEmpty}
      isCollapsed={isCollapsed}
      channelStatusAction={channelStatusAction}
      updateBoardSettings={updateBoardSettings}
    >
      {filteredAppointments?.map((appointment) => {
        const channelId = appointment?.channel?.id || '';
        const pet = appointment.clinicPet;

        return (
          <BoardCard
            key={appointment.id}
            cardType={BoardFilterView.Appointments}
            appointmentData={appointment}
            channelStatus={appointment?.channel?.channelStatus}
            channelId={appointment?.channel?.id}
            petParents={appointment.clinicPetParents}
            pets={pet ? [pet] : []}
            onEditAppointment={onEditAppointment}
            hasUnreadMessages={channelId && unreadChannelIds ? unreadChannelIds.includes(channelId) : false}
            lastMessageCreatedAtDate={appointment?.channel?.lastMessage?.createdAt}
            channelUpdatedAtDate={appointment?.channel?.updatedAt}
          />
        );
      })}
    </BoardColumn>
  );
};

export default AppointmentsColumn;
