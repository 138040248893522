import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { Button } from '@televet/televet-ui';
import { FormsContext } from 'pages/Forms/context/FormsContext';
import WildCardButton from 'shared/components/WildCardButton';
import WildcardTextInput, { WildcardInputQuestionTypes } from './WildcardInputs/WildcardTextInput';
import { Box, Flex } from '@televet/kibble-ui/build/chakra';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';

interface IOptionComponentProps {
  index: number;
  optionIndex: number;
  option: string;
  options: string[];
}

const OptionComponent = ({ index, optionIndex, options, option }: IOptionComponentProps): JSX.Element => {
  const [showPreview, setShowPreview] = useState<boolean | undefined>(true);
  const [optionText, setOptionText] = useState<string>(option);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const { isPatientIdSupported, isClientIdSupported } = useIntegrationsProvider();
  const textInputRef = useRef<HTMLInputElement | null>(null);
  const { dispatch, hasChanges, hasFormTemplateRecentlyChanged } = useContext(FormsContext);

  const handleOptionValueChange = (newValue: string): void => {
    setOptionText(newValue);
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout((): void => {
      dispatch({
        type: 'CHANGE_OPTION_VALUE_AT_INDEX',
        payload: { questionIndex: index, optionIndex, option: newValue },
      });
    }, 500);

    setTimer(newTimer);
  };

  const handleAddWildcard = (value: string, optionIndex: number): void => {
    setShowPreview(false);
    setOptionText(value);
    dispatch({
      type: 'CHANGE_OPTION_VALUE_AT_INDEX',
      payload: { questionIndex: index, optionIndex, option: value },
    });
  };

  const handleRemoveOption = (optionIndex: number): void => {
    dispatch({
      type: 'REMOVE_OPTION_AT_INDEX',
      payload: { questionIndex: index, optionIndex },
    });
  };

  useEffect(() => {
    // showPreview is undefined when wildcard menu is opened, so let focus go to menu input then
    if (showPreview === false && textInputRef.current) {
      textInputRef.current.focus();
    }
  }, [showPreview]);

  useEffect(() => {
    if (!hasChanges) {
      setOptionText(option);
    }
  }, [hasChanges, option]);

  useEffect(() => {
    if (hasFormTemplateRecentlyChanged) {
      setOptionText(option);
      dispatch({
        type: 'FORM_TEMPLATE_CHANGE',
        payload: {},
      });
    }
  }, [dispatch, hasFormTemplateRecentlyChanged, option]);

  return (
    <Box my="10px">
      <Flex mb="10px" alignItems="flex-end">
        <Box w="100%" mr="10px">
          <Text as="p" size="sm" fontWeight="bold" mb="10px">{`Option ${optionIndex + 1}`}</Text>
          <WildcardTextInput
            text={optionText}
            placeholder={`Option ${optionIndex + 1}`}
            type={WildcardInputQuestionTypes.Question}
            showPreview={showPreview}
            inputRef={textInputRef}
            allowFillInTheBlanks={false}
            errorMessage={
              'Fill-in-the-blank wildcards are not supported in multiple select options. This value will not be replaced when the form is sent.'
            }
            handleChange={handleOptionValueChange}
            setShowPreview={setShowPreview}
          />
        </Box>
        {options.length > 2 && (
          <RemoveButton buttonType="ghost" onClick={(): void => handleRemoveOption(optionIndex)}>
            Remove Option {optionIndex + 1}
          </RemoveButton>
        )}
      </Flex>
      <WildCardButton
        textAreaRef={textInputRef}
        stateHandler={(value: string): void => {
          handleAddWildcard(value, optionIndex);
        }}
        setShowPreview={setShowPreview}
        petName
        clientName
        clientFirstName
        clientLastName
        clientPhone
        clientEmail
        petSpecies
        appointmentType
        petId={isPatientIdSupported}
        clientId={isClientIdSupported}
      />
    </Box>
  );
};

export default OptionComponent;

const RemoveButton = styled(Button)`
  width: 195px;
  margin-bottom: 0;
  border: 1px solid hsl(220deg 45% 40%);
  color: hsl(220deg 45% 40%);
  white-space: nowrap;
  display: inline-block;

  &:hover {
    border: 1px solid hsl(220deg 45% 40%) !important;
    background-color: hsl(220deg 45% 40%) !important;
    color: hsl(220deg 15% 95%) !important;
  }
`;
