import React, { useMemo } from 'react';
import {
  ChannelViewChannelMemberFragment,
  ChannelViewChannelMessageFragment,
  MessageType,
  SmsNotificationStatusFragment,
  UserType,
} from 'shared/types/graphql';
import { IAuthor } from '.';
import { Icon, Text, HStack } from '@televet/kibble-ui';
import SmsNotificationStatusIndicator from './SmsNotificationStatusIndicator';
import { INotificationErrorTextProps } from '../../ChannelView';

interface IDeliveryStatusProps {
  message: ChannelViewChannelMessageFragment;
  seenByMembers: Array<ChannelViewChannelMemberFragment>;
  author: IAuthor;
  hasSmsConversations: boolean;
  isAutomationMessage: boolean;
  pushIsEnabled: boolean;
  smsNotificationStatus?: SmsNotificationStatusFragment;
  isSmsNotificationStatusLoading: boolean;
  phoneWarnings: INotificationErrorTextProps[];
  isLastVisibleMessage: boolean;
  isTeamChannel: boolean;
  currentUserId?: string;
}

const DeliveryStatus = ({
  message,
  seenByMembers,
  author,
  hasSmsConversations,
  isAutomationMessage,
  pushIsEnabled,
  smsNotificationStatus,
  isSmsNotificationStatusLoading,
  phoneWarnings,
  isLastVisibleMessage,
  isTeamChannel,
  currentUserId,
}: IDeliveryStatusProps): JSX.Element | null => {
  const seenByNames = useMemo(() => {
    return seenByMembers
      ?.filter(
        (member) =>
          (member.user?.id || member.clinicPetParent?.id) !== author.id && (isTeamChannel || !!member.clinicPetParent),
      )
      .map((member) => {
        const person = member?.user || member.clinicPetParent;
        return [person?.firstName, person?.lastName].join(' ').trim();
      });
  }, [seenByMembers, author, isTeamChannel]);

  const shouldShowSmsStatus =
    hasSmsConversations &&
    message.messageType === MessageType.Message &&
    !isAutomationMessage &&
    message.forceReadReceipt !== true &&
    !pushIsEnabled;

  if (message.messageType === MessageType.Note || author.type === UserType.Owner) {
    return null;
  }

  if (seenByNames?.length) {
    return (
      <HStack className="MessageGroup__SeenBy" spacing={1} mt={1}>
        <Icon size="xs" variant="subtle" name="eyeOpen" />
        <Text size="xs" variant="subtle">
          {seenByNames.join(', ')}
        </Text>
      </HStack>
    );
  }

  if (shouldShowSmsStatus) {
    return (
      <SmsNotificationStatusIndicator
        messageDate={message.createdAt}
        smsNotificationStatus={smsNotificationStatus || message.smsNotifificationStatuses?.[0]}
        isLoading={isSmsNotificationStatusLoading}
        phoneWarnings={phoneWarnings}
      />
    );
  }

  if (isLastVisibleMessage && (!isTeamChannel || author.id === currentUserId)) {
    return (
      <HStack className="MessageGroup__SeenBy" spacing={1} mt={1}>
        <Icon size="xs" variant="subtle" name="eyeSlash" />
        <Text size="xs" variant="subtle">
          Not Seen Yet
        </Text>
      </HStack>
    );
  }

  return null;
};

export default DeliveryStatus;
