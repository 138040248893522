import { ApolloCache, FetchResult, Reference } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useDisclosure } from '@televet/kibble-ui/build/chakra';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GraphQLFetchPolicies } from 'shared/enums';
import { GA4Events } from 'shared/enums/GA4Events';
import useGA from 'shared/hooks/useGA';
import {
  DeleteEmailCampaignCustomListMutation,
  EmailCampaign,
  UploadEmailCampaignCustomListMutation,
  useDeleteEmailCampaignCustomListMutation,
  useGetEmailCampaignCustomListUrlLazyQuery,
  useGetSignedUploadUrlMutation,
  useUploadEmailCampaignCustomListMutation,
} from 'shared/types/graphql';
import { downloadAndRenameFile } from 'shared/utils/fileDownloader';
import { SegmentProps } from '../../components/Segment';
import { MAX_RECIPIENTS } from '../../ConditionalSegments/constants/conditionalSegments.constants';

const MAX_RECORD_COUNT = MAX_RECIPIENTS + 1; // contacts + 1 header row;

export enum FileStatus {
  NotLoaded,
  Loading,
  RecipientCountValidationError,
  BadFormatValidationError,
  ReadyToUpload,
  Uploading,
  ServerError,
  Success,
  ViewingServerFile,
}

type UseCustomListParams = Pick<SegmentProps, 'isSelected' | 'onSelect'> & {
  displayEmptyValidationMessage: boolean;
  campaign?: EmailCampaign;
};

export type UseCustomListReturnType = {
  downloadList: () => Promise<void>;
  deleteCustomList: () => Promise<void>;
  isInformationModalOpen: boolean;
  openInformationModal: () => void;
  closeInformationModal: () => void;
  isDeleteConfirmationModalOpen: boolean;
  openDeleteConfirmationModal: () => void;
  closeDeleteConfirmationModal: () => void;
  inputFileRef: React.MutableRefObject<HTMLInputElement | null>;
  fileStatus: FileStatus;
  setFileStatus: React.Dispatch<React.SetStateAction<FileStatus>>;
  recordCount: number;
  setRecordCount: React.Dispatch<React.SetStateAction<number>>;
  completeProgressAnimation: boolean;
  setCompleteProgressAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  isDownloading: boolean;
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>;
  onFileChange: (file: File) => void;
  isDeleting: boolean;
  isLoadingCustomList: boolean;
} & UseCustomListParams;

export default function useCustomList({
  campaign,
  displayEmptyValidationMessage,
  onSelect,
  isSelected,
}: UseCustomListParams): UseCustomListReturnType {
  const {
    isOpen: isInformationModalOpen,
    onOpen: openInformationModal,
    onClose: closeInformationModal,
  } = useDisclosure();
  const {
    isOpen: isDeleteConfirmationModalOpen,
    onOpen: openDeleteConfirmationModal,
    onClose: closeDeleteConfirmationModal,
  } = useDisclosure();
  const { gaTrack } = useGA();
  const inputFileRef = useRef<HTMLInputElement | undefined>(null) as React.MutableRefObject<HTMLInputElement | null>;
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [fileStatus, setFileStatus] = useState<FileStatus>(
    campaign?.customList ? FileStatus.ViewingServerFile : FileStatus.NotLoaded,
  );
  const [recordCount, setRecordCount] = useState(0);
  const [completeProgressAnimation, setCompleteProgressAnimation] = useState(false);

  const [getSignedUploadUrl] = useGetSignedUploadUrlMutation();
  const [uploadList] = useUploadEmailCampaignCustomListMutation();
  const [deleteList, { loading: isDeleting }] = useDeleteEmailCampaignCustomListMutation();
  const [getFileDownloadUrl] = useGetEmailCampaignCustomListUrlLazyQuery();
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadList = useCallback(async () => {
    setIsDownloading(true);
    try {
      gaTrack(GA4Events.EMAIL_CAMPAIGNS_REJECTED_RECIPIENTS, {
        campaignId: campaign?.id,
        awsFileKey: campaign?.customList?.awsFileKey,
        fileName: campaign?.customList?.fileName,
      });

      const res = await getFileDownloadUrl({
        variables: {
          data: {
            emailCampaignId: campaign?.id || '',
          },
        },
        fetchPolicy: GraphQLFetchPolicies.NoCache,
      });

      const url = res?.data?.getEmailCampaignCustomListUrl;

      if (url) {
        await downloadAndRenameFile(url, campaign?.customList?.fileName || 'custom_list.csv');
      }
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setIsDownloading(false);
    }
  }, [campaign?.customList?.awsFileKey, campaign?.customList?.fileName, campaign?.id, gaTrack, getFileDownloadUrl]);

  const deleteCustomList = useCallback(async (): Promise<void> => {
    gaTrack(GA4Events.EMAIL_CAMPAIGNS_CUSTOM_LIST_DELETE, {
      campaignId: campaign?.id,
      awsFileKey: campaign?.customList?.awsFileKey,
      fileName: campaign?.customList?.fileName,
    });
    const res = await deleteList({
      variables: {
        data: {
          emailCampaignId: campaign?.id || '',
        },
      },
      update: updateDeletedCampaignCustomListCache(campaign),
    });

    if (res?.data?.deleteEmailCampaignCustomList) {
      closeDeleteConfirmationModal();
      const current = inputFileRef?.current;
      if (current) {
        current.value = '';
      }
      setFileStatus(FileStatus.NotLoaded);
    }
  }, [campaign, closeDeleteConfirmationModal, deleteList, gaTrack]);

  const areHeadersValid = useCallback((headers: string): boolean => {
    const expectedHeaders = ['Email Address', 'First Name', 'Last Name'];
    const headersArr = headers.split(',').map((header) => header.trim().toLowerCase());
    const expectedHeadersNormalized = expectedHeaders.map((header) => header.toLowerCase());
    return headersArr.length > 0 && expectedHeadersNormalized.every((header) => headersArr.includes(header));
  }, []);

  const onFileChange = useCallback(
    (file: File) => {
      onSelect();
      const trackUploadFailure = (errorType: string): void =>
        gaTrack(GA4Events.EMAIL_CAMPAIGNS_CUSTOM_UPLOAD_FAILURE, {
          campaignId: campaign?.id,
          fileName: file.name,
          fileType: file.type,
          size: file.size,
          errorType,
        });

      if (file.type !== 'text/csv') {
        trackUploadFailure('invalidFileType');
        return setFileStatus(FileStatus.BadFormatValidationError);
      }
      setFileStatus(FileStatus.Loading);
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>): void => {
        const text = e.target?.result as string;
        const lines = text.split(/\r\n|\n/);
        if (lines.length > MAX_RECORD_COUNT) {
          trackUploadFailure('maxRecordCount');
          setFileStatus(FileStatus.RecipientCountValidationError);
        } else if (areHeadersValid(lines[0]) === false) {
          trackUploadFailure('invalidHeaders');
          setFileStatus(FileStatus.BadFormatValidationError);
        } else {
          setRecordCount(lines.length);
          setCurrentFile(file);
          setFileStatus(FileStatus.ReadyToUpload);
        }
      };
      reader.readAsText(file);
    },
    [areHeadersValid, campaign?.id, gaTrack, onSelect],
  );

  useEffect(() => {
    // When the file is ready to upload (after validation),
    // we create a static, unique key at the campaign level to reference it in AWS.
    // We retrieve a signed URL and then upload the file to AWS directly from the front-end.
    // Next, we call the uploadList mutation to run logic and validations to save references in the database.
    // Once the record is created, we attach it to the campaign cache.
    if (fileStatus === FileStatus.ReadyToUpload) {
      const uploadToAwsAndSaveList = async (): Promise<void> => {
        if (!currentFile || !campaign) {
          return;
        }
        const awsFileKey = `2Months_${campaign.id}_${new Date().getTime()}_custom_list.csv`;
        gaTrack(GA4Events.EMAIL_CAMPAIGNS_CUSTOM_UPLOAD, {
          campaignId: campaign.id,
          awsFileKey,
          fileName: currentFile.name,
        });

        try {
          setFileStatus(FileStatus.Uploading);
          setCompleteProgressAnimation(false);
          const { data } = await getSignedUploadUrl({
            variables: {
              data: {
                key: awsFileKey,
                contentType: currentFile.type,
                overrideClinicIdWith: 'auto-expire',
              },
            },
          });

          if (!data?.getSignedUploadUrl.uploadUrl) {
            throw new Error('Upload Failed');
          }

          await fetch(data.getSignedUploadUrl.uploadUrl, {
            method: 'Put',
            body: currentFile,
            headers: { 'Content-Type': currentFile.type },
          });

          await uploadList({
            variables: {
              data: {
                awsFileKey,
                emailCampaignId: campaign.id,
                fileName: currentFile?.name,
              },
            },
            update: updateCreatedCampaignCustomListCache(campaign),
          });
          setCompleteProgressAnimation(true);
          setTimeout(() => {
            setFileStatus(FileStatus.ViewingServerFile);
          }, 500);
        } catch (e) {
          Sentry.captureException(e);
          setFileStatus(FileStatus.ServerError);
        }
      };
      uploadToAwsAndSaveList();
    }
  }, [campaign, currentFile, fileStatus, gaTrack, getSignedUploadUrl, uploadList]);

  return {
    isSelected,
    onSelect,
    inputFileRef,
    onFileChange,
    fileStatus,
    recordCount,
    isDownloading,
    downloadList,
    isInformationModalOpen,
    isDeleteConfirmationModalOpen,
    deleteCustomList,
    isDeleting,
    completeProgressAnimation,
    displayEmptyValidationMessage,
    campaign,
    setCompleteProgressAnimation,
    setFileStatus,
    setIsDownloading,
    setRecordCount,
    openInformationModal,
    closeInformationModal,
    closeDeleteConfirmationModal,
    openDeleteConfirmationModal,
    isLoadingCustomList: fileStatus === FileStatus.Uploading,
  };
}

type ModifiedEntity<T> = FetchResult<T, Record<string, unknown>, Record<string, unknown>>;
type Cache = ApolloCache<unknown>;

function updateDeletedCampaignCustomListCache(campaign?: EmailCampaign) {
  return (cache: Cache, result: ModifiedEntity<DeleteEmailCampaignCustomListMutation>): void => {
    if (campaign && result.data?.deleteEmailCampaignCustomList) {
      cache.modify({
        id: cache.identify(campaign),
        fields: {
          customList() {
            return null;
          },
        },
      });
    }
  };
}

function updateCreatedCampaignCustomListCache(campaign?: EmailCampaign) {
  return (cache: Cache, result: ModifiedEntity<UploadEmailCampaignCustomListMutation>): void => {
    if (campaign && result.data?.uploadEmailCampaignCustomList) {
      const { customList, customListHistory } = result.data.uploadEmailCampaignCustomList;
      cache.modify({
        id: cache.identify(campaign),
        fields: {
          customList() {
            return customList;
          },
          uploads(existingUploadsRefs: ReadonlyArray<Reference> = []) {
            return [...existingUploadsRefs, customListHistory];
          },
        },
      });
    }
  };
}
