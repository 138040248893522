import { CampaignAction } from '../enums';
import { ApolloQueryResult } from '@apollo/client';
import { Button } from '@televet/kibble-ui/build/components/Button';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@televet/kibble-ui/build/components/Modal';
import { TextInput } from '@televet/kibble-ui/build/components/TextInput';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import { Flex } from '@televet/kibble-ui/build/chakra';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteBasePaths } from 'routes';
import useClinicUser from 'shared/hooks/useClinicUser';
import { FindManyEmailCampaignQuery, useCreateOneEmailCampaignMutation } from 'shared/types/graphql';

type NewOttoEmailCampaignModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchCampaigns: () => Promise<ApolloQueryResult<FindManyEmailCampaignQuery>>;
  isLoadingCampaigns: boolean;
};

const NewOttoEmailCampaignModal = ({
  isOpen,
  setIsOpen,
  refetchCampaigns,
  isLoadingCampaigns,
}: NewOttoEmailCampaignModalProps): JSX.Element => {
  const campaignNameRef = useRef<HTMLInputElement>(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [createCampaign, { loading }] = useCreateOneEmailCampaignMutation();

  const { currentClinicId: clinicId, clinicUser, currentClinic } = useClinicUser();

  const toast = useToast();

  const history = useHistory();

  const userId = clinicUser?.id;

  const clinicEmail = currentClinic?.clinicEmail || null;

  const handleNextButtonClick = async (): Promise<void> => {
    setModalLoading(true);
    const campaignName = campaignNameRef.current?.value;
    if (!handleValidation(campaignName || '')) {
      setModalLoading(false);
      return;
    }
    const newCampaign = await createCampaign({
      variables: {
        data: {
          name: campaignName || '',
          clinic: { connect: { id: clinicId } },
          createdByUser: { connect: { id: userId } },
          lastUpdatedByUser: { connect: { id: userId } },
          from: `${currentClinic?.name} <no-reply@ottomail.vet>`,
          replyToJson: {
            email: clinicEmail || '',
            needsUserAttention: clinicEmail ? false : true,
          },
        },
      },
    });
    await refetchCampaigns();
    try {
      setIsOpen(false);
      history.push(
        `${RouteBasePaths.OttoEmailCampaigns}/${newCampaign.data?.createOneEmailCampaign?.id}/${CampaignAction.chooseTemplate}`,
      );
    } catch (e) {
      console.error(e);
      toast({
        title: 'Error while creating campaign',
        status: 'error',
      });
    } finally {
      setModalLoading(false);
    }
  };

  const handleClose = (): void => {
    if (campaignNameRef.current) {
      campaignNameRef.current.value = '';
      setErrorMessage('');
    }
    setIsOpen(false);
  };

  const handleValidation = (campaignName: string): boolean => {
    if (!campaignName) {
      setErrorMessage('Please enter a Campaign Name.');
      return false;
    }
    if (campaignName.length > 50) {
      setErrorMessage('Campaign name must be less than 50 characters.');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  return (
    <Modal allowEscape size="sm" isOpen={isOpen} onClose={handleClose}>
      <ModalHeader>Create New Campaign</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <TextInput
          label="Campaign Name"
          autoFocus
          ref={campaignNameRef}
          placeholder="My New Campaign"
          size="md"
          errorText={errorMessage}
          isInvalid={!!errorMessage}
          helperText="You can change this later."
          data-testid="create-campaign-name-input"
          onChange={(e): void => {
            handleValidation(e.currentTarget.value);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Flex justifyContent="space-between" flex="1">
          <Button variant="tertiary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(): void => {
              setTimeout(() => {
                handleNextButtonClick();
              }, 0);
            }}
            isLoading={loading || isLoadingCampaigns || modalLoading}
          >
            Next
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default NewOttoEmailCampaignModal;
