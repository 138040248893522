import React from 'react';
import { Modal, ModalProps } from '@televet/kibble-ui';

export default function withLazyModal<P extends Pick<ModalProps, 'isOpen' | 'onClose'>>(
  Component: React.ComponentType<P>,
  modalProps?: Partial<ModalProps>,
): {
  (props: P): JSX.Element;
  displayName: string;
} {
  const WrappedComponent = (props: P): JSX.Element => {
    return (
      <Modal {...modalProps} {...props}>
        {!!props.isOpen && <Component {...props} />}
      </Modal>
    );
  };
  WrappedComponent.displayName = `withLazyModal(${Component.displayName})`;
  return WrappedComponent;
}
