import { Button, Flex, Text, TextInput, VStack } from '@televet/kibble-ui';
import React from 'react';

type DialPadProps = {
  onKeyPress: (value: string) => void;
};

interface ButtonObject {
  value: string;
  label: string;
}

const buttons: ButtonObject[][] = [
  [
    { value: '1', label: '' },
    { value: '2', label: 'ABC' },
    { value: '3', label: 'DEF' },
  ],
  [
    { value: '4', label: 'GHI' },
    { value: '5', label: 'JKL' },
    { value: '6', label: 'MNO' },
  ],
  [
    { value: '7', label: 'PQRS' },
    { value: '8', label: 'TUV' },
    { value: '9', label: 'WXYZ' },
  ],
  [
    { value: '*', label: '' },
    { value: '0', label: '+' },
    { value: '#', label: '' },
  ],
];

const DialPad = ({ onKeyPress }: DialPadProps): JSX.Element => {
  const pressedKeysRef = React.useRef<HTMLInputElement>(null);
  const handleKeyPress = (button: ButtonObject): void => {
    if (pressedKeysRef.current) {
      pressedKeysRef.current.value += button.value;
      // make sure the input is scrolled to the end
      // if they keep having tones pressed
      pressedKeysRef.current.scrollLeft = pressedKeysRef.current.scrollWidth;
    }
    onKeyPress(button.value);
  };
  return (
    <VStack w="100%">
      <TextInput
        cursor="default"
        ref={pressedKeysRef}
        w="100%"
        isReadOnly
        _focus={{ outline: 0 }}
        _hover={{ outline: 0 }}
      />
      <Flex flexWrap="wrap">
        {buttons.map((buttonRow, index) => (
          <Flex key={`dial-pad-row-${index}`} justify="space-evenly" w="100%" gap={4} pb={4}>
            {buttonRow.map((button) => (
              <Button
                key={`dial-pad-button-${button.value}`}
                onClick={(): void => handleKeyPress(button)}
                variant="tertiary"
                w="100%"
              >
                <Flex justify="center" w="100%" align="flex-start">
                  <Text size="lg">{button.value}</Text>
                  <Text size="xs">{button.label}</Text>
                </Flex>
              </Button>
            ))}
          </Flex>
        ))}
      </Flex>
    </VStack>
  );
};

export default DialPad;
