import * as Sentry from '@sentry/react';
import { Menu } from '@televet/kibble-ui/build/components/Menu';
import React from 'react';
import { GraphQLFetchPolicies } from 'shared/enums';
import { EmailCampaignCustomListUploadHistory, useGetEmailCampaignCustomListUrlLazyQuery } from 'shared/types/graphql';
import { downloadAndRenameFile } from 'shared/utils/fileDownloader';

type CampaignListUploadHistoryMenuProps = {
  uploads: Partial<EmailCampaignCustomListUploadHistory>[];
};

export const CampaignListUploadHistoryMenu = ({ uploads }: CampaignListUploadHistoryMenuProps): JSX.Element => {
  const [getFileDownloadUrl] = useGetEmailCampaignCustomListUrlLazyQuery();

  return (
    <Menu
      buttonProps={{
        variant: 'tertiary',
        leftIconName: 'clock',
        size: 'sm',
        showRightIcon: false,
        text: 'Upload History',
        mr: 4,
      }}
      listProps={{
        canSelectAll: false,
        canSelectGroups: false,
        isMultiSelect: false,
        isSearchable: false,
        menuItems: uploads.map((upload) => ({
          label: upload.fileName,
          value: upload.id,
          isDisabled: new Date() > new Date(upload.expiresAt),
          onSelect: async (): Promise<void> => {
            try {
              const res = await getFileDownloadUrl({
                variables: {
                  data: {
                    historicalUploadId: upload.id,
                  },
                },
                fetchPolicy: GraphQLFetchPolicies.NoCache,
              });

              const url = res?.data?.getEmailCampaignCustomListUrl;

              if (url) {
                await downloadAndRenameFile(url, upload?.fileName || 'custom_list.csv');
              }
            } catch (e) {
              Sentry.captureException(e);
            }
          },
        })),
      }}
    />
  );
};
