import { CampaignAction } from '../enums';
import { ReactComponent as EmptyEmailPreview } from '../images/emailPreview.svg';
import EmailModalPreview from './EmailModalPreview';
import TemplatePreview from './TemplatePreview';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { TextInput } from '@televet/kibble-ui/build/components/TextInput';
import {
  Modal,
  ModalBody,
  ModalFooterButtons,
  ModalHeader,
  ModalCloseButton,
} from '@televet/kibble-ui/build/components/Modal';
import { Card } from '@televet/kibble-ui/build/components/Card';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import { Box, Flex, useDisclosure } from '@televet/kibble-ui/build/chakra';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteBasePaths } from 'routes';
import {
  EmailCampaign,
  EmailCampaignFragment,
  EmailCampaignSegmentationType,
  EmailCampaignStatus,
  FindManyEmailCampaignQuery,
  useSendEmailCampaignNowMutation,
  useUpdateOneEmailCampaignMutation,
} from 'shared/types/graphql';
import CampaignStatusTag from './CampaignStatusTag';
import { QUICKSIGHT_DASHBOARD_URL } from 'pages/Reporting/QuickSightReports/embedDashboard';
import { quickSightRelativePath } from 'pages/Reporting';
import ReplyTo from './ReplyTo';
import useGA from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';
import { CampaignSummaryElement, CampaignSummaryElementState } from './CampaignSummaryElement';
import Recipients from './Recipients/Recipients';
import useRecipients from './Recipients/hooks/useRecipients';
import ConditionalSegmentSummary from './Recipients/components/ConditionalSegmentSummary';
import CannotSendAlert from './CannotSendAlert';
import useEmailCampaigns from '../hooks/useEmailCampaigns';
import { MAX_RECIPIENTS } from './Recipients/ConditionalSegments/constants/conditionalSegments.constants';

type CampaignSummaryProps = {
  refetchEmailCampaigns: () => void;
  campaigns: FindManyEmailCampaignQuery | undefined;
  campaignId: string;
  recentlySentOrSendingCampaign?: {
    elapsedDays: number;
    campaign: EmailCampaignFragment;
  };
  loadingAdditionalSegmentProps: boolean;
  additionalSegmentProps: Record<string, unknown>;
};

const CampaignSummary = ({
  campaigns,
  campaignId,
  refetchEmailCampaigns,
  recentlySentOrSendingCampaign,
  loadingAdditionalSegmentProps,
  additionalSegmentProps,
}: CampaignSummaryProps): JSX.Element => {
  const campaign = campaigns?.findManyEmailCampaign?.find((campaign) => campaign.id === campaignId);
  const [isEditSubject, setIsEditSubject] = useState(false);
  const [nameDraft, setNameDraft] = useState(campaign?.name || '');
  const [subjectDraft, setSubjectDraft] = useState(campaign?.subject || '');
  const recipients = useRecipients({ campaign } as { campaign: EmailCampaign });
  const { gaTrack } = useGA();
  const toast = useToast();
  const { canSendEmailCampaigns } = useEmailCampaigns();

  const [nameValidationObject, setNameValidationObject] = useState({
    isValid: true,
    errorMessage: '',
  });
  const [subjectValidationObject, setSubjectValidationObject] = useState({
    isValid: true,
    errorMessage: '',
  });
  const [templateValidationObject, setTemplateValidationObject] = useState({
    isValid: true,
  });

  const history = useHistory();

  useEffect(() => {
    const campaign = campaigns?.findManyEmailCampaign?.find((campaign) => campaign.id === campaignId);
    if (!campaign || !!campaign.deletedAt) {
      history.push(RouteBasePaths.OttoEmailCampaigns);
    }
  }, [campaignId, campaigns?.findManyEmailCampaign, history]);

  const { isOpen: isEditNameOpen, onClose: onEditNameClose, onOpen: onEditNameOpen } = useDisclosure();
  const { isOpen: isSendCampaignOpen, onClose: onSendCampaignClose, onOpen: onSendCampaignOpen } = useDisclosure();

  const border = '1px';
  const borderColor = 'border.default';
  const subject = campaign?.subject || '';
  const from = campaign?.from || '';
  const [updateOneEmailCampaign, { loading }] = useUpdateOneEmailCampaignMutation();

  const [sendEmailCampaignNow, { loading: sendingCampaign }] = useSendEmailCampaignNowMutation();

  const handleSaveSubject = async (): Promise<void> => {
    if (!subjectValidationObject.isValid) {
      return;
    }
    await updateOneEmailCampaign({
      variables: {
        where: { id: campaignId },
        data: { subject: subjectDraft },
      },
    });
    setIsEditSubject(false);
  };

  const handleSaveName = async (): Promise<void> => {
    if (!nameValidationObject.isValid) {
      return;
    }
    await updateOneEmailCampaign({
      variables: {
        where: { id: campaignId },
        data: { name: nameDraft, startsAt: new Date().toISOString() },
      },
    });
    onEditNameClose();
  };

  const handleSendCampaign = async (): Promise<void> => {
    if (sendingCampaign) {
      return;
    }
    await sendEmailCampaignNow({ variables: { data: { emailCampaignId: campaignId } } });
    refetchEmailCampaigns();
    history.push(RouteBasePaths.OttoEmailCampaigns);

    onSendCampaignClose();
  };

  const handleCloseModal = (): void => {
    onEditNameClose();
  };

  const handleNameValidation = (campaignName: string): void => {
    let isValid = true;
    let errorMessage = '';
    if (!campaignName) {
      errorMessage = 'Please enter a Campaign Name.';
      isValid = false;
    }
    if (campaignName.length > 50) {
      errorMessage = 'Campaign name must be less than 50 characters.';
      isValid = false;
    }
    setNameValidationObject({ isValid, errorMessage });
  };

  const handleSubjectValidation = (subject: string): void => {
    let isValid = true;
    let errorMessage = '';
    if (!subject) {
      errorMessage = 'Please enter a subject.';
      isValid = false;
    }
    if (subject.length > 50) {
      errorMessage = 'Subject must be less than 50 characters.';
      isValid = false;
    }
    setSubjectValidationObject({ isValid, errorMessage });
  };

  const handleTemplateValidation = (): void => {
    let isValid = true;
    if (!campaign?.htmlContent) {
      isValid = false;
    }
    setTemplateValidationObject({ isValid });
  };

  const hasConditionalSegments = useMemo(
    () => !!campaign?.segmentArgs && !!campaign?.segmentArgs?.conditionalSegments,
    [campaign],
  );

  const { title, summary } = useMemo(() => {
    const isCustomList =
      campaign?.segmentationType === EmailCampaignSegmentationType.CustomList && campaign?.customList;
    const isSegmented =
      campaign?.segmentationType === EmailCampaignSegmentationType.ClinicPetParentPrismaWhere && hasConditionalSegments;

    if (isCustomList) {
      return {
        title: 'Custom List',
        summary: (
          <Text fontWeight="normal" color="text.subtle" as="p">
            {campaign?.customList?.fileName}
          </Text>
        ),
      };
    }

    if (isSegmented) {
      return {
        title: 'Filter All Clients',
        summary: (
          <Box ml="2" mb="2" color="text.subtle">
            <ConditionalSegmentSummary conditionalSegments={campaign?.segmentArgs} />
          </Box>
        ),
      };
    }

    return {
      title: 'Active Clients',
      summary: null,
    };
  }, [campaign, hasConditionalSegments]);

  return (
    <Flex
      className="OttoEmailCampaignSummary__Container"
      position="relative"
      overflow="auto"
      justifyContent="center"
      height="100%"
      padding="40px 85px"
      bgColor="background.default"
    >
      <Flex maxWidth="1024px" width="100%" direction="column">
        <Modal
          size="sm"
          allowEscape
          isOpen={isEditNameOpen}
          onClose={(): void => {
            //
          }}
        >
          <ModalCloseButton
            onClick={(): void => {
              handleCloseModal();
            }}
          />
          <ModalHeader>Edit Campaign Name</ModalHeader>
          <ModalBody>
            <TextInput
              label="Campaign Name"
              autoFocus
              placeholder={campaign?.name || 'Campaign name'}
              isInvalid={!nameValidationObject.isValid}
              errorText={nameValidationObject.errorMessage}
              value={nameDraft}
              onChange={(e): void => {
                handleNameValidation(e.currentTarget.value);
                setNameDraft(e.currentTarget.value);
              }}
            />
          </ModalBody>
          <ModalFooterButtons
            onCancel={(): void => {
              handleCloseModal();
            }}
            onSubmit={(): void => {
              handleSaveName();
            }}
            isLoading={loading}
          ></ModalFooterButtons>
        </Modal>

        <Modal allowEscape autoFocus size="sm" isOpen={isSendCampaignOpen} onClose={onSendCampaignClose}>
          <ModalCloseButton
            onClick={(): void => {
              onSendCampaignClose();
            }}
          />
          <ModalHeader>Ready to send?</ModalHeader>
          <ModalBody>
            <Text as="p">You&apos;re about to send an email to:</Text>
            <Text as="p" fontWeight="semibold" mt="2">
              {title}
            </Text>
            <Text>
              {recipients.recipientCount} {recipients.recipientCount === 1 ? 'Recipient' : 'Recipients'}
            </Text>
            {summary}
            {!!recentlySentOrSendingCampaign && (
              <Alert status="info" hideCloseButton={true} marginTop="2">
                <Text fontWeight="semibold" as="p">
                  {recentlySentOrSendingCampaign.campaign.status === EmailCampaignStatus.Sending
                    ? 'It looks like you already have a campaign sending'
                    : `It looks like you sent an email campaign ${
                        recentlySentOrSendingCampaign.elapsedDays === 0
                          ? 'today'
                          : `${recentlySentOrSendingCampaign.elapsedDays} ${
                              recentlySentOrSendingCampaign.elapsedDays === 1 ? 'day ago' : 'days ago'
                            }`
                      }.`}
                </Text>
                <Text>
                  Sending frequent email campaigns can lead to higher unsubscribe rates. Please see{' '}
                  <Text
                    variant="interactive"
                    textDecoration="underline"
                    onClick={(): void => {
                      gaTrack(GA4Events.EMAIL_CAMPAIGN_BEST_PRACTICES_CLICK);
                    }}
                  >
                    <a
                      href="https://help.otto.vet/hc/en-us/articles/22278182348941-Otto-Email-Campaigns"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Best Practices
                    </a>
                  </Text>
                  .
                </Text>
              </Alert>
            )}
          </ModalBody>
          <ModalFooterButtons
            submitText="Send Now"
            onCancel={(): void => {
              onSendCampaignClose();
            }}
            isLoading={loading || sendingCampaign}
            onSubmit={(): void => {
              handleSendCampaign();
            }}
          ></ModalFooterButtons>
        </Modal>
        <Flex>
          <Button
            size="sm"
            iconName="chevronLeft"
            variant="ghost"
            marginLeft="-3"
            onClick={(): void => {
              history.push(RouteBasePaths.OttoEmailCampaigns);
            }}
          >
            All Campaigns
          </Button>
        </Flex>
        <Flex mb="8" mt="2" justifyContent="space-between">
          <Box>
            <Heading>{campaign?.name}</Heading>

            {campaign?.status === EmailCampaignStatus.Draft ? (
              <Button mt="4" onClick={onEditNameOpen} variant="tertiary" iconName="pen">
                Edit Campaign Name
              </Button>
            ) : (
              <Box mt="3">
                <CampaignStatusTag big status={campaign?.status as EmailCampaignStatus} />
              </Box>
            )}
          </Box>
          {campaign?.status === EmailCampaignStatus.Draft ? (
            <Flex>
              <Button
                data-testid="email-campaign-finish-later-button"
                variant="secondary"
                mr="4"
                onClick={(): void => history.push(RouteBasePaths.OttoEmailCampaigns)}
              >
                Finish Later
              </Button>

              <Button
                data-testid="email-campaign-send-now-button"
                iconName="emailSend"
                isDisabled={!canSendEmailCampaigns}
                onClick={(): void => {
                  if (!recipients.recipientCount || recipients?.recipientCount > MAX_RECIPIENTS) {
                    toast({
                      status: 'error',
                      title: !recipients.recipientCount
                        ? 'This campaign has no recipients'
                        : 'This campaign has too many recipients',
                      description: !recipients.recipientCount
                        ? "You'll need at least one recipient in order to send this campaign."
                        : "You'll need to reduce the number of recipients to a maximum of 15k in order to send this campaign.",
                    });
                    recipients.handleRecipientsValidation();
                    return;
                  }

                  if (!subject || !campaign.htmlContent) {
                    handleSubjectValidation(campaign.subject || '');
                    handleTemplateValidation();
                    return;
                  }
                  onSendCampaignOpen();
                }}
              >
                Send Now
              </Button>
            </Flex>
          ) : (
            <Button
              iconName="report"
              onClick={(): void => {
                history.push(
                  `${RouteBasePaths.Reporting}${quickSightRelativePath}/${QUICKSIGHT_DASHBOARD_URL.EmailCampaigns}`,
                );
              }}
            >
              View Report
            </Button>
          )}
        </Flex>

        {!canSendEmailCampaigns && <CannotSendAlert />}

        <Flex flex="0">
          <Box>
            <Box
              flexDirection="column"
              border={border}
              borderColor={borderColor}
              borderRadius="lg"
              mr="4"
              pb="1"
              mb="40px"
            >
              <Flex borderBottom={border} borderColor={borderColor} justifyContent="space-between" padding="2">
                <Flex flex="1" paddingLeft="2">
                  <Box
                    ml="-8px"
                    mr="8px"
                    borderRadius="4px"
                    bgColor={
                      !subjectValidationObject.isValid
                        ? 'background.danger'
                        : !!subject
                        ? 'background.light'
                        : 'border.warning'
                    }
                    minWidth="4px"
                    maxWidth="4px"
                    width="4px"
                  />
                  <Box minWidth="24px" minHeight="24px" paddingY="2">
                    {!!subject ? (
                      <Icon size="lg" name="checkmarkCircle" variant="success" />
                    ) : (
                      <Icon
                        size="lg"
                        name="alertCircle"
                        variant={!subjectValidationObject.isValid ? 'danger' : 'warning'}
                      />
                    )}
                  </Box>
                  <Box width="100%" ml="2" paddingY="2">
                    <Text as="p" fontWeight="bold" fontSize="xl">
                      Subject
                    </Text>
                    {isEditSubject ? (
                      <Box width="100%" paddingRight="3">
                        <Flex mt="2" mb="4">
                          <TextInput
                            data-test-id="email-campaign-subject-input"
                            autoFocus
                            value={subjectDraft}
                            isInvalid={!subjectValidationObject.isValid}
                            errorText={subjectValidationObject.errorMessage}
                            onChange={(e): void => {
                              setSubjectDraft(e.target.value);
                              handleSubjectValidation(e.target.value);
                            }}
                          />
                        </Flex>
                        <Flex justifyContent="flex-end">
                          <Button
                            variant="tertiary"
                            mr="4"
                            onClick={(): void => {
                              setSubjectDraft(subject);
                              handleSubjectValidation(subject);
                              setIsEditSubject(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            data-testid="email-campaign-save-subject-button"
                            isLoading={loading}
                            onClick={handleSaveSubject}
                          >
                            Save
                          </Button>
                        </Flex>
                      </Box>
                    ) : (
                      <>
                        <Text as="p" fontWeight="normal" color={campaign?.subject ? 'text.default' : 'text.subtle'}>
                          {subject || "What's the subject line for this email?"}
                        </Text>
                        {!subjectValidationObject.isValid && (
                          <Text as="p" mt="2" mb="2" color="text.danger">
                            Please add a subject.
                          </Text>
                        )}
                      </>
                    )}
                  </Box>
                </Flex>
                {!isEditSubject && campaign?.status === EmailCampaignStatus.Draft && (
                  <Button
                    data-testid="email-campaign-edit-subject-button"
                    margin="3"
                    onClick={(): void => {
                      setIsEditSubject(true);
                    }}
                    variant="secondary"
                  >
                    {subject ? 'Edit' : 'Add'} Subject
                  </Button>
                )}
              </Flex>
              <Recipients
                border={border}
                borderColor={borderColor}
                {...recipients}
                loadingAdditionalSegmentProps={loadingAdditionalSegmentProps}
                additionalSegmentProps={additionalSegmentProps}
              />
              <CampaignSummaryElement
                border={border}
                borderColor={borderColor}
                state={CampaignSummaryElementState.Valid}
                title="From"
              >
                <Text as="p">{from}</Text>
              </CampaignSummaryElement>
              <ReplyTo campaign={campaign} refetchEmailCampaigns={refetchEmailCampaigns} />
            </Box>
          </Box>
          <Box>
            <Card
              borderColor={templateValidationObject.isValid ? 'border.default' : 'border.danger'}
              borderWidth={templateValidationObject.isValid ? '1px' : '2px'}
            >
              {campaign?.htmlContent ? (
                <TemplatePreview html={campaign.htmlContent} />
              ) : (
                <EmptyEmailPreview width="285px" height="341px" />
              )}
              {campaign?.htmlContent ? (
                <Flex flexDirection="column" width="100%">
                  {campaign?.status === EmailCampaignStatus.Draft && (
                    <Button
                      mt="5"
                      onClick={(): void => history.push(CampaignAction.editCampaignHTML)}
                      variant="secondary"
                    >
                      Edit Content
                    </Button>
                  )}
                  <EmailModalPreview html={campaign?.htmlContent || ''} />
                </Flex>
              ) : (
                <Button
                  isFullWidth
                  onClick={(): void => history.push(CampaignAction.chooseTemplate)}
                  variant="secondary"
                >
                  Choose a Template
                </Button>
              )}

              {!templateValidationObject.isValid && (
                <Flex width="100%" mt="4">
                  <Text color="text.danger">Please choose a template.</Text>{' '}
                </Flex>
              )}
            </Card>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CampaignSummary;
