import React, { useEffect, useMemo, useState } from 'react';
import { GraphQLFetchPolicies } from 'shared/enums';
import useClinicUser from 'shared/hooks/useClinicUser';
import {
  EmailCampaign,
  EmailCampaignSegmentationType,
  useGetEmailCampaignSegmentCountLazyQuery,
  useGetEmailCampaignSegmentCountQuery,
  useUpdateOneEmailCampaignMutation,
} from 'shared/types/graphql';
import { EmailCampaignSegmentDefinition } from '../ConditionalSegments/types/conditionalSegments.types';
import { MAX_RECIPIENTS } from '../ConditionalSegments/constants/conditionalSegments.constants';
import useGA from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';

export type useRecipientsReturnType = {
  campaign?: EmailCampaign;
  loadingCount: boolean;
  allActiveSegmentationCount: number | null | undefined;
  customSegmentationCount: number | null | undefined;
  recipientCount: number | null | undefined;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  recipientsValidationObject: {
    isValid: boolean;
    errorMessage: string;
  };
  handleRecipientsValidation: () => void;
  saveRecipientSegmentation: (
    segmentationType: EmailCampaignSegmentationType,
    clinicSegments?: EmailCampaignSegmentDefinition,
  ) => Promise<void>;
  hasCustomSegmentation: boolean;
  isSaving: boolean;
  onCancel: () => Promise<void>;
};

type useRecipientsArgs = {
  campaign?: EmailCampaign;
};

export const useRecipients = ({ campaign }: useRecipientsArgs): useRecipientsReturnType => {
  // Hooks
  const { currentClinicId: clinicId = '' } = useClinicUser();
  const { gaTrack } = useGA();
  const { data: allActiveData, loading: isAllActiveSegmentationCountLoading } = useGetEmailCampaignSegmentCountQuery({
    variables: {
      clinicId,
    },
  });
  const [getCustomSegmentationCount, { data: customData, loading: isCustomSegmentationCountLoading }] =
    useGetEmailCampaignSegmentCountLazyQuery();
  const [updateOneEmailCampaign, { loading: isSaving }] = useUpdateOneEmailCampaignMutation();

  // UI flags and validations
  const [isEditing, setIsEditing] = useState(false);
  const [recipientsValidationObject, setRecipientsValidationObject] = useState({
    isValid: true,
    errorMessage: '',
  });

  const handleRecipientsValidation = (): void => {
    setRecipientsValidationObject({
      isValid: !!recipientCount && recipientCount <= MAX_RECIPIENTS,
      errorMessage: !recipientCount
        ? 'Please add at least one recipient.'
        : 'Results exceed 15k contact limit. Please change your filters and try again.',
    });
  };

  const hasCustomSegmentation = useMemo(
    () => campaign?.segmentationType !== EmailCampaignSegmentationType.AllActiveClients,
    [campaign?.segmentationType],
  );

  // Final count based on the campaign segment definition
  const recipientCount = useMemo(() => {
    if (hasCustomSegmentation) {
      return customData?.getEmailCampaignSegmentCount;
    }
    return allActiveData?.getEmailCampaignSegmentCount;
  }, [allActiveData?.getEmailCampaignSegmentCount, customData?.getEmailCampaignSegmentCount, hasCustomSegmentation]);

  // Call the lazy query once the campaign is loaded
  useEffect(() => {
    if (campaign?.segmentationType !== EmailCampaignSegmentationType.AllActiveClients) {
      getCustomSegmentationCount({
        variables: {
          clinicId: clinicId || '',
          emailCampaignId: campaign?.id,
        },
        fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
      });
    }
  }, [campaign?.id, campaign?.segmentationType, clinicId, getCustomSegmentationCount, gaTrack]);

  useEffect(() => {
    if (
      campaign?.segmentationType !== EmailCampaignSegmentationType.AllActiveClients &&
      recipientCount &&
      recipientCount > MAX_RECIPIENTS
    ) {
      gaTrack(GA4Events.EMAIL_CAMPAIGNS_RECIPIENT_LIMIT_REACHED, {
        emailCampaignId: campaign?.id,
      });
    }
  }, [campaign?.id, campaign?.segmentationType, gaTrack, recipientCount]);

  const saveRecipientSegmentation = async (
    segmentationType: EmailCampaignSegmentationType,
    clinicSegments: EmailCampaignSegmentDefinition = {},
  ): Promise<void> => {
    await updateOneEmailCampaign({
      variables: {
        where: { id: campaign?.id },
        data: {
          typedSegmentArgs: clinicSegments,
          segmentationType,
        },
      },
    });

    getCustomSegmentationCount({
      variables: {
        clinicId: clinicId || '',
        emailCampaignId: campaign?.id,
      },
      fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
    });
  };

  const onCancel = async (): Promise<void> => {
    // If the user was using a custom list, and then deleted it, and tries to cancel, we should default back to All Active Clients
    if (campaign?.segmentationType === EmailCampaignSegmentationType.CustomList && !campaign?.customList) {
      await saveRecipientSegmentation(EmailCampaignSegmentationType.AllActiveClients);
    }
    setIsEditing(false);
  };

  return {
    campaign,
    loadingCount: isAllActiveSegmentationCountLoading || isCustomSegmentationCountLoading,
    allActiveSegmentationCount: allActiveData?.getEmailCampaignSegmentCount,
    customSegmentationCount: customData?.getEmailCampaignSegmentCount,
    recipientCount,
    hasCustomSegmentation,
    isSaving,
    isEditing,
    setIsEditing,
    recipientsValidationObject,
    handleRecipientsValidation,
    saveRecipientSegmentation,
    onCancel,
  };
};

export default useRecipients;
