import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { RouteDefinitions } from 'routes';
import JumpToTodayButton from 'pages/Appointments/components/Header/JumpToTodayButton';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Switch } from '@televet/kibble-ui/build/components/Switch';
import { HStack, Flex, Center } from '@televet/kibble-ui/build/chakra';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { toggleWallpaperMode } from 'state/currentClinic/currentClinicSlice';
import useClinicUser from 'shared/hooks/useClinicUser';
import { useAuth } from 'shared/providers/AuthProvider';
import { Icon, Tooltip } from '@televet/kibble-ui';
import wallpaperUrl from 'assets/images/wallpaper.jpeg';

enum PageName {
  appointments = 'appointments',
  board = 'board',
  conversations = 'conversations',
  team = 'team',
  loyaltyProgram = 'loyalty-program',
  emails = 'emails',
  emailTemplates = 'email-templates',
  survey = 'survey',
  reporting = 'reporting',
  automations = 'automations',
  templates = 'templates',
  forms = 'forms',
  settings = 'settings',
  channelAttachments = 'channelattachments',
  successCenter = 'success-center',
  ottoEmailCampaigns = 'email-campaigns',
  globalAutomations = 'global-automations',
}

const TopToolbar = ({ clinicId }: { clinicId: string | undefined }): JSX.Element => {
  const { isMobile } = useResolutionProvider();
  const location = useLocation();
  const pageName = location.pathname.split('/');

  const wallpaperMode = useAppSelector((state) => state.currentClinic.wallpaperMode);
  const dispatch = useAppDispatch();

  const createPageName = useMemo((): string | undefined => {
    switch (pageName[1]) {
      case PageName.appointments:
        return 'Appointments';
      case PageName.board:
        return 'Board';
      case PageName.conversations:
        return 'Conversations';
      case PageName.team:
        return 'Team Chat';
      case PageName.loyaltyProgram:
        return 'Loyalty Program';
      case PageName.emails:
        return 'Email Campaigns';
      case PageName.survey:
        return 'Survey';
      case PageName.reporting:
        return 'Reporting';
      case PageName.automations:
        return 'Automations';
      case PageName.templates:
        return 'Message Templates';
      case PageName.forms:
        return 'Forms';
      case PageName.settings:
      case PageName.emailTemplates:
        return 'Settings';
      case PageName.channelAttachments:
        return 'Conversation Attachment';
      case PageName.successCenter:
        return 'Success Hub';
      case PageName.ottoEmailCampaigns:
        return 'Email Campaigns';
      case PageName.globalAutomations:
        return 'Global Automations';
    }
  }, [pageName]);

  const isAprilFoolsDay = useMemo(() => {
    const date = new Date();
    return date.getMonth() === 3 && date.getDate() === 1;
  }, []);

  const { isImpersonating } = useAuth();
  const { isOttoTeamMember } = useClinicUser();

  useEffect(() => {
    if (!isAprilFoolsDay && wallpaperMode) {
      dispatch(toggleWallpaperMode());
    }
  }, [isAprilFoolsDay, wallpaperMode, dispatch]);

  if (RouteDefinitions.Board.includes(location.pathname)) {
    return <></>;
  }

  return (
    <HStack
      p={5}
      h={14}
      backgroundColor="background.default"
      display={!isMobile ? 'flex' : 'none'}
      borderBottom="1px"
      borderBottomColor="border.default"
      justify="space-between"
      w="100%"
      {...(wallpaperMode
        ? {
            backgroundImage: `url(${wallpaperUrl})`,
            backgroundRepeat: 'repeat',
            backgroundSize: '14%',
          }
        : {})}
    >
      <HStack gap={2}>
        <Heading
          size="md"
          {...(wallpaperMode
            ? {
                bg: 'white',
                py: 2,
                px: 3,
                border: '1px solid',
                borderColor: 'border.default',
                borderRadius: 'xl',
              }
            : {})}
        >
          {createPageName || 'No page name'}
        </Heading>
        {isAprilFoolsDay && (isOttoTeamMember || isImpersonating) && (
          <Flex align="center" p={2} bg="white" border="1px solid" borderColor="border.default" borderRadius="xl">
            <Switch
              label="Wallpaper Mode"
              formControlStyle={{ display: 'flex' }}
              labelStyle={{ flex: '0 0 auto', margin: '0 0 0 8px', cursor: 'pointer' }}
              labelPosition="end"
              isChecked={wallpaperMode}
              onChange={(): void => {
                dispatch(toggleWallpaperMode());
              }}
            />
            <Tooltip label="You voted and we delivered! (This is only visible to Otto team members.)">
              <Center ml={2}>
                <Icon name="infoCircle" size="sm" />
              </Center>
            </Tooltip>
          </Flex>
        )}
        {RouteDefinitions.Appointments.includes(location.pathname) && clinicId && <JumpToTodayButton />}
      </HStack>
    </HStack>
  );
};

export default TopToolbar;
