import React, { useMemo, MutableRefObject, RefCallback, useEffect, useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { format, isToday, parseISO, startOfDay } from 'date-fns';
import sortedUniqBy from 'lodash-es/sortedUniqBy';
import MessageGroup from '../../ChannelMessage/MessageGroup';
import { IMap } from 'shared/interfaces/IMap';
import {
  ChannelMessageAttachmentType,
  ChannelViewChannelFragment,
  ChannelViewChannelMessageFragment,
  GetClinicChannelViewChannelQuery,
  SmsNotificationStatusFragment,
} from 'shared/types/graphql';
import { useSidePanel } from 'shared/components/SidePanel/state/providers/SidePanelProvider';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import { GET_CLINIC_WORKFLOWS_EVENT_SETTINGS } from 'shared/queries/workflows';
import { useQuery } from '@apollo/client';
import useClinicUser from 'shared/hooks/useClinicUser';
import { usePopover } from 'shared/providers/PopoverWindowProvider';
import { IPopover } from 'shared/providers/PopoverWindowProvider/interfaces/IPopover';
import { INotificationErrorTextProps } from '..';
import { GraphQLFetchPolicies } from 'shared/enums';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { TwilioIdentity } from '@televet/shared-types/twilio';
import useLastMessage from 'pages/Conversations/hooks/useLastMessage';

interface IMessageTimelineProps {
  messages: ChannelViewChannelMessageFragment[] | undefined;
  channelId: string | undefined;
  hasPreviousPage: boolean;
  channelData?: ChannelViewChannelFragment | null;
  isTeamChannel: boolean;
  messageTimelineScrollContainerRef: MutableRefObject<HTMLDivElement | null>;
  currentUserId?: string;
  previousMessagesRef: RefCallback<HTMLDivElement | null>;
  onLoadPreviousMessages: () => void;
  onFirstMessageMount: () => void;
  onLastMessageMount: (message: ChannelViewChannelMessageFragment, element: HTMLDivElement) => void;
  setPreviewData: (fileUrl: string, attachmentType: ChannelMessageAttachmentType, fileName: string) => void;
  setCurrentInvoiceId: (invoiceId: string) => void;
  setInvoiceClinicPetParentId: (invoiceClinicPetParentId: string) => void;
  phoneWarnings: INotificationErrorTextProps[];
  emailWarnings: INotificationErrorTextProps[];
  lastConsumedMessageIndex: number | undefined | null;
  channelMemberId: string | undefined;
  newMessageIndicatorRef: MutableRefObject<HTMLDivElement | null>;
  clinicPetParents: NonNullable<
    GetClinicChannelViewChannelQuery['findUniqueChannel']
  >['channelMembers'][0]['clinicPetParent'][];
}

const MessageTimeline = ({
  messages,
  channelId,
  hasPreviousPage,
  channelData,
  isTeamChannel,
  messageTimelineScrollContainerRef,
  currentUserId,
  previousMessagesRef,
  onLoadPreviousMessages,
  onFirstMessageMount,
  onLastMessageMount,
  setPreviewData,
  setCurrentInvoiceId,
  setInvoiceClinicPetParentId,
  phoneWarnings,
  emailWarnings,
  lastConsumedMessageIndex,
  channelMemberId,
  newMessageIndicatorRef,
  clinicPetParents,
}: IMessageTimelineProps): JSX.Element | null => {
  // Group messages by day and ensure proper sort
  const [smsStstusesByMessage, setSmsStstusesByMessage] = useState<Record<string, SmsNotificationStatusFragment>>({});
  const messagesByDay = useMemo(() => {
    if (!messages?.length || !channelId) return {};

    const channelMessages = messages.filter(
      (message: ChannelViewChannelMessageFragment) => message?.channel?.id === channelId,
    );

    const sortedUniqueMessages = sortedUniqBy(
      channelMessages,
      (message: ChannelViewChannelMessageFragment) => message.id,
    );

    const result = sortedUniqueMessages.reduce(
      (
        result: IMap<ChannelViewChannelMessageFragment[]>,
        currentMessage: ChannelViewChannelMessageFragment,
      ): IMap<ChannelViewChannelMessageFragment[]> => {
        const key = startOfDay(parseISO(currentMessage.createdAt)).toISOString();
        (result[key] = result[key] || []).push(currentMessage);
        return result;
      },
      {} as IMap<ChannelViewChannelMessageFragment[]>,
    );

    const ordered = {} as IMap<ChannelViewChannelMessageFragment[]>;
    Object.keys(result)
      .sort((a: string, b: string) => {
        return parseISO(a).getTime() - parseISO(b).getTime();
      })
      .forEach((key) => {
        ordered[key] = result[key];
      });

    return ordered;
  }, [messages, channelId]);

  // In mobile once the side panel is open and hitting back
  // on the browser it returns to the conversation but we
  // need to close the side panel.

  const { setIsOpen, isOpen } = useSidePanel();
  const { isMobile } = useResolutionProvider();
  useEffect(() => {
    if (isMobile && isOpen) {
      setIsOpen(false);
    }
  }, [isMobile, isOpen, setIsOpen]);

  const { currentClinicId } = useClinicUser();
  const { data: workflowsData } = useQuery(GET_CLINIC_WORKFLOWS_EVENT_SETTINGS, {
    variables: {
      where: {
        clinic: {
          id: {
            equals: currentClinicId,
          },
        },
      },
    },
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    skip: !currentClinicId,
  });

  const workflowEventSettingsById = useMemo(() => {
    const obj = {} as IMap<string>;
    if (workflowsData?.findManyWorkflowEventSetting) {
      for (const workflowEventSetting of workflowsData.findManyWorkflowEventSetting) {
        obj[workflowEventSetting.id] = workflowEventSetting.name;
      }
    }
    return obj;
  }, [workflowsData]);

  const { popover } = usePopover();

  const { lastVisibleMessageIndex, lastVisibleMessageReadByMember } = useLastMessage({
    channelData,
    currentUserId,
    messages,
  });

  const handleSmsStatusChange = useCallback((status: SmsNotificationStatusFragment, messageId: string) => {
    setSmsStstusesByMessage((value) => ({
      ...value,
      [messageId]: status
    }))
  }, []);

  return (
    <MessageTimelineContainer data-testid="message-timeline" isTeamChannel={isTeamChannel} popover={popover}>
      <MessageTimelineScrollContainer ref={messageTimelineScrollContainerRef}>
        <>
          {hasPreviousPage && (
            <PreviousMessagesLoadingContainer ref={previousMessagesRef} onClick={onLoadPreviousMessages}>
              <Spinner />
            </PreviousMessagesLoadingContainer>
          )}
          {Object.keys(messagesByDay).map((key, dayIndex, keys) => {
            return (
              <MessageGroupContainer key={key}>
                <DaySeparatorContainer>
                  <Line />
                  <DayLabel>{isToday(parseISO(key)) ? 'Today' : format(parseISO(key), 'EEEE, MMMM do')}</DayLabel>
                  <Line />
                </DaySeparatorContainer>
                {messagesByDay[key].map((message: ChannelViewChannelMessageFragment, messageIndex, dayMessages) => {
                  const isFirstMessage = dayIndex === 0 && messageIndex === 0;
                  const isLastMessage = dayIndex === keys.length - 1 && messageIndex === dayMessages.length - 1;
                  const isOneBeforeLastMessage = messageIndex === dayMessages.length - 2;
                  const isLastMessageSystemMessage =
                    dayMessages[dayMessages.length - 1].author?.twilioIdentity === TwilioIdentity.System;
                  const isFirstUnreadMessage =
                    lastConsumedMessageIndex != null ? lastConsumedMessageIndex + 1 === message.index : false;
                  const isLastVisibleMessage = lastVisibleMessageIndex === message.index;
                  if (!message.body && !message.attachments?.length && !message.deletedAt) return null;
                  return (
                    <React.Fragment key={message.id}>
                      {isFirstUnreadMessage && <NewMessagesIndicator ref={newMessageIndicatorRef} />}
                      <MessageGroup
                        key={message.id}
                        message={message}
                        isFirstMessage={isFirstMessage}
                        isLastMessage={isLastMessage}
                        isOneBeforeLastMessage={isOneBeforeLastMessage}
                        isLastMessageSystemMessage={isLastMessageSystemMessage}
                        channelData={channelData}
                        setPreviewData={setPreviewData}
                        onFirstMessageMount={onFirstMessageMount}
                        onLastMessageMount={onLastMessageMount}
                        workflowEventSettingsById={workflowEventSettingsById}
                        setCurrentInvoiceId={setCurrentInvoiceId}
                        setInvoiceClinicPetParentId={setInvoiceClinicPetParentId}
                        phoneWarnings={phoneWarnings}
                        emailWarnings={emailWarnings}
                        channelMemberId={channelMemberId}
                        clinicPetParents={clinicPetParents}
                        seenByMembers={lastVisibleMessageReadByMember[message.index]}
                        isLastVisibleMessage={isLastVisibleMessage}
                        isTeamChannel={isTeamChannel}
                        currentUserId={currentUserId}
                        onSmsStatusChange={handleSmsStatusChange}
                        smsNotificationStatus={smsStstusesByMessage[message.id]}
                      />
                    </React.Fragment>
                  );
                })}
              </MessageGroupContainer>
            );
          })}
        </>
      </MessageTimelineScrollContainer>
    </MessageTimelineContainer>
  );
};

const MessageTimelineContainer = styled.div<{ isTeamChannel: boolean; popover: IPopover }>`
  position: relative;
  flex: 1 1 auto;
  overflow-x: hidden;
`;

const MessageTimelineScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const PreviousMessagesLoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  & > div {
    padding: 0;
  }
`;

const MessageGroupContainer = styled.div`
  width: 100%;
`;

const DaySeparatorContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 10px 0 10px 0;
  text-align: center;
  width: 100%;
`;

const Line = styled.div`
  border-top: 1px solid #ebedf0;
  flex: 1;
  height: 1px;
`;

const DayLabel = styled.span`
  background-color: var(--chakra-colors-background-default);
  border-radius: 14.5px;
  border: none;
  border: solid 1px #ebedf0;
  color: var(--chakra-colors-text-subtle);
  font-size: 12px;
  height: 25px;
  padding: 4px 1em 0 1em;
`;

const NewMessagesIndicator = styled.div`
  width: 100%;
  position: relative;
  margin-right: 100px;
  height: 0;
  border-bottom: 1px solid red;
  color: red;
  scroll-margin-top: 40px;
  z-index: 1;
  &::after {
    content: 'NEW';
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    right: 10px;
    background-color: white;
    top: -10px;
    padding: 0 6px;
    border-radius: 6px;
    border: 1px solid red;
    text-align: center;
  }
`;

export default MessageTimeline;
