import { IconName } from '@televet/kibble-ui';
import { TypographyVariant } from '@televet/kibble-ui/build/shared/types';
import { NextStepSelectValue } from '../types/NextStepSelectValue';

export type StepValueDisplayProps = {
  value: NextStepSelectValue | string;
  label: string;
  isPlaceholder?: boolean;
  iconName?: IconName;
  variant?: TypographyVariant;
  isConditionalChild?: boolean;
};

export const getStepValueDisplayProps = (nextStepSelectValue: NextStepSelectValue | string): StepValueDisplayProps => {
  const propsMap: Record<NextStepSelectValue, StepValueDisplayProps> = {
    [NextStepSelectValue.EndAutomation]: {
      value: NextStepSelectValue.EndAutomation,
      label: 'End Automation',
      iconName: 'close',
      variant: 'danger',
    },
    [NextStepSelectValue.SendAMessage]: {
      value: NextStepSelectValue.SendAMessage,
      label: 'Send a Message',
      isPlaceholder: true,
    },
    [NextStepSelectValue.NextPrompt]: {
      value: NextStepSelectValue.NextPrompt,
      label: 'Go to Next Prompt',
      iconName: 'arrowDownRight',
    },
    [NextStepSelectValue.NewAutomation]: {
      value: NextStepSelectValue.NewAutomation,
      label: 'Add New Automation',
      iconName: 'plus',
      variant: 'info',
    },
    [NextStepSelectValue.OpenLink]: {
      value: NextStepSelectValue.OpenLink,
      label: 'Open External Link',
      iconName: 'externalLink',
      variant: 'info',
    },
  };

  const propsMatch = propsMap[nextStepSelectValue as NextStepSelectValue];

  if (!propsMatch) {
    return {
      value: nextStepSelectValue,
      label: nextStepSelectValue,
      iconName: 'chainLink',
    };
  }

  return propsMatch;
};
