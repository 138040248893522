import React, { useState, ChangeEvent, useCallback, useMemo, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import partial from 'lodash-es/partial';
import pick from 'lodash-es/pick';
import { PencilIcon, TrashCanIcon } from 'assets/icons';
import {
  ClinicPetParentUpdateInput,
  ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput,
  SidePanelProfileClinicPetParentFragment,
  SidePanelPetParentProfilePhoneNumberFragment,
  useUpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation,
  useJobChainCompletedSubscription,
  GetSidePanelPetParentProfileQuery,
  Exact,
} from 'shared/types/graphql';
import { cleanPhoneNumber, formatPhoneNumber } from 'shared/utils';
import { Mixpanel } from 'shared/utils/mixpanel';
import { useValidation, ValidationResponseCodes } from '../../hooks/useValidation';
import { ApolloQueryResult } from '@apollo/client';

interface IClientPhoneNumberPopoverProps {
  clinicPetParent: SidePanelProfileClinicPetParentFragment;
  goToPetParent: (id: string) => void;
  setIsPrimaryPhoneNumberUpdated: React.Dispatch<boolean>;
  setJobChainIdFromResponse: React.Dispatch<string>;
  refetchGetSidePanelPetParentProfile: (
    variables?:
      | Partial<
          Exact<{
            clinicPetParentId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetSidePanelPetParentProfileQuery>>;
}

const ClientPhoneNumberPopover = ({
  clinicPetParent,
  goToPetParent,
  setIsPrimaryPhoneNumberUpdated,
  setJobChainIdFromResponse,
  refetchGetSidePanelPetParentProfile,
}: IClientPhoneNumberPopoverProps): JSX.Element => {
  const { validatePhoneNumber } = useValidation();
  const [updateClinicPetParent] = useUpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation();
  const [jobChainId, setJobChainId] = useState('');
  const { data: useJobChainCompletedData } = useJobChainCompletedSubscription({
    skip: !jobChainId,
    variables: { jobChainId: jobChainId || '' },
  });

  const [draftPhoneNumber, setDraftPhoneNumber] = useState<SidePanelPetParentProfilePhoneNumberFragment | null>(null);
  const [phoneValidationMessage, setPhoneValidationMessage] = useState('');
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const activePhoneNumbers = useMemo(() => {
    return clinicPetParent.phoneNumbers?.filter((n) => !n.isDeleted) || [];
  }, [clinicPetParent]);

  const onDraftPhoneNumberChange = (updates: Partial<SidePanelPetParentProfilePhoneNumberFragment>): void => {
    setDraftPhoneNumber(Object.assign({}, draftPhoneNumber, updates));
  };

  const onDraftPhoneNumberBlur = (): void => {
    if (!draftPhoneNumber) return;
    const formattedNumber = formatPhoneNumber(cleanPhoneNumber(draftPhoneNumber.number as string));
    setDraftPhoneNumber(Object.assign({}, draftPhoneNumber, { number: formattedNumber }));
  };

  const onDraftPhoneNumberDelete = (): void => {
    setIsConfirmingDelete(true);
  };

  const phoneNumberInputRef = useRef<HTMLInputElement | null>(null);
  const phoneNumberInputRefCallback = useCallback((element: HTMLInputElement): void => {
    phoneNumberInputRef.current = element;
    setPhoneValidationMessage('');
    element?.focus();
  }, []);

  const onDraftPhoneNumberSave = async (): Promise<void> => {
    if (!draftPhoneNumber) return;

    const { isValid, code } = await validatePhoneNumber(draftPhoneNumber.number);

    if (!isValid) {
      if (code === ValidationResponseCodes.PhoneNumberInUse) {
        setPhoneValidationMessage('Phone number is already in use.');
        Mixpanel.track('Side panel client profile reserved phone number entered');
      } else {
        setPhoneValidationMessage('Please enter a valid phone number');
        Mixpanel.track('Side panel client profile invalid phone number entered');
      }

      phoneNumberInputRef?.current?.focus();
      return;
    }

    if (
      !isConfirmingDelete &&
      !!activePhoneNumbers.find(
        (n) =>
          n.id !== draftPhoneNumber.id &&
          cleanPhoneNumber(n.number) === cleanPhoneNumber(draftPhoneNumber.number || ''),
      )
    ) {
      setPhoneValidationMessage(
        "That number is already attached to this client's profile. Please enter another number.",
      );
      Mixpanel.track('Side panel client profile duplicate phone number entered');
      phoneNumberInputRef?.current?.focus();
      return;
    }

    const data: ClinicPetParentUpdateInput = {};

    let phoneNumbers: ClinicPetParentUpdateInput['phoneNumbers'];
    const phoneNumberData = pick(draftPhoneNumber, ['number', 'isDeleted', 'isPrimary', 'isMobile']);

    if (isConfirmingDelete) {
      phoneNumberData.isDeleted = true;
      phoneNumberData.isPrimary = false;
    }
    // TODO: Remove this once we set up the backend to reformat phone numbers automatically
    if (phoneNumberData.number) phoneNumberData.number = cleanPhoneNumber(phoneNumberData.number);

    // Only true if number itself has changed or a new number is added
    const hasNumberChanged =
      !phoneNumberData.isDeleted &&
      activePhoneNumbers.find((number) => number.id === draftPhoneNumber.id)?.number !== phoneNumberData.number;

    // we append lastOptOutSendDate only if number itself has changed
    if (draftPhoneNumber.id) {
      phoneNumbers = {
        update: [
          {
            where: { id: draftPhoneNumber.id },
            data: {
              ...phoneNumberData,
              ...(hasNumberChanged && { lastOptOutSendDate: null }),
            },
          },
        ],
      };
    } else {
      phoneNumbers = {
        create: [phoneNumberData as ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput],
        update: [],
      };
    }

    // Ensure there is only one primary number if numbers exist
    const existingOtherPrimaryNumbers = activePhoneNumbers.filter((n) => !!n.isPrimary && n.id !== draftPhoneNumber.id);

    if (phoneNumberData.isPrimary) {
      phoneNumbers.update?.push(
        ...existingOtherPrimaryNumbers.map((n) => ({ where: { id: n.id }, data: { isPrimary: false } })),
      );
    }

    data.phoneNumbers = phoneNumbers;

    const petParentPhNumberUpdateResponse = await updateClinicPetParent({
      variables: {
        where: { id: clinicPetParent.id },
        data,
      },
    });

    const jobChainIdfromUpdate =
      petParentPhNumberUpdateResponse?.data?.updateOneClinicPetParentAndEnqueuePhoneNumberLookup?.jobChains?.[0]?.id ||
      '';
    setJobChainIdFromResponse(jobChainIdfromUpdate);

    setJobChainId(jobChainIdfromUpdate);
    Mixpanel.track('Side panel client phone number added');
    goToPetParent(clinicPetParent.id);
    setDraftPhoneNumber(null);
    setIsConfirmingDelete(false);
  };

  const onDraftPhoneNumberCancel = (): void => {
    setDraftPhoneNumber(null);
    setIsConfirmingDelete(false);
  };

  const onEditNumberClick = (clinicEntityPhoneNumber: SidePanelPetParentProfilePhoneNumberFragment): void => {
    setDraftPhoneNumber(clinicEntityPhoneNumber);
  };

  const onAddNumberClick = (): void => {
    setDraftPhoneNumber({
      id: '',
      updatedAt: new Date(),
      number: '',
      isMobile: true,
      isPrimary: !activePhoneNumbers.length,
    });
  };

  useEffect(() => {
    if (useJobChainCompletedData && jobChainId === useJobChainCompletedData?.jobChainCompleted?.jobChainId) {
      const isPrimaryNumberChanged = useJobChainCompletedData?.jobChainCompleted?.payload?.[0].isPrimaryChanged;
      setIsPrimaryPhoneNumberUpdated(isPrimaryNumberChanged);
      if (isPrimaryNumberChanged) {
        refetchGetSidePanelPetParentProfile();
      }
    }
  }, [jobChainId, refetchGetSidePanelPetParentProfile, setIsPrimaryPhoneNumberUpdated, useJobChainCompletedData]);
  const canChangePrimary = useMemo(() => {
    return (draftPhoneNumber: typeof activePhoneNumbers[0]): boolean =>
      // If there is only one number, it must be primary and when adding a new number, it can not be set intitially as primary
      activePhoneNumbers.length === 0 || !!draftPhoneNumber?.id;
  }, [activePhoneNumbers]);

  return (
    <Container data-testid="client-phone-number-container">
      <Header>Client phone numbers</Header>
      <NumberList>
        {activePhoneNumbers.map((phoneNumber) => (
          <NumberListItem
            data-testid={`phone-number-${phoneNumber.number}`}
            key={`phone-${phoneNumber.id}`}
            isHighlighted={draftPhoneNumber?.id === phoneNumber.id}
            onClick={partial(onEditNumberClick, phoneNumber)}
          >
            <Number>
              <div>{formatPhoneNumber(cleanPhoneNumber(phoneNumber.number))}</div>
              {phoneNumber.isPrimary && <PrimaryBadge data-testid="primary-phone-badge">Primary</PrimaryBadge>}
            </Number>

            <StyledPencilIcon />
          </NumberListItem>
        ))}
      </NumberList>
      <HR />
      <Footer showBackground={!!draftPhoneNumber}>
        {draftPhoneNumber ? (
          <DraftEditContainer>
            {isConfirmingDelete ? (
              <DeleteConfirmationContainer>
                <div>This number will be deleted.</div>
                <div>Are you sure?</div>
              </DeleteConfirmationContainer>
            ) : (
              <>
                <DraftEditHeader>{draftPhoneNumber.id ? 'Edit phone number' : 'Add phone number'}</DraftEditHeader>
                <PhoneNumberInput
                  data-testid="phone-number-input"
                  type="text"
                  key={draftPhoneNumber.id}
                  ref={phoneNumberInputRefCallback}
                  autoComplete="off"
                  value={draftPhoneNumber.number}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                    onDraftPhoneNumberChange({ number: e.target.value })
                  }
                  onKeyDown={(e): void => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      onDraftPhoneNumberSave();
                    } else if (e.key === 'Escape') {
                      onDraftPhoneNumberCancel();
                    }
                  }}
                  onBlur={onDraftPhoneNumberBlur}
                />
                {phoneValidationMessage && <ValidationMessage>{phoneValidationMessage}</ValidationMessage>}

                <CheckboxesContainer>
                  <CheckboxLabel>
                    <input
                      data-testid="primary-phone-checkbox"
                      type="checkbox"
                      checked={!!draftPhoneNumber.isPrimary}
                      disabled={!canChangePrimary(draftPhoneNumber)}
                      onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                        onDraftPhoneNumberChange({ isPrimary: e.target.checked })
                      }
                    />
                    Primary
                  </CheckboxLabel>
                  <CheckboxLabel>
                    <input
                      data-testid="mobile-phone-checkbox"
                      type="checkbox"
                      checked={!!draftPhoneNumber.isMobile}
                      onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                        onDraftPhoneNumberChange({ isMobile: e.target.checked })
                      }
                    />
                    Mobile
                  </CheckboxLabel>
                </CheckboxesContainer>
                {draftPhoneNumber.id && (
                  <DeleteContainer>
                    <StyledTrashCanIcon />
                    <div onClick={onDraftPhoneNumberDelete}>Delete this number</div>
                  </DeleteContainer>
                )}
              </>
            )}

            <DraftButtonsContainer>
              <DraftCancelButton data-testid="phone-number-cancel-button" onClick={onDraftPhoneNumberCancel}>
                Cancel
              </DraftCancelButton>
              <DraftSaveButton data-testid="phone-number-save-button" onClick={onDraftPhoneNumberSave}>
                {isConfirmingDelete ? 'Yes, Delete' : 'Save'}
              </DraftSaveButton>
            </DraftButtonsContainer>
          </DraftEditContainer>
        ) : (
          <ActionButton data-testid="add-phone-number-button" onClick={onAddNumberClick}>
            Add a number
          </ActionButton>
        )}
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #757c8d;
  max-height: 300px;
  overflow-y: auto;
`;

const HR = styled.hr`
  border: none;
  border-top: 1px solid #ddd;
  margin-top: 4px;
`;

const Header = styled.div`
  font-weight: 600;
  padding: 8px 14px;
  color: #6b7081;
`;

interface IListItemProps {
  isHighlighted?: boolean;
}

const ListItem = styled.div<IListItemProps>`
  cursor: pointer;
  padding: 8px 14px;
  background: ${({ isHighlighted }: IListItemProps): string => (isHighlighted ? '#e6f4f6' : '#fff')};

  &:hover {
    background: #e6f4f6;
  }
`;

const NumberList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

const StyledPencilIcon = styled(PencilIcon)`
  opacity: 0;
  flex: 0 0 auto;
`;

const NumberListItem = styled(ListItem)`
  display: flex;
  align-items: center;

  &:hover {
    ${StyledPencilIcon} {
      opacity: 1;
    }
  }
`;

const Number = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
`;

const PrimaryBadge = styled.div`
  background: hsl(180deg 16% 95%);

  font-size: 12px;
  font-weight: 600;
  padding: 2px 4px;
  margin: 0 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 0 0 auto;
`;

interface IFooterProps {
  showBackground: boolean;
}

const Footer = styled.div<IFooterProps>`
  display: flex;
  flex-direction: column;
  padding: 4px 0;
  background: ${({ showBackground }: IFooterProps): string => (showBackground ? '#f8f8f8' : '#fff')};
`;

const DraftEditContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DraftEditHeader = styled.div`
  margin: 4px 14px 4px;
  color: #6b7081;
  font-weight: 600;
`;

const CheckboxesContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 14px 8px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:first-of-type {
    margin-right: 16px;
  }

  & input {
    margin-right: 6px;
    cursor: pointer;
  }
`;

const PhoneNumberInput = styled.input`
  font-size: 14px;
  color: #6b7081;
  padding: 6px 8px;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
  background-color: var(--chakra-colors-background-default);
  margin: 4px 6px 0;

  &:focus {
    border-color: #52b0c3;
  }
`;

const ValidationMessage = styled.div`
  margin: 8px 14px;
  line-height: 1.4;
  font-size: 12px;
  color: var(--chakra-colors-text-danger);
  max-width: 180px;
`;

const DeleteConfirmationContainer = styled.div`
  margin: 10px 20px;
  text-align: center;

  & *:first-child {
    font-weight: 600;
    margin-bottom: 4px;
  }
`;

const DeleteContainer = styled.div`
  margin: 6px 14px 10px;
  display: flex;
  align-items: center;

  & div {
    color: #52b0c3;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: hsl(190, 55%, 46%);
    }
  }
`;

const StyledTrashCanIcon = styled(TrashCanIcon)`
  margin-right: 8px;
`;

const DraftButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 6px 4px;
`;

const DraftButton = styled.button`
  border-radius: 8px;
  border: none;
  flex: 1 1 auto;
  width: 50%;
  padding: 6px 0;
  font-weight: 600;
  cursor: pointer;

  &:first-of-type {
    margin-right: 3px;
  }

  &:nth-of-type(n + 2) {
    margin-left: 3px;
  }
`;

const DraftCancelButton = styled(DraftButton)`
  background: transparent;
  border: 1px solid #ccc;
`;

const DraftSaveButton = styled(DraftButton)`
  background: #52b0c3;
  border: 1px solid #52b0c3;
  color: #fff;
`;

const ActionButton = styled(ListItem)``;

export default ClientPhoneNumberPopover;
