import { Box, Flex, HStack, VStack } from '@televet/kibble-ui/build/chakra';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Button } from '@televet/kibble-ui/build/components/Button';
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from 'shared/providers/PopoverWindowProvider/enums/ItemTypes';
import { NO_STATUS } from './AppointmentsColumn';
import {
  BoardFilterView,
  ChannelStatusAction,
  MessageType,
  UpdateUserBoardFilterSettingsMutationFn,
  useCreateSystemChannelMessageMutation,
  useUpdateChannelStatusMutation,
} from 'shared/types/graphql';
import { useAppSelector } from 'state/hooks';
import useClinicUser from 'shared/hooks/useClinicUser';
import { useToast } from '@televet/kibble-ui';

interface IConversationBoardColumnProps {
  columnName: string;
  columnColor: string;
  channelStatusId?: string;
  children: ReactNode;
  numberOfCards: number;
  borderColor?: string;
  hasNextPage?: boolean;
  currentPage: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  isEmpty: boolean;
  isCollapsed: boolean;
  fetchMoreColumns?: () => Promise<void>;
  canCollapse?: boolean;
  channelStatusAction?: ChannelStatusAction;
  updateBoardSettings: UpdateUserBoardFilterSettingsMutationFn;
}

const scrollPixelVariance = 30; // In certain situations it needs to be slightly higher than the client height plus the scroll top

const BoardColumn = ({
  columnName,
  columnColor,
  channelStatusId,
  children,
  numberOfCards,
  borderColor,
  hasNextPage,
  currentPage,
  setPageNumber,
  isLoading,
  fetchMoreColumns,
  isEmpty,
  isCollapsed = false,
  canCollapse = true,
  channelStatusAction,
  updateBoardSettings,
}: IConversationBoardColumnProps): JSX.Element => {
  const { boardSettings, currentClinicId, clinicUserId } = useAppSelector((state) => state.board);
  const toast = useToast();

  const { clinicUser } = useClinicUser();

  const isHidden = useMemo(() => {
    if (!channelStatusId || !currentClinicId) return false;
    return (isEmpty || numberOfCards === 0) && channelStatusId !== NO_STATUS && boardSettings?.shouldHideEmptyColumns;
  }, [channelStatusId, currentClinicId, isEmpty, numberOfCards, boardSettings?.shouldHideEmptyColumns]);

  const toggleColumnCollapse = useCallback(async () => {
    if (channelStatusId && currentClinicId) {
      if (channelStatusId === NO_STATUS) {
        try {
          await updateBoardSettings({
            variables: {
              where: {
                userId: clinicUserId || '',
                clinicId: currentClinicId || '',
              },
              data: {
                shouldCollapseNoStatusColumn: !isCollapsed,
              },
            },
          });
        } catch (e) {
          console.error(e);
          toast({ status: 'error', description: 'We are having trouble saving your changes.', title: 'Error' });
        }
      } else {
        let collapsedColumns = [];

        if (isCollapsed) {
          // If the column is collapsed, an entry for the clinic already exists in the map
          // Remove this column from clinics array of collapsed columns
          collapsedColumns = boardSettings?.collapsedColumnsMap[currentClinicId].filter(
            (id: string) => channelStatusId !== id,
          );
        } else {
          // Else the column is not collapsed
          const clinicHasPreviouslyStoredValue = Object.keys(boardSettings?.collapsedColumnsMap);

          // If an entry for the clinic exists in the map
          if (clinicHasPreviouslyStoredValue.includes(currentClinicId)) {
            // Add this column to the existing array of collapsed columns for this clinic
            collapsedColumns = [...boardSettings?.collapsedColumnsMap[currentClinicId], channelStatusId];
          } else {
            // Otherwise we will need to add a new entry to the map for this clinic
            collapsedColumns = [channelStatusId];
          }
        }

        try {
          await updateBoardSettings({
            variables: {
              where: {
                userId: clinicUserId || '',
                clinicId: currentClinicId || '',
              },
              data: {
                collapsedColumnsMap: {
                  ...boardSettings?.collapsedColumnsMap,
                  [currentClinicId]: collapsedColumns,
                },
              },
            },
          });
        } catch (e) {
          console.error(e);
          toast({ status: 'error', description: 'We are having trouble saving your changes.', title: 'Error' });
        }
      }
    }

    if (fetchMoreColumns && !isCollapsed) {
      // Give time for collapse transistion to complete
      setTimeout(() => {
        fetchMoreColumns();
      }, 200);
    }
  }, [
    boardSettings?.collapsedColumnsMap,
    channelStatusId,
    clinicUserId,
    currentClinicId,
    fetchMoreColumns,
    isCollapsed,
    toast,
    updateBoardSettings,
  ]);

  const [updateChannel] = useUpdateChannelStatusMutation();
  const [createSystemMessage] = useCreateSystemChannelMessageMutation();

  const [{ isOver }, drop] = useDrop({
    accept:
      boardSettings?.currentView === BoardFilterView.Conversations
        ? ItemTypes.ConversationBoardCard
        : ItemTypes.AppointmentBoardCard,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    drop(item: { type: ItemTypes; channelId: string }) {
      const { channelId } = item;
      updateChannel({
        variables: {
          where: { id: channelId },
          data: { channelStatus: { connect: { id: channelStatusId } } },
        },
        optimisticResponse: {
          updateOneChannel: {
            __typename: 'Channel',
            clinicId: currentClinicId,
            id: channelId,
            channelStatusId,
            channelStatus: {
              __typename: 'ChannelStatus',
              id: channelStatusId || '',
              name: columnName,
              color: columnColor,
              channelStatusAction: channelStatusAction || ChannelStatusAction.Active,
            },
          },
        },
      }).then(() => {
        // Add system message to track archived channels
        if (
          channelStatusAction === ChannelStatusAction.Inactive ||
          channelStatusAction === ChannelStatusAction.InactivePermanently
        ) {
          createSystemMessage({
            variables: {
              data: {
                attributes: {
                  overrideShouldCountTowardsUnreadMessageCount: false,
                },
                messageType: MessageType.Note,
                body: `Conversation was ${
                  channelStatusAction === ChannelStatusAction.Inactive ? 'closed' : 'archived'
                } by ${clinicUser?.displayName || `${clinicUser?.firstName} ${clinicUser?.lastName}`}`,
                channelId,
              },
            },
          });
        }
      });
    },
  });

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
      const canGetNextPage = scrollTop + clientHeight + scrollPixelVariance >= scrollHeight;
      if (hasNextPage && canGetNextPage && !isLoading) {
        setPageNumber(currentPage + 1);
      }
    },
    [currentPage, hasNextPage, setPageNumber, isLoading],
  );

  if (isHidden) {
    return <></>;
  }

  if (isCollapsed) {
    return (
      <HStack
        className="Board__Column--Collapsed"
        maxW={10}
        minW={10}
        h="100%"
        mx="1"
        align="flex-start"
        justify="center"
        bg={`${columnColor}33`}
        {...(borderColor && {
          border: '1px',
          borderColor,
        })}
        borderRadius="lg"
        opacity={isOver ? 0.5 : 1}
        ref={
          boardSettings?.currentView === BoardFilterView.Conversations || channelStatusId === NO_STATUS ? null : drop
        }
      >
        <Flex
          className="Board__Column-Header"
          justify="center"
          align="center"
          pt={3}
          style={{ writingMode: 'vertical-rl', textOrientation: 'mixed' }}
          borderRadius="4px 4px 0 0"
        >
          {canCollapse && (
            <Button iconName="chevronRight" variant="ghostNeutral" onClick={toggleColumnCollapse} size="xs" mb={2} />
          )}
          <Box mb={3} borderRadius="md" border="2px" borderColor="border.strong" px={1}>
            <Text size="sm" fontWeight="bold">
              {numberOfCards}
            </Text>
          </Box>
          <Text size="lg" fontWeight="bold" borderLeft="4px" borderLeftColor={columnColor}>
            {columnName}
          </Text>
        </Flex>
      </HStack>
    );
  }

  return (
    <Flex
      className="Board__Column"
      align="flex-start"
      bg={`${columnColor}33`}
      borderRadius="xl"
      h="100%"
      pos="relative"
      opacity={isOver ? 0.5 : 1}
      {...(borderColor && {
        border: '1px',
        borderColor,
      })}
      maxW={boardSettings?.currentView === BoardFilterView.Automations ? '32.8%' : '372px'}
      minW={boardSettings?.currentView === BoardFilterView.Automations ? '32.8%' : '372px'}
      flexDirection="column"
      justifyContent="flex-start"
      ref={boardSettings?.currentView === BoardFilterView.Automations || channelStatusId === NO_STATUS ? null : drop}
    >
      <HStack
        className="Board__Column-Header"
        align="center"
        w="100%"
        bg="background.default"
        borderBottom="4px"
        justify="space-between"
        borderBottomColor={columnColor}
        boxShadow="md"
        pl={3}
        pr={1}
        py={2}
        borderTopRadius={12}
      >
        <HStack>
          <Text
            size="xs"
            fontWeight="bold"
            borderRadius="md"
            border="1px"
            borderColor="border.strong"
            h="max-content"
            px={1}
          >
            {numberOfCards}
          </Text>
          <Text isTruncated size="lg" fontWeight="bold">
            {columnName}
          </Text>
        </HStack>
        {canCollapse && (
          <Button iconName="chevronLeft" variant="ghostNeutral" onClick={toggleColumnCollapse} size="xs" minW={20}>
            Collapse
          </Button>
        )}
      </HStack>
      {!isCollapsed && (
        <VStack
          className="Board__Column-ScrollContainer"
          overflowY="auto"
          h="max-content"
          w="100%"
          px={3}
          pb={4}
          spacing={3}
          onScroll={handleScroll}
          p={3}
          pos="relative"
        >
          {children}
          {isLoading && (
            <Box>
              <Spinner />
            </Box>
          )}
        </VStack>
      )}
    </Flex>
  );
};

export default BoardColumn;
