import { Button } from '@televet/kibble-ui';
import { Box } from '@televet/kibble-ui/build/chakra';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

type DictationButtonProps = {
  isSendingMessage: boolean;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  inputRef: React.RefObject<HTMLTextAreaElement>;
};

const DictationButton = ({ isSendingMessage, setMessage, inputRef }: DictationButtonProps): JSX.Element => {
  const [isDictating, setIsDictating] = useState(false);
  const [originalMessage, setOriginalMessage] = useState('');
  const [dictationDraft, setDictationDraft] = useState('');
  const [selectionIndex, setSelectionIndex] = useState({
    start: 0,
    end: 0,
  });
  const isSpeechRecognitionSupported = !!window.SpeechRecognition || !!window.webkitSpeechRecognition;

  const { channelId } = useParams<{ channelId: string }>();

  useEffect(() => {
    const newMessage =
      originalMessage.slice(0, selectionIndex.start) + dictationDraft + originalMessage.slice(selectionIndex.end);
    setMessage(newMessage);
    setTimeout(() => {
      inputRef.current?.setSelectionRange(
        selectionIndex.start + dictationDraft.length,
        selectionIndex.end + dictationDraft.length,
      );
      inputRef.current?.focus();
    }, 0);
  }, [inputRef, dictationDraft, originalMessage, selectionIndex.end, selectionIndex.start, setMessage]);

  // Handle when the message is send and still listening for dictation
  useEffect(() => {
    if (isSendingMessage) {
      stopDictation();
    }
  }, [isSendingMessage]);

  // Handle when the component is unmounted
  useEffect(() => {
    return (): void => {
      stopDictation();
    };
  }, []);

  // Handle Navigating away from the conversation
  useEffect(() => {
    stopDictation();
  }, [channelId]);

  const speechRecognitionRef = useRef<null | SpeechRecognition>(null);

  const stopDictation = (): void => {
    setIsDictating(false);
    speechRecognitionRef.current?.stop();
    speechRecognitionRef.current?.abort();
    speechRecognitionRef.current = null;
  };

  const handleSpeechRecognition = (): void => {
    const speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!speechRecognition) {
      return;
    }

    if (!isDictating) {
      setDictationDraft('');
      setOriginalMessage(inputRef.current?.value || '');
      setSelectionIndex({
        start: inputRef.current?.selectionStart || 0,
        end: inputRef.current?.selectionEnd || 0,
      });
      speechRecognitionRef.current = new speechRecognition();
      speechRecognitionRef.current.continuous = true;
      speechRecognitionRef.current.interimResults = true;
      speechRecognitionRef.current.lang = 'en-US';

      speechRecognitionRef.current.onstart = (): void => {
        setIsDictating(true);
      };

      speechRecognitionRef.current.onend = (): void => {
        speechRecognitionRef.current = null;
      };

      speechRecognitionRef.current.onresult = (event): void => {
        let messageString = ' ';

        const results = event.results;
        for (let index = 0; index < results.length; index++) {
          const result = results[index];
          messageString += result[0].transcript + ' ';
        }
        const speechMessage = messageString
          .replaceAll(' period', '\b.')
          .replaceAll(' colon', '\b:')
          .replaceAll(' comma', ',')
          .replaceAll(' question mark', '?')
          .replaceAll(' exclamation point', '!')
          .replaceAll(' dash', '-')
          .replaceAll(' underscore', '_')
          .replaceAll(' slash', '/');
        if (!isSendingMessage) {
          setDictationDraft(speechMessage);
        }
      };
      speechRecognitionRef.current.start();
    } else {
      stopDictation();
      inputRef.current?.focus();
    }
  };
  return (
    <Box className="MessageComposer__DictationButton">
      <Tooltip isDisabled={isSpeechRecognitionSupported} label="Speech Recognition is not supported in this browser">
        <Box pos="relative">
          <Button
            mt={1}
            mr={1}
            onClick={handleSpeechRecognition}
            variant={isDictating ? 'primary' : 'ghostNeutral'}
            iconName="microphone"
            isDisabled={!isSpeechRecognitionSupported}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default DictationButton;
