import { useCallback, useMemo } from 'react';
import differenceBy from 'lodash-es/differenceBy';
import {
  ConditionAttribute,
  ConditionUpdateManyWithoutSetNestedInput,
  useGetAutomationEditorDataQuery,
  useUpdateOneAutomationAndConditionSetMutation,
  WorkflowActionType,
  WorkflowEvent,
  WorkflowEventActionCreateWithoutTriggerWorkflowInput,
  WorkflowEventActionCreateWithoutWorkflowEventSettingInput,
  WorkflowEventActionUncheckedCreateWithoutWorkflowEventSettingInput,
  WorkflowEventActionUpdateWithWhereUniqueWithoutWorkflowEventSettingInput,
  WorkflowEventActionWhereUniqueInput,
  ConditionOperator,
} from 'shared/types/graphql';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { AutomationDraft, ExtendedWorkflowEvent, TimeIncrement } from '../types/AutomationDraft';
import { convertTime } from '../state/utils/time';
import { convertAutomationPersistedToDraft, initialAutomationDraft } from '../state/utils/draft';
import { AutomationPrompt } from '../types/AutomationPrompt';
import pick from 'lodash-es/pick';
import isEqual from 'lodash-es/isEqual';
import isEqualWith from 'lodash-es/isEqualWith';
import omit from 'lodash-es/omit';
import difference from 'lodash-es/difference';
import { useHistory } from 'react-router-dom';
import useClinicUser from 'shared/hooks/useClinicUser';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import { useScrollToActiveAutomation } from './useScrollToActiveAutomation';
import {
  updateAutomationDraft,
  updateShouldBlockNavigation,
  updateValidationActionWord,
} from '../state/automationsSlice';
import { AutomationEditorView } from '../types/AutomationEditorView';
import { GraphQLFetchPolicies } from 'shared/enums';
import { updateAutomationErrors } from '../state/automationsSlice';
import { ConversationStatusSelectValue } from 'shared/components/ConversationStatusSelect';
import { ValidationActionWord } from '../types/AutomationValidation';
import { TimeFormatValue } from 'shared/components/WildCardInputGroup/WildcardTimeFormatSelect';
import { set } from 'date-fns';
import {
  getAutomationsListQuery,
  getAutomationParentAutomationsQuery,
  getGlobalAutomationsListQuery,
} from '../constants/operationNames';
import { useCreateAutomation } from '../hooks/useCreateAutomation';
import { hashCondition } from '../utils/hashCondition';
import { getConditionHasValue } from '../utils/getConditionHasValue';
import { PatientAgeUnit } from '../types/SendConditionPatientAge';
import { useCurrentAutomationId } from './useCurrentAutomationId';
import { WorkflowEventActionConfig } from '@televet/shared-types/JsonTypes/WorkflowEventActionConfig';
import { useGetAutomationsBasePath } from './useGetAutomationsBasePath';

// Please exercise extreme caution when using this hook.
// This hook will cause a rerender every time the automation draft state is changed.
export const useSaveAutomationDraft = (): {
  hasUnsavedChanges: boolean;
  isNewUneditedAutomation: boolean;
  originalAutomation: AutomationDraft;
  isSaving: boolean;
  saveAutomationDraft: (updates?: Partial<AutomationDraft>) => Promise<void>;
} => {
  const currentAutomationId = useCurrentAutomationId();
  const automationDraft = useAppSelector((state) => state.automations.automationDraft);
  const isGlobalEditor = useAppSelector((state) => state.automations.isGlobalEditor);
  const { scrollToActiveAutomation } = useScrollToActiveAutomation();
  const { currentClinicId } = useClinicUser();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { automationsBasePath } = useGetAutomationsBasePath();

  const { data: automationData } = useGetAutomationEditorDataQuery({
    variables: { workflowEventSettingId: automationDraft.automationId },
    skip: !automationDraft.automationId,
    fetchPolicy: GraphQLFetchPolicies.CacheOnly,
  });

  const [updateOneAutomationAndConditionSet, { loading: isUpdatingOneAutomationAndConditionSet }] =
    useUpdateOneAutomationAndConditionSetMutation({
      onCompleted: (data) => {
        const newAutomationData = convertAutomationPersistedToDraft({
          automation: data?.updateOneWorkflowEventSetting,
          conditionSets: [data.updateOneConditionSet],
        });
        if (currentAutomationId === newAutomationData.automationId) {
          dispatch(updateAutomationDraft(newAutomationData));
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: isGlobalEditor
        ? [getGlobalAutomationsListQuery, getAutomationParentAutomationsQuery]
        : [getAutomationsListQuery, getAutomationParentAutomationsQuery],
    });

  const { createAutomation, isLoading: isCreatingOneAutomation } = useCreateAutomation({
    onCompleted: async (data) => {
      const newAutomationData = convertAutomationPersistedToDraft({
        automation: data?.createWorkflowEventSettingAndConditions,
      });

      dispatch(updateAutomationDraft(newAutomationData));

      history.push(`${automationsBasePath}/${newAutomationData.automationId}/${AutomationEditorView.Settings}`);
      scrollToActiveAutomation(newAutomationData.automationId);

      dispatch(updateShouldBlockNavigation(true));
    },
  });

  const originalAutomation = useMemo(
    () =>
      convertAutomationPersistedToDraft({
        automation: automationData?.findUniqueWorkflowEventSetting,
        conditionSets: automationData?.findManyConditionSet,
      }),
    [automationData],
  );

  const isNewUneditedAutomation = useMemo(() => {
    return !originalAutomation.automationId && isEqual(automationDraft, initialAutomationDraft);
  }, [originalAutomation, automationDraft]);

  const hasUnsavedChanges = useMemo(() => {
    const equalityValidation = (automationDraft: AutomationDraft, originalAutomation: AutomationDraft): boolean => {
      const automationDraftPrompts = automationDraft.prompts;
      const originalAutomationPrompts = originalAutomation.prompts;
      return isEqual(automationDraft, originalAutomation) && isEqual(automationDraftPrompts, originalAutomationPrompts);
    };
    return !isEqualWith(automationDraft, originalAutomation, equalityValidation);
  }, [automationDraft, originalAutomation]);

  const saveAutomationDraft = useCallback(
    async (updates?: Partial<AutomationDraft>): Promise<void> => {
      dispatch(updateShouldBlockNavigation(false));

      const {
        automationId,
        automationName,
        automationType,
        forms,
        isPublished,
        notificationMessage,
        prompts,
        requestOptions,
        sendConditions,
        conditionSets,
        sendFrequency,
        sendHours,
        sendTiming,
        timeFormat,
        triggerType,
      } = automationDraft;

      // Prevent user from saving an automation without a name/type
      if (!automationName || !automationType) {
        dispatch(updateValidationActionWord(ValidationActionWord.Saving));

        if (!automationName) {
          dispatch(updateAutomationErrors({ automationName: ['nameEmpty'] }));
        }
        if (!automationType) {
          dispatch(updateAutomationErrors({ automationType: ['typeEmpty'] }));
        }
        return;
      }
      let widgetRequestTypes;
      let triggeringFormTemplates;
      let actions;

      // If is updating existing automation
      if (automationId) {
        widgetRequestTypes = { set: requestOptions?.map((id) => ({ id })) };
        triggeringFormTemplates = { set: forms?.map((id) => ({ id })) };

        const resolveActionDifferences = (
          original: AutomationPrompt[],
          incoming: AutomationPrompt[],
        ): {
          update: WorkflowEventActionUpdateWithWhereUniqueWithoutWorkflowEventSettingInput[];
          create: WorkflowEventActionCreateWithoutWorkflowEventSettingInput[];
          delete: WorkflowEventActionWhereUniqueInput[];
        } => {
          const deletions: WorkflowEventActionWhereUniqueInput[] = differenceBy(original, incoming, 'id').map(
            ({ id }) => ({
              id,
            }),
          );
          const creations: WorkflowEventActionCreateWithoutWorkflowEventSettingInput[] = [];
          const updates: WorkflowEventActionUpdateWithWhereUniqueWithoutWorkflowEventSettingInput[] = [];

          incoming.forEach((action, index) => {
            const { promptType, config, channelStatusChangeId, triggerDelayInMilliseconds, triggerActionNumber } =
              action;

            // Map conversation status and invoice amount for config
            const { invoiceAmount } = config;

            let formattedInvoiceAmount;
            if (invoiceAmount) {
              formattedInvoiceAmount = parseInt(invoiceAmount.replace('.', ''));
            }

            // Map conversation status for prompt
            let formattedChannelStatusId = channelStatusChangeId;
            if (channelStatusChangeId === ConversationStatusSelectValue.NoChange) {
              formattedChannelStatusId = null;
            }

            const updatedConfig: WorkflowEventActionConfig = JSON.parse(JSON.stringify(config));
            if (updatedConfig.buttons) {
              updatedConfig.buttons = updatedConfig.buttons.map((button) => {
                const { channelStatusChangeId } = button;
                return {
                  ...button,
                  channelStatusChangeId:
                    channelStatusChangeId === ConversationStatusSelectValue.NoChange
                      ? null
                      : channelStatusChangeId ?? null,
                };
              });
            }

            const newActionData:
              | WorkflowEventActionUncheckedCreateWithoutWorkflowEventSettingInput
              | WorkflowEventActionCreateWithoutTriggerWorkflowInput = {
              actionType: WorkflowActionType.WorkflowEventTrigger,
              order: index + 1,
              promptType,
              config: {
                ...updatedConfig,
                timeFormat: action.config.timeFormat || TimeFormatValue.DayAndDateAndTime,
                invoiceAmount: formattedInvoiceAmount,
              },
              workflowEventTriggerDelayInMilliseconds: triggerDelayInMilliseconds,
              workflowEventTriggerActionNumber: triggerActionNumber,
            };

            if (action.id) {
              updates.push({
                where: { id: action.id },
                data: { ...newActionData, channelStatusChangeId: formattedChannelStatusId },
              });
            } else {
              creations.push({
                ...newActionData,
                channelStatusChange: formattedChannelStatusId ? { connect: { id: channelStatusChangeId } } : undefined,
              });
            }
          });

          return {
            update: updates,
            create: creations,
            delete: deletions,
          };
        };

        actions = resolveActionDifferences(originalAutomation.prompts, prompts);
      } else {
        widgetRequestTypes = { connect: requestOptions?.map((id) => ({ id })) };
        triggeringFormTemplates = { connect: forms?.map((id) => ({ id })) };
        actions = {
          create: prompts?.map((action, index) => {
            const { promptType, config, channelStatusChangeId, triggerDelayInMilliseconds, triggerActionNumber } =
              action;

            let invoiceAmount;

            if (config.invoiceAmount) {
              invoiceAmount = parseInt(config.invoiceAmount.replace('.', ''));
            }

            return {
              actionType: WorkflowActionType.WorkflowEventTrigger,
              order: index + 1,
              promptType,
              config: {
                ...config,
                timeFormat: action.config.timeFormat || TimeFormatValue.DayAndDateAndTime,
                invoiceAmount,
              },
              workflowEventTriggerDelayInMilliseconds: triggerDelayInMilliseconds,
              workflowEventTriggerActionNumber: triggerActionNumber,
              channelStatusChange: channelStatusChangeId ? { connect: { id: channelStatusChangeId } } : undefined,
            };
          }),
        };
      }

      const automatedTriggerInMinutes = convertTime(
        sendTiming.timeValue || 0,
        sendTiming.timeIncrement,
        TimeIncrement.Minutes,
      );

      let timeOfDay = null;

      if (sendTiming.timeOfDay) {
        timeOfDay = sendTiming.timeOfDay;
      } else if (sendTiming.timeIncrement === TimeIncrement.Days || automationType === WorkflowEvent.LapsedPetParent) {
        timeOfDay = set(new Date(), { hours: 9, minutes: 0 });
      }

      const updatedAutomation = {
        name: automationName,
        isPublished,
        event:
          automationType === ExtendedWorkflowEvent.FromAnotherAutomation
            ? WorkflowEvent.AppointmentReminder
            : automationType,
        automatedTriggerType: sendTiming.triggerType,
        automatedTriggerTimeOfDay: timeOfDay,
        automatedTriggerInMinutes,
        sendingHours: sendHours,
        triggerType,
        visitType: sendFrequency,
        widgetRequestTypes,
        triggeringFormTemplates,
        notificationBody: notificationMessage,
        timeFormat: timeFormat || TimeFormatValue.DayAndDate,
        actions,
        ...updates,
      };

      const existingConditions = originalAutomation.conditionSets[0]?.conditions || [];

      const conditionOperations = Object.entries(sendConditions).reduce((prev, [key, value]) => {
        if (!value) {
          return prev;
        }

        const sendCondition = JSON.parse(JSON.stringify(value));

        const conditionAttribute: ConditionAttribute = key as ConditionAttribute;
        const existingCondition = existingConditions.find((condition) => condition.attribute === conditionAttribute);

        if (
          sendCondition.attribute === ConditionAttribute.PetAgeInYears &&
          sendCondition.operator !== ConditionOperator.Between && // TODO (future PR): Handle "between"
          sendCondition.operand.displayUnit === PatientAgeUnit.Months &&
          typeof sendCondition.operand.value === 'number'
        ) {
          sendCondition.operand.value = sendCondition.operand.value / 12;
        }

        const newCondition = {
          attribute: conditionAttribute,
          operator: sendCondition.operator,
          operand: omit(sendCondition.operand, 'displayUnit'),
          checksum: '',
        };

        newCondition.checksum = hashCondition(pick(newCondition, 'attribute', 'operator', 'operand'));

        const hasValue = getConditionHasValue(newCondition);

        if (existingCondition) {
          if (hasValue) {
            const conditionUpdate = { where: { id: existingCondition.id }, data: newCondition };
            prev.update ? prev.update.push(conditionUpdate) : (prev.update = [conditionUpdate]);
          } else {
            prev.delete
              ? prev.delete.push({ id: existingCondition.id })
              : (prev.delete = [{ id: existingCondition.id }]);
          }
        } else if (hasValue) {
          prev.create ? prev.create.push(newCondition) : (prev.create = [newCondition]);
        }
        return prev;
      }, {} as ConditionUpdateManyWithoutSetNestedInput);

      // Delete any Condition records that are no longer included in sendConditions
      const conditionAttributesToDelete = difference(
        existingConditions.map((condition) => condition.attribute),
        Object.keys(sendConditions),
      );
      if (conditionAttributesToDelete.length) {
        conditionOperations.delete = existingConditions
          .filter((condition) => conditionAttributesToDelete.includes(condition.attribute))
          .map((condition) => ({ id: condition.id }));
      }

      const conditionSetData = {
        conditions: conditionOperations,
      };

      let toastMessageActionWord = 'saved';

      if (updates) {
        toastMessageActionWord = updates.isPublished ? 'activated' : 'deactivated';
      }

      try {
        if (automationId) {
          const conditionSetId = conditionSets[0]?.id;

          if (conditionSetId) {
            await updateOneAutomationAndConditionSet({
              variables: {
                workflowEventSettingId: automationId,
                workflowEventSettingData: updatedAutomation,
                conditionSetId,
                conditionSetData,
              },
            });
          }
        } else {
          await createAutomation({
            workflowEventSettingData: {
              ...updatedAutomation,
              ...(!isGlobalEditor && {
                clinic: {
                  connect: {
                    id: currentClinicId,
                  },
                },
              }),
            },
            conditionsData: conditionOperations.create || [],
          });
        }
        toast({
          status: 'success',
          title: 'Success!',
          description: `Automation ${toastMessageActionWord} successfully.`,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const errorField = error.graphQLErrors?.[0]?.extensions?.field;
        const promptOrder = error.graphQLErrors?.[0]?.extensions?.order;

        let errorMessage = error.graphQLErrors?.[0]?.message;

        // Validate wildcards for prompts fields
        if (promptOrder && errorField === 'messageBodyTemplate') {
          errorMessage = `There are invalid tags in the question or message field of prompt #${promptOrder}. Please ensure your tags are properly formatted.`;
        }

        // Validate wildcards for notification message
        if (errorField === 'notificationBody') {
          errorMessage = `There are invalid tags in the notification message field. Please ensure your tags are properly formatted.`;
        }

        toast({
          status: 'error',
          title: `Automation not ${toastMessageActionWord} successfully.`,
          description: errorMessage,
        });
      }

      // TODO: Update draft automation based on response from mutation — automation draft state updates
      // currently result from the getAutomationEditorData cache-and-network fetch policy
    },
    [
      dispatch,
      automationDraft,
      originalAutomation.conditionSets,
      originalAutomation.prompts,
      toast,
      updateOneAutomationAndConditionSet,
      createAutomation,
      isGlobalEditor,
      currentClinicId,
    ],
  );

  const isSaving = useMemo(() => {
    return isCreatingOneAutomation || isUpdatingOneAutomationAndConditionSet;
  }, [isCreatingOneAutomation, isUpdatingOneAutomationAndConditionSet]);

  return {
    saveAutomationDraft,
    hasUnsavedChanges,
    isNewUneditedAutomation,
    originalAutomation,
    isSaving,
  };
};
