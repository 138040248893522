import React, { useEffect, useState, useMemo, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { Call, Device } from '@twilio/voice-sdk';

import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { Popover, PopoverSizes, usePopover } from 'shared/providers/PopoverWindowProvider';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { GraphQLErrorPolicies } from 'shared/enums/GraphQLErrorPolicies';
import { SEARCH_CLINIC_PET_PARENT, GET_TWILIO_PHONE_TOKEN } from 'shared/queries';
import { CREATE_ONE_CALL_RECORDING, UPDATE_ONE_CALL_RECORDING } from 'shared/mutations';
import { PopoverNames } from 'shared/enums/PopoverNames';
import {
  ClinicPetParent as ClinicPetParentT,
  CallHistoryType,
  CallParticipantCreateInput,
  CallProvider,
  CallStatus,
  TwilioRecordingStatus,
  useCreateSystemChannelMessageMutation,
  useGetClinicSettingsGeneralQuery,
  MessageType,
} from 'shared/types/graphql';
import { ITwilioState } from './interfaces/ITwilioState';
import { pad } from './utils';
import { Mixpanel } from 'shared/utils/mixpanel';
import MobilePhoneCall from './MobilePhoneCall';
import { MessageTypes, useSnackbar } from '@televet/televet-ui';
import {
  cleanPhoneNumber,
  formatPhoneNumber,
  getPhoneNumberE164,
  selectPrimaryPhoneNumberBestGuess,
} from 'shared/utils';
import useClinicUser from 'shared/hooks/useClinicUser';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import { CREATE_ONE_CALL_HISTORY, CREATE_ONE_CALL_PARTICIPANT, UPDATE_ONE_CALL_HISTORY } from 'shared/mutations';
import CallRecordingSelectionModal from '../CallRecordingSelectionModal';
import { isMicrophoneEnabled } from '../UnsupportedBrowserPopover/checkBrowserSupport';
import { Text, HStack, VStack, Avatar, Button, useDisclosure, Collapse } from '@televet/kibble-ui';
import DialPad from './DialPad';

const defaultFromNumber = '+1 844 932 3838';

interface IPhoneCallPopoverProps {
  clinicPetParent?: ClinicPetParentT;
  startTop?: number;
  startLeft?: number;
  closePopover: () => void;
  onReject?: () => void;
  phoneNumber?: string;
  isCallWithRecording?: boolean;
}

const twilioVoiceErrorCodes: Record<number, string> = {
  53405:
    "Phone call unable to connect: Please check your network's firewall and security settings to ensure the browser's ability to connect.",
  31401: 'Please make sure your microphone permissions for Otto are enabled in your browser settings.',
};

const PhoneCallPopover = ({
  clinicPetParent,
  startTop = 100,
  startLeft = window.innerWidth - 715,
  onReject,
  closePopover,
  phoneNumber,
  isCallWithRecording,
}: IPhoneCallPopoverProps): JSX.Element => {
  const [timer, setTimer] = useState(0);
  const [isCallTypeSelected, setIsCallTypeSelected] = useState(!!phoneNumber ? true : false);
  const [isMicEnabled, setIsMicEnabled] = useState(false);
  const [time, setTime] = useState(new Date());
  const [device, setDevice] = useState<Device | null>(null);
  const [state, setState] = useState<ITwilioState>({
    status: null,
    error: null,
  });
  const [callConnection, setCallConnection] = useState<Call>();
  const [isRecording, setIsRecording] = useState(isCallWithRecording || false);
  const [isRecordingDisabled, setIsRecordingDisabled] = useState(!isRecording || !isCallWithRecording);
  const [recordingId, setRecordingId] = useState<string | null>(null);
  const { addSnackbar } = useSnackbar();
  const { isMobile, isTouchDevice } = useResolutionProvider();
  const { clinicUser, currentClinicId } = useClinicUser();

  const {
    isOpen: isCallRecordingSelectionModalOpen,
    onClose: closeCallRecordingSelectionModalOpen,
    onOpen: openCallRecordingSelectionModal,
  } = useDisclosure();
  const { openPopover } = usePopover();
  const { data: currentClinic } = useGetClinicSettingsGeneralQuery({
    variables: { id: currentClinicId || '' },
    fetchPolicy: GraphQLFetchPolicies.CacheFirst,
    skip: !currentClinicId,
  });

  useEffect(() => {
    if (isMicEnabled) {
      openCallRecordingSelectionModal();
    }
  }, [isMicEnabled, openCallRecordingSelectionModal]);

  const clinicPhoneNumber = useMemo(
    () =>
      currentClinic?.findUniqueClinic?.phone && currentClinic?.findUniqueClinic?.isCallerIdVerified
        ? currentClinic?.findUniqueClinic?.phone
        : null,
    [currentClinic],
  );

  const [createOneCallHistory] = useMutation(CREATE_ONE_CALL_HISTORY);
  const [updateOneCallHistory] = useMutation(UPDATE_ONE_CALL_HISTORY);
  const [createOneCallParticipant] = useMutation(CREATE_ONE_CALL_PARTICIPANT);

  const [createOneCallRecording] = useMutation(CREATE_ONE_CALL_RECORDING);
  const [updateOneCallRecording] = useMutation(UPDATE_ONE_CALL_RECORDING);

  const apolloClient = useApolloClient();

  /**
   * Retreive token to be able to make an authenticated call
   */
  const { data: twilioTokenData } = useQuery(GET_TWILIO_PHONE_TOKEN, {
    errorPolicy: GraphQLErrorPolicies.All,
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
  });

  const [createSystemMessage] = useCreateSystemChannelMessageMutation();

  const callTime = useMemo(() => {
    const hours = Math.floor(timer / 60 / 60);
    const minutes = Math.floor(timer / 60) - hours * 60;
    const seconds = timer % 60;
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }, [timer]);

  const systemMessage = useCallback(() => {
    const elapsed = (Date.now() - time.getTime()) / 1000;
    const hours = Math.floor(elapsed / 60 / 60);
    const minutes = Math.floor(elapsed / 60) - hours * 60;
    const seconds = Math.round(elapsed % 60);

    const primaryPhoneNumber = selectPrimaryPhoneNumberBestGuess(clinicPetParent?.phoneNumbers || []);
    createSystemMessage({
      variables: {
        data: {
          body: `Phone call made for ${`${pad(hours)}:${pad(minutes)}:${pad(seconds)}`} to ${[
            clinicPetParent?.firstName || 'Unknown',
            clinicPetParent?.lastName || 'client',
          ].join(' ')} at ${formatPhoneNumber(primaryPhoneNumber)}`,
          petParentIds: [clinicPetParent?.id || ''],
          messageType: MessageType.Note,
        },
      },
    });
  }, [clinicPetParent, createSystemMessage, time]);

  const onDisconnect = useCallback(() => {
    if (!device) {
      closePopover();
      return;
    }
    setIsRecording(false);
    setIsCallTypeSelected(false);
    device.disconnectAll();
    device.destroy();

    closePopover();
  }, [closePopover, device]);

  /**
   * Where we set the device for the phone call and its listeners
   */
  useEffect(() => {
    if (!twilioTokenData) return;

    const token = twilioTokenData?.twilioCapabilityToken?.token;

    if (!token) {
      console.warn('No TwilioCapabilityToken was returned.');
      closePopover();
      addSnackbar({
        type: MessageTypes.Error,
        message: `The call was not able to connect. Please refresh the page and try again.`,
        timeout: 8000,
      });
      return;
    }

    // Authenticate the user's ability to place a phone call
    const device = new Device(token, {
      logLevel: 'info', // type of LogLevelDesc
      appName: 'clinic-web',
      closeProtection: true,
    });
    setDevice(device);

    device.on('registered', () => {
      setTime(new Date());
      Mixpanel.track('Phone call started with pet parent');
    });

    device.on(Device.EventName.Error, (err) => {
      addSnackbar({
        type: MessageTypes.Info,
        message: twilioVoiceErrorCodes[err.code] || err.message || 'The call was not able to connect.',
        timeout: 8000,
      });

      Sentry.captureException(err);
      console.error(err);

      setState({
        ...state,
        status: CallStatus.Failed,
      });
    });

    return (): void => {
      device.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, twilioTokenData, onReject, clinicPetParent]);

  const checkMicroPhoneEnabled = useCallback(async () => {
    const micEnabled = await isMicrophoneEnabled();

    if (!micEnabled) {
      openPopover(PopoverNames.MicrophoneBlocked);
      return;
    }
    setIsMicEnabled(true);
    return true;
  }, [openPopover]);

  const startRecording = useCallback(
    async (callObject: Call | undefined) => {
      if (callObject === undefined) return;
      setIsRecording(true);
      if (recordingId) {
        // resume existing recording
        updateOneCallRecording({
          variables: {
            where: {
              id: recordingId,
            },
            data: {
              status: TwilioRecordingStatus.InProgress,
            },
          },
        }).catch((e) => {
          Sentry.captureException(e);
          console.warn(e);
        });
        Mixpanel.track('Call recording resumed', { recordingId });
      } else {
        // create new recording
        const callRecordingResponse = await createOneCallRecording({
          variables: {
            callSid: callObject.parameters?.CallSid,
          },
        }).catch((e) => {
          Sentry.captureException(e);
          console.warn(e);
        });

        if (callRecordingResponse) {
          const callRecording = callRecordingResponse.data;
          setRecordingId(callRecording?.createOneCallRecording?.id);
        }

        Mixpanel.track('Call recording started', { recordingId });
      }
    },
    [recordingId, updateOneCallRecording, createOneCallRecording],
  );

  useEffect(() => {
    if (!device) return;
    const parentPrimaryPhone = selectPrimaryPhoneNumberBestGuess(clinicPetParent?.phoneNumbers || []);

    if (!(parentPrimaryPhone || phoneNumber)) return;
    if (state.status === CallStatus.Completed) return;
    checkMicroPhoneEnabled();
    if (!isCallTypeSelected) return;

    try {
      if ((parentPrimaryPhone || phoneNumber) && !callConnection) {
        //  Device.ConnectOptions is the param object's shape for device.connect
        device
          .connect({
            params: {
              to: parentPrimaryPhone || phoneNumber || '',
              from: clinicPhoneNumber || defaultFromNumber,
              shouldRecord: isRecording.toString(),
            },
          })
          .then((callObject: Call) => {
            let callHistoryId: string;
            setCallConnection(callObject);

            callObject.on('ringing', async () => {
              // create records inside callback to avoid Twilio timing issue with CallSid creation
              try {
                // create call history record

                const phoneNumberE164 = getPhoneNumberE164(
                  callObject.customParameters.get('from') || clinicPhoneNumber || defaultFromNumber,
                );

                const { data: callHistory } = await createOneCallHistory({
                  variables: {
                    data: {
                      sourceId: callObject.parameters.CallSid || '',
                      from: phoneNumberE164,
                      provider: CallProvider.Twilio,
                      type: CallHistoryType.Voice,
                      status: CallStatus.Ringing,
                      clinic: {
                        connect: {
                          id: currentClinicId || '',
                        },
                      },
                    },
                  },
                });

                // for use in updateOneCallHistory req
                callHistoryId = callHistory?.createOneCallHistory?.id;

                // store for use in CallParticipant mutations
                const callHistoryData = {
                  callHistory: {
                    connect: {
                      id: callHistory?.createOneCallHistory?.id,
                    },
                  },
                };
                // format phoneNumber as '1234567890' (not E.164) for db lookup and storage if not coming from clinicPetParent
                const cleanedPhoneNumber = cleanPhoneNumber(formatPhoneNumber(phoneNumber));

                // clinicPetParent does not exist when call is initiated from phone dialer, so try to look them up
                let clinicPetParentId = clinicPetParent?.id;
                if (!clinicPetParent) {
                  const { data: clinicPetParentData } = await apolloClient.query({
                    query: SEARCH_CLINIC_PET_PARENT,
                    variables: {
                      pageSize: 1,
                      whereInput: {
                        phoneNumber: cleanedPhoneNumber,
                      },
                    },
                    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
                  });

                  clinicPetParentId = clinicPetParentData?.clinicPetParentSearch[0]?.id;
                }

                // create data object with or without pet parent
                const petParentPhoneNumberE164 = getPhoneNumberE164(parentPrimaryPhone || cleanedPhoneNumber);
                let clinicPetParentData: Partial<CallParticipantCreateInput> = {
                  number: petParentPhoneNumberE164 || '',
                };
                if (clinicPetParentId) {
                  clinicPetParentData = {
                    ...clinicPetParentData,
                    clinicPetParent: {
                      connect: {
                        id: clinicPetParentId,
                      },
                    },
                  };
                }

                // create pet parent record
                await createOneCallParticipant({
                  variables: {
                    data: {
                      ...clinicPetParentData,
                      ...callHistoryData,
                    },
                  },
                });

                // create clinic user record
                await createOneCallParticipant({
                  variables: {
                    data: {
                      user: {
                        connect: {
                          id: clinicUser?.id,
                        },
                      },
                      ...callHistoryData,
                    },
                  },
                });
              } catch (e) {
                Sentry.captureException(e);
                console.warn(e);
              }
            });

            callObject.on('accept', () => {
              if (isRecording) {
                startRecording(callObject);
              }
            });

            callObject.on('cancel', () => {
              setState({
                ...state,
                status: CallStatus.Canceled,
              });

              const updateOnCancel = (): void => {
                updateOneCallHistory({
                  variables: {
                    where: {
                      id: callHistoryId,
                    },
                    data: {
                      status: CallStatus.Canceled,
                      sourceId: callObject.parameters.CallSid,
                    },
                  },
                }).catch((e) => {
                  Sentry.captureException(e);
                  console.warn(e);
                });
              };

              // make sure createOneCallHistory completed first
              if (callHistoryId) {
                updateOnCancel();
              } else {
                // try again after 5s
                setTimeout((): void => {
                  if (callHistoryId) {
                    updateOnCancel();
                  }
                }, 5000);
              }

              onDisconnect();
              Mixpanel.track('Phone call cancelled');
            });

            callObject.on('reject', () => {
              setState({
                ...state,
                status: CallStatus.NoAnswer,
              });

              const updateOnReject = (): void => {
                updateOneCallHistory({
                  variables: {
                    where: {
                      id: callHistoryId,
                    },
                    data: {
                      status: CallStatus.NoAnswer,
                      sourceId: callObject.parameters.CallSid,
                    },
                  },
                }).catch((e) => {
                  Sentry.captureException(e);
                  console.warn(e);
                });
              };

              // make sure createOneCallHistory completed first
              if (callHistoryId) {
                updateOnReject();
              } else {
                // try again after 5s
                setTimeout((): void => {
                  if (callHistoryId) {
                    updateOnReject();
                  }
                }, 5000);
              }

              if (onReject) onReject();
              onDisconnect();
              Mixpanel.track('Phone call rejected');
            });

            callObject.on('disconnect', () => {
              setState({
                ...state,
                status: CallStatus.Completed,
              });

              const updateOnDisconnect = (): void => {
                updateOneCallHistory({
                  variables: {
                    where: {
                      id: callHistoryId,
                    },
                    data: {
                      status: CallStatus.Completed,
                      duration: Math.round((Date.now() - time.getTime()) / 1000),
                      sourceId: callObject.parameters.CallSid,
                    },
                  },
                }).catch((e) => {
                  Sentry.captureException(e);
                  console.warn(e);
                });
              };

              // make sure createOneCallHistory completed first
              if (callHistoryId) {
                updateOnDisconnect();
              } else {
                // try again after 5s
                setTimeout((): void => {
                  if (callHistoryId) {
                    updateOnDisconnect();
                  }
                }, 5000);
              }

              onDisconnect();
              if (clinicPetParent) systemMessage();
            });
          });
      }
    } catch (e) {
      Sentry.captureException(e);
      console.warn(e);
      closePopover();
    }
  }, [
    device,
    clinicPetParent,
    clinicPhoneNumber,
    closePopover,
    phoneNumber,
    state,
    onDisconnect,
    onReject,
    systemMessage,
    createOneCallHistory,
    updateOneCallHistory,
    createOneCallParticipant,
    currentClinicId,
    clinicUser?.id,
    time,
    apolloClient,
    isCallTypeSelected,
    isRecording,
    checkMicroPhoneEnabled,
    callConnection,
    startRecording,
  ]);

  useEffect(() => {
    if (state.status === CallStatus.Completed) {
      setTimeout(() => {
        if (!device) return;
        device.disconnectAll();
        Mixpanel.track('Phone call ended', { callTime });

        closePopover();
      }, 2000);
    }
  }, [closePopover, device, state, callTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
    return (): void => clearInterval(interval);
  }, []);

  const pauseRecording = useCallback(async () => {
    setIsRecording(false);
    updateOneCallRecording({
      variables: {
        where: {
          id: recordingId,
        },
        data: {
          status: TwilioRecordingStatus.Paused,
        },
      },
    }).catch((e) => {
      Sentry.captureException(e);
      console.warn(e);
    });

    Mixpanel.track('Call recording paused', { recordingId });
  }, [updateOneCallRecording, recordingId]);

  const onCallTypeSelected = (recordingState: boolean): void => {
    setIsCallTypeSelected(true);
    setIsRecording(recordingState);
    setIsRecordingDisabled(!recordingState);
    closeCallRecordingSelectionModalOpen();
  };

  const onCloseCallRecordingSelectionModal = (): void => {
    onDisconnect();
    closeCallRecordingSelectionModalOpen();
  };

  const isMuted = useCallback(() => callConnection?.isMuted(), [callConnection]);

  const { isOpen: isDialPadOpen, onToggle: onDialPadToggle } = useDisclosure();

  if (isMobile) {
    if (!isCallTypeSelected) {
      return (
        <CallRecordingSelectionModal
          selectedPetParent={[clinicPetParent?.firstName, clinicPetParent?.lastName].join(' ').trim()}
          isOpen={isCallRecordingSelectionModalOpen}
          onCloseCallRecordingSelectionModal={onCloseCallRecordingSelectionModal}
          onCallSelected={(recordingState: boolean): void => onCallTypeSelected(recordingState)}
        />
      );
    }
    return (
      <Popover
        name={PopoverNames.PhoneCall}
        size={PopoverSizes.lg}
        id={PopoverNames.PhoneCall}
        startTop={100}
        startLeft={100}
        borderWidth={PopoverSizes.xs}
        isMobile={isMobile}
      >
        <MobilePhoneCall
          petParent={clinicPetParent}
          phoneNumber={phoneNumber}
          endCall={onDisconnect}
          callTime={callTime}
          callConnection={callConnection}
          isRecording={isRecording}
          isRecordingDisabled={isRecordingDisabled}
          pauseRecording={pauseRecording}
        />
      </Popover>
    );
  } else {
    if (!isCallTypeSelected) {
      return (
        <CallRecordingSelectionModal
          selectedPetParent={[clinicPetParent?.firstName, clinicPetParent?.lastName].join(' ').trim()}
          isOpen={isCallRecordingSelectionModalOpen}
          onCloseCallRecordingSelectionModal={onCloseCallRecordingSelectionModal}
          onCallSelected={(recordingState: boolean): void => onCallTypeSelected(recordingState)}
        />
      );
    }
    return (
      <Popover
        name={PopoverNames.PhoneCall}
        size={PopoverSizes.lg}
        id={PopoverNames.PhoneCall}
        startTop={startTop}
        startLeft={startLeft}
        borderWidth={PopoverSizes.xxs}
        isMobile={isMobile}
        isTouchDevice={isTouchDevice}
        width={300}
      >
        <VStack align="flex-start" py={4} px={4} flex="1" w="100%">
          <Text variant="subtle" size="sm">
            {state.status ? state.status : callTime}
          </Text>
          <HStack justify="space-between" flex="1" w="100%">
            {phoneNumber ? (
              <Text size="md" fontWeight="bold">
                {formatPhoneNumber(phoneNumber)}
              </Text>
            ) : clinicPetParent ? (
              <HStack>
                <Avatar
                  name={[clinicPetParent.firstName, clinicPetParent.lastName].join(' ').trim()}
                  showTooltip={false}
                  size="lg"
                />
                <Text fontWeight="bold" size="md">
                  {[clinicPetParent.firstName, clinicPetParent.lastName].join(' ').trim()}
                </Text>
              </HStack>
            ) : null}
            <HStack>
              <Button
                size="sm"
                variant="ghostNeutral"
                iconName={isMuted() ? 'microphoneMute' : 'microphone'}
                onClick={(): void => callConnection?.mute(!isMuted())}
              />
              <Button
                size="sm"
                isActive={isDialPadOpen}
                variant="ghostNeutral"
                iconName={'phoneButtons'}
                onClick={onDialPadToggle}
              />
            </HStack>
          </HStack>

          <Collapse in={isDialPadOpen} transition={{ exit: { duration: 0.3 }, enter: { duration: 0.3 } }}>
            <DialPad
              onKeyPress={(value: string): void => {
                callConnection?.sendDigits(value);
              }}
            />
          </Collapse>

          <HStack justifyContent={isRecordingDisabled ? 'flex-end' : 'space-between'} w="100%">
            {!isRecordingDisabled &&
              (isRecording ? (
                <Button
                  variant="tertiary"
                  iconName="stopRecording"
                  size="sm"
                  isDisabled={isRecordingDisabled}
                  onClick={async (): Promise<void> => {
                    if (!isRecordingDisabled) {
                      await pauseRecording();
                    }
                  }}
                >
                  <Text fontWeight="bold" size="sm">
                    Stop Recording
                  </Text>
                </Button>
              ) : (
                <Text variant="subtle" size="sm">
                  Recording Stopped
                </Text>
              ))}
            <Button
              iconName="phoneCancel"
              variant="primaryDestructive"
              size="sm"
              onClick={(): void => {
                onDisconnect();
                setIsCallTypeSelected(false);
              }}
            >
              End Call
            </Button>
          </HStack>
        </VStack>
      </Popover>
    );
  }
};
export default PhoneCallPopover;
