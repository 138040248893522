import { Modal, ModalBody, ModalCloseButton, ModalHeader } from '@televet/kibble-ui/build/components/Modal';
import { Box, BoxProps, Flex, FlexProps, ListItem, UnorderedList } from '@televet/kibble-ui/build/chakra';
import React from 'react';
import { Link } from '@televet/kibble-ui/build/components/Link';
import { Text } from '@televet/kibble-ui/build/components/Text';

const AWS_TEMPLATE_URL = 'https://ani-mail-custom-list-template.s3.us-east-2.amazonaws.com/template.csv';

type InformationModalProps = {
  onClose: () => void;
};

export const InformationModal = ({ onClose }: InformationModalProps): JSX.Element => {
  return (
    <Modal allowEscape autoFocus size="md" isOpen onClose={onClose}>
      <ModalCloseButton onClick={onClose} />
      <ModalHeader>Custom List Formatting</ModalHeader>
      <ModalBody>
        <Link variant="interactive" href={AWS_TEMPLATE_URL} rel="noreferrer" download>
          Download our template
        </Link>
        <Text fontWeight="normal"> or format your own existing spreadsheet with these column headers:</Text>
        <UnorderedList>
          <ListItem>
            <Text fontWeight="normal">Email Address</Text>
          </ListItem>
          <ListItem>
            <Text fontWeight="normal">First Name</Text>
          </ListItem>
          <ListItem>
            <Text fontWeight="normal">Last Name</Text>
          </ListItem>
        </UnorderedList>
        <Text fontWeight="normal" color="text.default">
          Save your file as a .CSV
        </Text>
        <Box mt={4} mb={6}>
          <CsvExampleTable />
        </Box>
      </ModalBody>
    </Modal>
  );
};

const rowProps: FlexProps = {
  borderRadius: 1,
  borderWidth: 1,
  boxShadow: 'md',
};

const HeaderRow = (props: FlexProps): JSX.Element => {
  return <Flex fontWeight="bold" mb={2} {...rowProps} {...props} />;
};

const Row = (props: FlexProps): JSX.Element => {
  return <Flex {...rowProps} {...props} />;
};

const Cell = (props: BoxProps): JSX.Element => {
  return <Box flex={1} px={4} py={1} fontSize="sm" {...props} borderColor="border.default" />;
};

const CsvExampleTable = (): JSX.Element => {
  return (
    <Box>
      <HeaderRow>
        <Cell borderRight="1px">Email Address</Cell>
        <Cell borderRight="1px">First Name</Cell>
        <Cell>Last Name</Cell>
      </HeaderRow>
      <Row>
        <Cell borderRight="1px">otto@otto.vet</Cell>
        <Cell borderRight="1px">Otto</Cell>
        <Cell>Vet</Cell>
      </Row>
    </Box>
  );
};
