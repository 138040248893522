import React, { useCallback, ChangeEvent, useState, useEffect, useMemo } from 'react';
import {
  Modal,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooterButtons,
  MenuItemProps,
  TextInput,
  VStack,
  HStack,
  Select,
  Text,
  Button,
  Menu,
  ModalProps,
} from '@televet/kibble-ui';
import { NextStepSelect } from '../NextStepSelect';
import { StatusChangeSelect } from '../StatusChangeSelect';
import { ButtonErrorDetail, errorDetails } from 'pages/Automations/types/AutomationValidation';
import partial from 'lodash-es/partial';
import { useAppDispatch } from 'state/hooks';
import {
  updateAutomationPromptButton,
  updateAutomationPromptButtonErrors,
} from 'pages/Automations/state/automationsSlice';
import { AutomationRunActionPromptType } from 'shared/types/graphql';
import ButtonActionContainer from './ButtonActionContainer';
import { automationActionLabels } from 'pages/Automations/constants/automationActionLabels';
import EditButtonModalSectionHeader from './EditButtonModalSectionHeader';
import ConversationTagMenu from './ConversationTagMenu';
import {
  ActionEffect,
  ActionEffectType,
  AddAppointmentNoteActionEffect,
  AutomationButtonAction,
  AutomationButtonVariant,
  IMessageButtonOption,
  TagConversationActionEffect,
} from '@televet/shared-types/JsonTypes/WorkflowEventActionConfig';
import withLazyModal from 'shared/components/LazyModal/withLazyModal';

const buttonStyleOptions = [
  { label: 'Default', value: AutomationButtonVariant.Secondary },
  { label: 'Strong', value: AutomationButtonVariant.Primary },
  { label: 'Subtle', value: AutomationButtonVariant.GhostNeutral },
];

const buttonActionOptions = [
  { label: 'No Change', value: ActionEffectType.None },
  { label: 'Confirmed', value: ActionEffectType.ConfirmAppointment },
];

interface EditButtonModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  button: IMessageButtonOption;
  buttonIndex: number;
  buttonErrors: ButtonErrorDetail | undefined;
  promptType: AutomationRunActionPromptType;
  promptIndex: number;
}

const EditButtonModal = ({
  button,
  buttonIndex,
  buttonErrors,
  promptType,
  promptIndex,
  isOpen,
  onClose,
}: EditButtonModalProps): JSX.Element | null => {
  const dispatch = useAppDispatch();

  const [buttonDraft, setButtonDraft] = useState<IMessageButtonOption>(button);
  const [selectedButtonActions, setSelectedButtonActions] = useState<AutomationButtonAction[]>([
    ...(!!button.channelStatusChangeId ? [AutomationButtonAction.SetConversationStatus] : []),
    ...(button.entityAction === ActionEffectType.ConfirmAppointment ||
    button.actionEffects?.some((effect) => effect.type === ActionEffectType.ConfirmAppointment)
      ? [AutomationButtonAction.SetAppointmentStatus]
      : []),
    ...(button.entityAction === ActionEffectType.AddTagsToChannel ||
    button.actionEffects?.some((effect) => effect.type === ActionEffectType.AddTagsToChannel)
      ? [AutomationButtonAction.AddConversationTags]
      : []),
  ]);

  useEffect(() => {
    if (isOpen) {
      setButtonDraft(button);
    }
  }, [button, isOpen]);

  const buttonTextErrors = buttonErrors?.buttonText;
  const buttonUrlErrors = buttonErrors?.externalUrl;

  const handleButtonTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(updateAutomationPromptButton({ promptIndex, buttonIndex, button: { body: event?.target.value || '' } }));

      if (buttonErrors?.buttonText) {
        dispatch(
          updateAutomationPromptButtonErrors({
            promptIndex,
            buttonIndex,
            button: { buttonText: [] },
          }),
        );
      }
    },
    [dispatch, promptIndex, buttonIndex, buttonErrors?.buttonText],
  );

  const handleSetAppointmentStatusChange = useCallback(
    (selectedItem: MenuItemProps) => {
      let actionEffects: ActionEffect[] = [...(buttonDraft.actionEffects || [])];

      if (selectedItem.value === ActionEffectType.ConfirmAppointment) {
        actionEffects.push({ type: ActionEffectType.ConfirmAppointment });
      } else {
        actionEffects = actionEffects.filter((effect) => effect.type !== ActionEffectType.ConfirmAppointment);
      }

      dispatch(
        updateAutomationPromptButton({
          promptIndex,
          buttonIndex,
          button: {
            entityAction: selectedItem.value as ActionEffectType,
            actionEffects,
          },
        }),
      );
    },
    [buttonDraft.actionEffects, dispatch, promptIndex, buttonIndex],
  );

  const handleButtonStyleChange = useCallback(
    (selectedItem: MenuItemProps) => {
      dispatch(
        updateAutomationPromptButton({
          promptIndex,
          buttonIndex,
          button: { style: selectedItem.value as AutomationButtonVariant },
        }),
      );
    },
    [buttonIndex, dispatch, promptIndex],
  );

  const handleButtonActionSelected = useCallback((selectedItem: MenuItemProps) => {
    setSelectedButtonActions((currentActions) => {
      const action = selectedItem.value as AutomationButtonAction;
      if (currentActions.includes(action)) {
        return currentActions.filter((currentAction) => currentAction !== action);
      }

      return [...currentActions, action];
    });
  }, []);

  const removeButtonAction = useCallback(
    (buttonAction: AutomationButtonAction) => {
      if (!selectedButtonActions.includes(buttonAction)) {
        return;
      }

      let buttonConfig: Partial<IMessageButtonOption> = {};
      switch (buttonAction) {
        case AutomationButtonAction.SetConversationStatus:
          buttonConfig = { channelStatusChangeId: undefined };
          break;
        case AutomationButtonAction.SetAppointmentStatus:
          const nonConfirmActionEffects = buttonDraft.actionEffects?.filter(
            (effect) => effect.type !== ActionEffectType.ConfirmAppointment,
          );
          buttonConfig = { entityAction: ActionEffectType.None, actionEffects: nonConfirmActionEffects };
          break;
        case AutomationButtonAction.AddConversationTags:
          const nonTagActionEffects = buttonDraft.actionEffects?.filter(
            (effect) => effect.type !== ActionEffectType.AddTagsToChannel,
          );
          buttonConfig = { actionEffects: nonTagActionEffects };
          break;
        default:
          break;
      }

      dispatch(
        updateAutomationPromptButton({
          promptIndex,
          buttonIndex,
          button: {
            ...buttonConfig,
          },
        }),
      );

      setSelectedButtonActions((currentActions) =>
        currentActions.filter((currentAction) => currentAction !== buttonAction),
      );
    },
    [buttonDraft.actionEffects, buttonIndex, dispatch, promptIndex, selectedButtonActions],
  );

  const getDraftActionEffectValue = useCallback(
    (type: ActionEffectType) => {
      if (buttonDraft.entityAction === type) {
        return buttonDraft.entityAction;
      }

      const actionEffect = buttonDraft.actionEffects?.find((effect) => effect.type === type);

      switch (type) {
        case ActionEffectType.AddAppointmentNote:
          return (actionEffect as AddAppointmentNoteActionEffect)?.appointmentNote;
        case ActionEffectType.AddTagsToChannel:
          return (actionEffect as TagConversationActionEffect)?.tags;
        case ActionEffectType.ConfirmAppointment:
          return actionEffect ? ActionEffectType.ConfirmAppointment : ActionEffectType.None;
        default:
          return undefined;
      }
    },
    [buttonDraft],
  );

  const buttonActionMenuItems: MenuItemProps[] = useMemo(() => {
    const items: MenuItemProps[] = [
      {
        label: automationActionLabels.setConversationStatus,
        value: AutomationButtonAction.SetConversationStatus,
        isSelected: selectedButtonActions.includes(AutomationButtonAction.SetConversationStatus),
      },
      {
        label: automationActionLabels.setAppointmentStatus,
        value: AutomationButtonAction.SetAppointmentStatus,
        isSelected: selectedButtonActions.includes(AutomationButtonAction.SetAppointmentStatus),
      },
      {
        label: automationActionLabels.addConversationTags,
        value: AutomationButtonAction.AddConversationTags,
        isSelected: selectedButtonActions.includes(AutomationButtonAction.AddConversationTags),
      },
    ];

    return items;
  }, [selectedButtonActions]);

  const availableButtonActionMenuItems = useMemo(
    () => buttonActionMenuItems.filter((item) => !item.isSelected),
    [buttonActionMenuItems],
  );

  const onSelectedTagsChange = useCallback(
    (actionEffects: ActionEffect[]) => {
      dispatch(
        updateAutomationPromptButton({
          promptIndex,
          buttonIndex,
          button: { actionEffects },
        }),
      );
    },
    [dispatch, promptIndex, buttonIndex],
  );

  const selectedTags = useMemo(() => {
    return (
      buttonDraft.actionEffects
        ?.filter((effect) => effect.type === ActionEffectType.AddTagsToChannel)
        .flatMap((effect) => (effect as TagConversationActionEffect).tags) || []
    );
  }, [buttonDraft.actionEffects]);

  const getButtonActionChildComponent = useCallback(
    (buttonAction: AutomationButtonAction) => {
      switch (buttonAction) {
        case AutomationButtonAction.SetAppointmentStatus:
          return (
            <Select
              size="md"
              w="231px"
              value={getDraftActionEffectValue(ActionEffectType.ConfirmAppointment) as string | undefined}
              options={buttonActionOptions}
              listProps={{ onSelect: handleSetAppointmentStatusChange }}
            />
          );
        case AutomationButtonAction.SetConversationStatus:
          return (
            <StatusChangeSelect
              size="md"
              buttonWidth="231px"
              showLabel={false}
              promptType={promptType}
              promptIndex={promptIndex}
              buttonIndex={buttonIndex}
              channelStatusChangeId={button.channelStatusChangeId}
            />
          );
        case AutomationButtonAction.AddConversationTags:
          return <ConversationTagMenu selectedTags={selectedTags} onSelectedTagsChange={onSelectedTagsChange} />;
        default:
          return null;
      }
    },
    [
      button.channelStatusChangeId,
      buttonIndex,
      handleSetAppointmentStatusChange,
      promptIndex,
      promptType,
      selectedTags,
      onSelectedTagsChange,
      getDraftActionEffectValue,
    ],
  );

  const buttonTextInputRef = useCallback((el: HTMLInputElement | null): void => {
    if (el) {
      el.focus();
    }
  }, []);

  if (!buttonDraft) {
    return null;
  }

  return (
    <Modal size="xl" isOpen={isOpen} onClose={partial(onClose, false)} isCentered={false}>
      <ModalHeader size="lg">Edit Button</ModalHeader>
      <ModalCloseButton size="sm" />
      <ModalBody pb={2}>
        <VStack spacing={4} align="stretch">
          <EditButtonModalSectionHeader
            title="Button Style"
            description="Change button text and style. Tip: Keep it short, sweet, and don't go overboard with styles."
          />
          <HStack alignItems="flex-start">
            <TextInput
              ref={buttonTextInputRef}
              label="Button Text"
              labelStyle={{
                fontWeight: 'semibold',
              }}
              size="md"
              isInvalid={Boolean(buttonTextErrors?.length)}
              errorText={buttonTextErrors?.map((errorName) => errorDetails[errorName].errorMessage).join(' ')}
              value={buttonDraft.body}
              onChange={handleButtonTextChange}
              placeholder="Button text"
            />
            <Select
              label="Button Style"
              labelStyle={{
                fontWeight: 'semibold',
              }}
              size="md"
              value={buttonDraft.style || AutomationButtonVariant.Secondary}
              options={buttonStyleOptions}
              listProps={{ onSelect: handleButtonStyleChange }}
            />
          </HStack>
          <VStack bg="background.subtle" borderRadius={12} pt={2} pb={3} px={3}>
            <Text variant="subtle">Preview</Text>
            <Button
              variant={buttonDraft.style || AutomationButtonVariant.Secondary}
              h="max-content"
              whiteSpace="break-spaces"
              iconName={buttonDraft.externalUrl !== undefined ? 'externalLink' : undefined}
            >
              {buttonDraft.body}
            </Button>
          </VStack>
          <EditButtonModalSectionHeader
            title="Button Actions"
            description="Make changes to conversations, appointments, and schedule automations to send later. Button actions are optional."
            pt={4}
          />
          <VStack gap={1}>
            {selectedButtonActions.map((buttonAction, index) => {
              return (
                <ButtonActionContainer
                  key={`buttonAction-${index}`}
                  label={automationActionLabels[buttonAction]}
                  remove={(): void => removeButtonAction(buttonAction)}
                >
                  {getButtonActionChildComponent(buttonAction)}
                </ButtonActionContainer>
              );
            })}
          </VStack>
          {availableButtonActionMenuItems.length && (
            <Menu
              buttonProps={{
                text: 'Add Button Action',
                variant: 'ghost',
                size: 'md',
                leftIconName: 'plus',
                showRightIcon: false,
                w: '194px',
                alignSelf: 'flex-start',
              }}
              listProps={{
                menuItems: availableButtonActionMenuItems,
                onSelect: (selectedItem): void => handleButtonActionSelected(selectedItem),
              }}
              closeOnSelect={true}
            />
          )}
          <EditButtonModalSectionHeader
            title="Next Step"
            description="Define what happens next after the button is tapped."
            pt={4}
          />
          <NextStepSelect
            promptIndex={promptIndex}
            buttonIndex={buttonIndex}
            showLabel={false}
            buttonUrlErrors={buttonUrlErrors}
          />
        </VStack>
      </ModalBody>
      <ModalFooterButtons onSubmit={partial(onClose, false)} onCancel={partial(onClose, true)} submitText="Done" />
    </Modal>
  );
};

export default withLazyModal(EditButtonModal);
