import sortBy from 'lodash/sortBy';
import { NormalizedWritebackError, PaymentPimsWritebackLogEntry, PimsWritebackStatus } from 'shared/types/graphql';
import { PaymentMedium, StripePaymentIntent, StripePaymentMethodType } from 'shared/types/graphql';
import { AuxiliaryPimsWritebackStatus } from '../constants';

// If there is any successful writeback, return Success. Else, return latest status.
export const getWritebackStatus = (
  writebackLogEntries: PaymentPimsWritebackLogEntry[],
  isPaymentConfigInvalid?: boolean,
  isIntegratedPayment?: boolean,
): PimsWritebackStatus | AuxiliaryPimsWritebackStatus => {
  if (writebackLogEntries.some((entry) => entry.writebackLog.status === PimsWritebackStatus.Success)) {
    return PimsWritebackStatus.Success;
  }

  const lastLog = sortBy(writebackLogEntries, (entry) => entry.writebackLog.createdAt).pop();
  const normalizedError = lastLog?.writebackLog.normalizedError;
  return normalizedError === NormalizedWritebackError.BitwerxInvalidConfiguration && isPaymentConfigInvalid
    ? AuxiliaryPimsWritebackStatus.Invalid
    : lastLog?.writebackLog.status
    ? lastLog.writebackLog.status
    : isIntegratedPayment
    ? PimsWritebackStatus.Pending
    : AuxiliaryPimsWritebackStatus.Disabled;
};

export const stripePaymentMethodLabels = {
  [StripePaymentMethodType.CardPresent]: 'Terminal',
  [StripePaymentMethodType.CardNotPresent]: 'Digital',
};

export const stripePaymentMediumLabels = {
  [PaymentMedium.StripeTerminalApp]: 'Terminal',
  [PaymentMedium.StripeTerminal]: 'Terminal',
  [PaymentMedium.StripeVirtualTerminal]: 'Digital',
  [PaymentMedium.StripeOnline]: 'Digital',
};

export const getPaymentMethodLabel = (
  intent: Partial<StripePaymentIntent> | null | undefined,
  defaultLabel = 'Pending',
): string => {
  let paymentMethodLabel = defaultLabel;
  if (intent?.paymentMedium) {
    paymentMethodLabel = stripePaymentMediumLabels[intent.paymentMedium];
  } else if (intent?.stripePaymentMethod?.type) {
    paymentMethodLabel = stripePaymentMethodLabels[intent.stripePaymentMethod.type];
  }
  return paymentMethodLabel;
};
