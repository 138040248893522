import React, { useCallback, ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Button, TextInput, Text, Alert, useDisclosure, Box, SortableList, SortableItem } from '@televet/kibble-ui';
import partial from 'lodash-es/partial';
import set from 'lodash-es/set';
import omit from 'lodash-es/omit';
import { AutomationsPromptBlockFieldContainer } from 'pages/Automations/components/Layout';
import { ConversationStatusSelectValue } from 'shared/components/ConversationStatusSelect';
import { useAppDispatch } from 'state/hooks';
import {
  updateAutomationPromptErrors,
  setAutomationPromptButton,
  updateAutomationPromptConfig,
} from 'pages/Automations/state/automationsSlice';

import { ButtonErrorDetail, PromptButtonContainerError } from 'pages/Automations/types/AutomationValidation';
import { useGetFieldErrorText } from 'pages/Automations/hooks/useGetFieldErrorText';
import {
  AutomationRunActionPromptType,
  FullChannelStatusFragment,
  useGetClinicChannelStatusesQuery,
} from 'shared/types/graphql';
import EditButtonModal from './EditButtonModal';
import useClinicUser from 'shared/hooks/useClinicUser';
import ButtonListItem from './ButtonListItem';
import cuid from 'cuid';
import { useNextStepValidation } from 'pages/Automations/hooks/useNextStepValidation';
import {
  ActionEffectType,
  AutomationButtonVariant,
  IMessageButtonOption,
  WorkflowEventActionConfig,
} from '@televet/shared-types/JsonTypes/WorkflowEventActionConfig';

export interface ButtonProps {
  buttonText: string;
  statusChangeId?: string;
  nextStep: string;
  buttonAction: ActionEffectType;
}

interface ButtonsProps {
  promptType: AutomationRunActionPromptType;
  promptIndex: number;
  config: WorkflowEventActionConfig;
  errors: {
    buttonErrors?: ButtonErrorDetail[];
    containerErrors?: PromptButtonContainerError[];
  };
}

export const Buttons = ({
  config,
  promptType,
  promptIndex,
  errors: { buttonErrors, containerErrors },
}: ButtonsProps): JSX.Element => {
  const { currentClinicId } = useClinicUser();
  const { buttons, callToActionBody } = config;
  const dispatch = useAppDispatch();

  const containerErrorText = useGetFieldErrorText(containerErrors);

  const containerHasError = useMemo(() => {
    return Boolean(containerErrors?.length);
  }, [containerErrors]);

  const handleCallToActionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(updateAutomationPromptConfig({ promptIndex, config: { callToActionBody: event.target.value } }));
    },
    [promptIndex, dispatch],
  );

  const {
    isOpen: isEditButtonModalOpen,
    onOpen: onOpenEditButtonModal,
    onClose: onCloseEditButtonModal,
  } = useDisclosure();

  const [draftButtonIndex, setDraftButtonIndex] = useState<number>(0);
  const draftButton = useMemo(() => buttons?.[draftButtonIndex], [buttons, draftButtonIndex]);
  const draftButtonErrors = useMemo(
    () => buttonErrors?.find((error) => error.buttonIndex === draftButtonIndex),
    [buttonErrors, draftButtonIndex],
  );

  // Reference to the button state before the user makes any edits in the modal
  const preEditButton = useRef<IMessageButtonOption | undefined>(draftButton);

  const handleCloseEditButtonModal = useCallback(
    (revertChanges?: boolean): void => {
      if (revertChanges && preEditButton.current) {
        // On modal dismissal,reset button to pre-edit state only when the user clicked "Cancel"
        dispatch(
          setAutomationPromptButton({
            promptIndex,
            buttonIndex: draftButtonIndex,
            button: preEditButton.current,
          }),
        );
      }
      onCloseEditButtonModal();
    },
    [dispatch, draftButtonIndex, onCloseEditButtonModal, promptIndex],
  );

  const handleEditButton = useCallback(
    ({ buttonIndex, currentButton }: { buttonIndex: number; currentButton: IMessageButtonOption }): void => {
      setDraftButtonIndex(buttonIndex);
      preEditButton.current = currentButton;
      onOpenEditButtonModal();
    },
    [onOpenEditButtonModal],
  );

  const handleDeleteButton = useCallback(
    (buttonIndex: number) => {
      const updatedButtons = buttons?.filter((button, index) => buttonIndex !== index);
      dispatch(updateAutomationPromptConfig({ promptIndex, config: { buttons: updatedButtons } }));
    },
    [promptIndex, buttons, dispatch],
  );

  const handleAddButton = useCallback(() => {
    const promptOrder = promptIndex + 1;
    const newButton = {
      body: `Button ${(buttons?.length || 0) + 1} Text`,
      style: AutomationButtonVariant.Secondary,
      entityAction: ActionEffectType.None,
      actionEffects: [],
      workflowEventTriggerActionNumber: promptOrder + 1,
    };
    const updatedButtons = (buttons || []).concat(newButton);
    dispatch(updateAutomationPromptConfig({ promptIndex, config: { buttons: updatedButtons } }));

    if (containerHasError) {
      dispatch(updateAutomationPromptErrors({ promptIndex, prompt: { buttonContainer: [] } }));
    }

    handleEditButton({ buttonIndex: updatedButtons.length - 1, currentButton: newButton });
  }, [promptIndex, buttons, containerHasError, dispatch, handleEditButton]);

  // The Kibble SortableList component requires `id`, and `index` is used to map the button config
  // object to its new index in the change handler
  const sortableButtons = useMemo(
    () => buttons?.map((button, index) => ({ ...button, id: cuid(), index })) || [],
    [buttons],
  );

  const handleButtonOrderChange = (items: typeof sortableButtons): void => {
    if (!buttons) {
      return;
    }
    const newButtons = items.reduce((prev, curr, index) => {
      prev[index] = buttons[curr.index];
      return prev;
    }, [] as IMessageButtonOption[]);
    dispatch(updateAutomationPromptConfig({ promptIndex, config: { buttons: newButtons } }));
  };

  useEffect(() => {
    const promptOrder = promptIndex + 1;

    // If no buttons are present, default first two buttons
    if (!buttons) {
      dispatch(
        updateAutomationPromptConfig({
          promptIndex,
          config: {
            buttons: [
              {
                body: `Button 1 Text`,
                style: AutomationButtonVariant.Secondary,
                channelStatusChangeId: ConversationStatusSelectValue.NoChange,
                entityAction: ActionEffectType.None,
                actionEffects: [],
                workflowEventTriggerActionNumber: promptOrder + 1,
              },
              {
                body: `Button 2 Text`,
                style: AutomationButtonVariant.Secondary,
                channelStatusChangeId: ConversationStatusSelectValue.NoChange,
                entityAction: ActionEffectType.None,
                actionEffects: [],
                workflowEventTriggerActionNumber: promptOrder + 1,
              },
            ],
          },
        }),
      );
    }
  }, [buttons, dispatch, promptIndex]);

  const { data: channelStatusesData } = useGetClinicChannelStatusesQuery({
    variables: { clinicId: currentClinicId || '' },
    skip: !currentClinicId,
  });
  const channelStatusMap = useMemo(
    () =>
      (channelStatusesData?.findManyChannelStatus || []).reduce(
        (prev, curr) => set(prev, curr.id, curr),
        {} as Record<string, FullChannelStatusFragment>,
      ),
    [channelStatusesData],
  );

  useNextStepValidation({
    promptIndex,
    buttonIndex: draftButtonIndex,
    isButtonPrompt: true,
  });

  return (
    <>
      <AutomationsPromptBlockFieldContainer label="Call to Action">
        <TextInput
          placeholder="Please select a button below:"
          value={callToActionBody}
          onChange={handleCallToActionChange}
        />
      </AutomationsPromptBlockFieldContainer>
      <AutomationsPromptBlockFieldContainer label={`Button${buttons && buttons.length > 1 ? 's' : ''}`}>
        {!buttons ||
          (!buttons.length && (
            <Alert
              title="At least one button is required to activate this automation"
              status="info"
              hideCloseButton
              mb={2}
            />
          ))}
        {containerHasError && (
          <Text mt={2} size="xs" variant="danger">
            {containerErrorText}
          </Text>
        )}
        {draftButton && (
          <EditButtonModal
            button={draftButton}
            buttonIndex={draftButtonIndex}
            buttonErrors={draftButtonErrors}
            promptType={promptType}
            promptIndex={promptIndex}
            isOpen={isEditButtonModalOpen}
            onClose={handleCloseEditButtonModal}
          />
        )}
        <Box className="PromptBlock_ButtonsList">
          <SortableList
            items={sortableButtons}
            onChange={handleButtonOrderChange}
            renderItem={(item): JSX.Element => {
              const buttonIndex = item.index;
              const button = omit(item, 'id', 'index');
              const buttonErrorDetail = buttonErrors?.find((error) => error.buttonIndex === buttonIndex);
              const channelStatus = button.channelStatusChangeId
                ? channelStatusMap[button.channelStatusChangeId]
                : undefined;
              return (
                <SortableItem id={item.id}>
                  <ButtonListItem
                    key={buttonIndex}
                    button={button}
                    buttonIndex={buttonIndex}
                    buttonCount={sortableButtons.length}
                    buttonErrorDetail={buttonErrorDetail}
                    channelStatus={channelStatus}
                    promptType={promptType}
                    onEditButton={partial(handleEditButton, { buttonIndex, currentButton: button })}
                    onDeleteButton={partial(handleDeleteButton, buttonIndex)}
                  />
                </SortableItem>
              );
            }}
          />
        </Box>
        <Button variant="ghost" iconName="plus" onClick={handleAddButton}>
          Add Button
        </Button>
      </AutomationsPromptBlockFieldContainer>
    </>
  );
};
