import React, { ReactElement, useContext, useRef, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { FormsContext } from '../../context/FormsContext';
import { Mixpanel } from 'shared/utils/mixpanel';
import WildCardButton from 'shared/components/WildCardButton';
import WildcardTextInput, {
  WildcardInputQuestionTypes,
} from 'pages/Forms/FormsContent/FormsDrafting/WildcardInputs/WildcardTextInput';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Switch } from '@televet/kibble-ui/build/components/Switch';
import { useIntegrationsProvider } from 'shared/providers/IntegrationsProvider';

function DefaultFields(): ReactElement {
  const [showPreview, setShowPreview] = useState<boolean | undefined>(true);
  const { formName, introMessage, dispatch, hasChanges, autoExportEnabled, clinicWidgetRequestTypes } =
    useContext(FormsContext);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [formNameValue, setFormNameValue] = useState<string>(formName);
  const [formIntroMessage, setFormIntroMessage] = useState<string>(introMessage);
  const { primaryIntegrationName, isExportToPimsSupported, isClientIdSupported, isPatientIdSupported } =
    useIntegrationsProvider();
  const isWidgetRequestForm = !!clinicWidgetRequestTypes?.length;

  const handleFormNameChange = (newValue: string): void => {
    setFormNameValue(newValue);
    if (!!timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      dispatch({ type: 'CHANGE_FORM_NAME', payload: newValue });
      Mixpanel.track('Form name entered', { name: newValue });
    }, 500);

    setTimer(newTimer);
  };

  const handleIntroMessageChange = (newValue: string): void => {
    setFormIntroMessage(newValue);
    if (!!timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      dispatch({
        type: 'CHANGE_INTRO_MESSAGE',
        payload: { message: newValue },
      });
      Mixpanel.track('Form introduction message entered', { message: newValue });
    }, 500);

    setTimer(newTimer);
  };

  const handleAddWildCard = (value: string): void => {
    setFormIntroMessage(value);
    setShowPreview(false);
    dispatch({
      type: 'CHANGE_INTRO_MESSAGE',
      payload: { message: value },
    });
    Mixpanel.track('Form introduction message entered', { message: value });
  };
  const handleAutoExportChange = (): void => {
    dispatch({
      type: 'CHANGE_FORM_AUTO_EXPORT_ENABLED',
      payload: !autoExportEnabled,
    });
  };
  useEffect(() => {
    // showPreview is undefined when wildcard menu is opened, so let focus go to menu input then
    if (showPreview === false && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [showPreview]);

  useEffect(() => {
    if (!hasChanges) {
      setFormIntroMessage(introMessage);
      setFormNameValue(formName);
    }
  }, [formName, hasChanges, introMessage]);

  return (
    <>
      <TitleDiv>
        <Title>Form Name</Title>
      </TitleDiv>
      <FormNameInput value={formNameValue} onChange={(e): void => handleFormNameChange(e.target.value)} />
      {isExportToPimsSupported && (
        <>
          <AutoExportContainer>
            <AutoExportControl>
              <Switch onChange={handleAutoExportChange} size="md" isChecked={autoExportEnabled} isFieldInline />
            </AutoExportControl>
            <Text onClick={handleAutoExportChange} style={{ cursor: 'pointer' }}>
              Export Form When Completed
            </Text>
          </AutoExportContainer>
          <AutoExportHelperContainer>
            <Text size="sm" variant="subtle">
              The form will automatically export to {primaryIntegrationName || 'PIMS'} when completed by a client.
            </Text>
          </AutoExportHelperContainer>
        </>
      )}
      {!isWidgetRequestForm && (
        <>
          <TitleDiv>
            <Title>Introduction Message</Title>
          </TitleDiv>
          <WildcardTextInput
            text={formIntroMessage}
            placeholder="Enter your message"
            type={WildcardInputQuestionTypes.Intro}
            showPreview={showPreview}
            textAreaRef={textAreaRef}
            allowFillInTheBlanks={false}
            handleChange={handleIntroMessageChange}
            setShowPreview={setShowPreview}
          />
          <WildCardButton
            textAreaRef={textAreaRef}
            stateHandler={handleAddWildCard}
            setShowPreview={setShowPreview}
            petName
            clientName
            clientFirstName
            clientLastName
            clientPhone
            clientEmail
            petSpecies
            appointmentType
            petPortalLinksForPets
            petId={isPatientIdSupported}
            clientId={isClientIdSupported}
          />
        </>
      )}
    </>
  );
}

export default DefaultFields;

const FormNameInput = styled.input`
  border-radius: 7px;
  border: solid 1px #d2d2d2;
  color: #060a3a;
  font-size: 14px;
  padding: 6px 18px 6px 18px;
  resize: vertical;
  width: 100%;
`;

const Title = styled.label`
  font-size: 14px;
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 8px;
`;
const AutoExportContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;
const AutoExportHelperContainer = styled.div`
  display: flex;
  margin-left: 44px;
`;
const AutoExportControl = styled.div`
  flex: 0;
  padding-right: 12px;
`;
