import { Box, Tooltip } from '@televet/kibble-ui';
import { Menu, MenuItemProps } from '@televet/kibble-ui/build/components/Menu';
import sortBy from 'lodash-es/sortBy';
import React, { useMemo } from 'react';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import { WildcardDisplayNames, WildcardValues } from 'shared/enums/WildcardOptions';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';

interface IWildCardButtonProps {
  textAreaRef?: React.MutableRefObject<HTMLTextAreaElement | HTMLInputElement | null>;
  stateHandler?: Function; // eslint-disable-line @typescript-eslint/ban-types
  setShowPreview?: (value: React.SetStateAction<boolean | undefined>) => void;
  time?: boolean;
  petName?: boolean;
  petId?: boolean;
  clientName?: boolean;
  clientFirstName?: boolean;
  clientLastName?: boolean;
  clientId?: boolean;
  clinicName?: boolean;
  employeeName?: boolean;
  date?: boolean;
  myName?: boolean;
  clientPhone?: boolean;
  clientEmail?: boolean;
  petSpecies?: boolean;
  appointmentType?: boolean;
  petPortalLinksForPets?: boolean;
  petPortalLinksForClients?: boolean;
  fillInTheBlank?: boolean;
  isDisabled?: boolean;
}

const WildCardButton = ({
  textAreaRef,
  stateHandler,
  setShowPreview,
  time,
  petName,
  clientName,
  clientFirstName,
  clientLastName,
  clinicName,
  employeeName,
  date,
  myName,
  clientPhone,
  clientEmail,
  petSpecies,
  appointmentType,
  petPortalLinksForPets,
  petPortalLinksForClients,
  fillInTheBlank,
  petId,
  clientId,
  isDisabled,
}: IWildCardButtonProps): JSX.Element => {
  const { isFeatureEnabled } = useFeatureFlag();
  const hasCareEnabled = useMemo(() => {
    return isFeatureEnabled(FeatureFlagName.CarePlans);
  }, [isFeatureEnabled]);

  const allowedWildCardOptions = useMemo(() => {
    const newArray: MenuItemProps[] = [];
    if (time) {
      newArray.push({ label: WildcardDisplayNames.Time, value: WildcardValues.Time });
    }
    if (petName) {
      newArray.push({ label: WildcardDisplayNames.PetName, value: WildcardValues.PetName });
    }
    if (clientName) {
      newArray.push({ label: WildcardDisplayNames.ClientName, value: WildcardValues.ClientName });
    }
    if (clientFirstName) {
      newArray.push({ label: WildcardDisplayNames.ClientFirstName, value: WildcardValues.ClientFirstName });
    }
    if (clientLastName) {
      newArray.push({ label: WildcardDisplayNames.ClientLastName, value: WildcardValues.ClientLastName });
    }
    if (clinicName) {
      newArray.push({ label: WildcardDisplayNames.ClinicName, value: WildcardValues.ClinicName });
    }
    if (employeeName) {
      newArray.push({ label: WildcardDisplayNames.EmployeeName, value: WildcardValues.EmployeeName });
    }
    if (date) {
      newArray.push({ label: WildcardDisplayNames.Date, value: WildcardValues.Date });
    }
    if (myName) {
      newArray.push({ label: WildcardDisplayNames.MyName, value: WildcardValues.MyName });
    }
    if (clientPhone) {
      newArray.push({ label: WildcardDisplayNames.ClientPhone, value: WildcardValues.ClientPhone });
    }
    if (clientEmail) {
      newArray.push({ label: WildcardDisplayNames.ClientEmail, value: WildcardValues.ClientEmail });
    }
    if (petSpecies) {
      newArray.push({ label: WildcardDisplayNames.PetSpecies, value: WildcardValues.PetSpecies });
    }
    if (appointmentType) {
      newArray.push({ label: WildcardDisplayNames.AppointmentType, value: WildcardValues.AppointmentType });
    }
    if (hasCareEnabled) {
      if (petPortalLinksForClients) {
        newArray.push({
          label: WildcardDisplayNames.PetPortalLinksForClients,
          value: WildcardValues.PetPortalLinksForClients,
        });
      }
      if (petPortalLinksForPets) {
        newArray.push({
          label: WildcardDisplayNames.PetPortalLinksForPets,
          value: WildcardValues.PetPortalLinksForPets,
        });
      }
    }
    if (fillInTheBlank) {
      newArray.push({ label: WildcardDisplayNames.FillInTheBlank, value: WildcardValues.FillInTheBlank });
    }
    if (petId) {
      newArray.push({ label: WildcardDisplayNames.PetPimsId, value: WildcardValues.PetPimsId });
    }
    if (clientId) {
      newArray.push({ label: WildcardDisplayNames.ClientPimsId, value: WildcardValues.ClientPimsId });
    }
    return sortBy(newArray, (e) => e.label);
  }, [
    time,
    petName,
    clientName,
    clientFirstName,
    clientLastName,
    clinicName,
    employeeName,
    date,
    myName,
    clientPhone,
    clientEmail,
    petSpecies,
    appointmentType,
    petPortalLinksForClients,
    hasCareEnabled,
    petPortalLinksForPets,
    fillInTheBlank,
    petId,
    clientId,
  ]);

  const insertTextInBetween = (textBody: string, textInsert: string, start: number, end: number): string => {
    const result = textBody.slice(0, start) + textInsert + textBody.slice(end);
    return result;
  };

  const handleOnMenuShown = (): void => {
    if (setShowPreview) {
      setShowPreview(undefined);
    }
  };

  const handleClickOutside = (): void => {
    if (setShowPreview) {
      setShowPreview(true);
    }
  };

  const onSelectWildCard = (option: MenuItemProps): void => {
    if (!textAreaRef || !option.value) return;

    const currentValue = textAreaRef?.current?.value || '';
    const selectionStart = textAreaRef?.current?.selectionStart || 0;
    const selectionEnd = textAreaRef?.current?.selectionEnd || 0;
    const newValue = insertTextInBetween(currentValue, option.value, selectionStart, selectionEnd);
    if (textAreaRef.current) {
      textAreaRef.current.value = newValue;
      textAreaRef.current.selectionStart = selectionStart + option.value.length;
      textAreaRef.current.selectionEnd = selectionStart + option.value.length;
    }
    if (stateHandler) {
      stateHandler(newValue);
    }
    textAreaRef.current?.focus();
  };

  return (
    <Tooltip
      isDisabled={!isDisabled}
      label="Wildcard tags are disabled because they are not supported in website widget requests."
    >
      <Box w="max-content">
        <Menu
          buttonProps={{
            variant: 'tertiary',
            text: 'Insert Tag',
            size: 'sm',
            disabled: isDisabled,
          }}
          listProps={{
            menuItems: allowedWildCardOptions,
            onSelect: onSelectWildCard,
            shouldRetainSelection: false,
          }}
          containerProps={{
            onOpen: handleOnMenuShown,
            onClose: handleClickOutside,
          }}
        />
      </Box>
    </Tooltip>
  );
};

export default WildCardButton;
