import { Button } from '@televet/kibble-ui/build/components/Button';
import { NotificationCounter } from '@televet/kibble-ui/build/components/NotificationCounter';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Box, HStack, VStack } from '@televet/kibble-ui/build/chakra';
import React, { useCallback, useMemo } from 'react';
import ChannelStatusSelect from 'shared/components/ChannelStatusSelect/ChannelStatusSelect';
import { DragLayerMonitor, useDrag } from 'react-dnd';
import { ItemTypes } from 'shared/providers/PopoverWindowProvider/enums/ItemTypes';
import {
  BoardFilterView,
  BoardPetParentFragment,
  ChannelListChannelFragment,
  ChannelListChannelStatusFragment,
  GetPaginatedAutomationRunsQuery,
  PetAvatarFragment,
} from 'shared/types/graphql';
import { Placement } from 'shared/enums';
import { replaceParam, RouteDefinitions } from 'routes';
import { useHistory } from 'react-router-dom';
import ConversationCardBody from './ConversationCardBody';
import AppointmentCardBody from './AppointmentCardBody';
import AutomationCardBody from './AutomationCardBody';
import { AvatarGroup, PetParentAvatar } from 'shared/components/Avatars';
import { Appointment } from 'pages/Board';
import { getClinicPetParentDisplayName } from 'shared/utils';
import { openChannelOverlay } from 'pages/Conversations/state/conversationsSlice';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import { useAppDispatch } from 'state/hooks';
import { useResolutionProvider } from 'shared/providers/ResolutionProvider';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';
import useGA from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';
import { Avatar } from '@televet/kibble-ui/build/components/Avatar';

interface DefaultBoardCardProps {
  cardType: BoardFilterView;
  pets: PetAvatarFragment[] | undefined;
  petParents: BoardPetParentFragment[] | undefined;
  channelStatus?: ChannelListChannelStatusFragment | null;
  channelId?: string | null;
  hasUnreadMessages?: boolean;
  channelUpdatedAtDate: string;
  lastMessageCreatedAtDate: string;
}

interface AppointmentCardProps extends DefaultBoardCardProps {
  cardType: BoardFilterView.Appointments;
  channelData?: never;
  appointmentData: Partial<Appointment>;
  onEditAppointment: (appointment: Partial<Appointment>) => void;
  automationRunData?: never;
}
interface AutomationCardProps extends DefaultBoardCardProps {
  cardType: BoardFilterView.Automations;
  channelData?: never;
  appointmentData?: Partial<Appointment> | null;
  onEditAppointment: (appointment: Partial<Appointment>) => void;
  automationRunData: NonNullable<GetPaginatedAutomationRunsQuery['automationRuns']>[0];
}
interface ConversationCardProps extends DefaultBoardCardProps {
  cardType: BoardFilterView.Conversations;
  channelData: ChannelListChannelFragment;
  onEditAppointment?: never;
  appointmentData?: never;
  automationRunData?: never;
}

type BoardCardProps = ConversationCardProps | AutomationCardProps | AppointmentCardProps;

const BoardCard = ({
  cardType,
  hasUnreadMessages = false,
  channelStatus,
  channelData,
  channelId,
  petParents,
  pets,
  appointmentData,
  onEditAppointment,
  automationRunData,
  channelUpdatedAtDate,
  lastMessageCreatedAtDate,
}: BoardCardProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isMobile } = useResolutionProvider();
  const { isFeatureEnabled } = useFeatureFlag();
  const isOverlayEnabled = useMemo(() => !isFeatureEnabled(FeatureFlagName.NoChannelOverlay), [isFeatureEnabled]);

  const [{ isDragging }, drag] = useDrag({
    item: {
      type:
        cardType === BoardFilterView.Conversations ? ItemTypes.ConversationBoardCard : ItemTypes.AppointmentBoardCard,
      channelId,
    },
    collect: (monitor: DragLayerMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const petParentDisplayNames = useMemo(() => {
    return petParents
      ?.map(({ creationSource, firstName, lastName, phoneNumbers }) =>
        getClinicPetParentDisplayName({
          firstName,
          lastName,
          creationSource,
          phoneNumbers,
        }),
      )
      .join(', ');
  }, [petParents]);

  const openEditAppointmentModal = useCallback(() => {
    if (!appointmentData || !onEditAppointment) return;
    onEditAppointment(appointmentData);
  }, [onEditAppointment, appointmentData]);

  const { gaTrack } = useGA();

  const navigateToConversation = useCallback(() => {
    if (!isMobile && isOverlayEnabled) {
      gaTrack(GA4Events.BOARD_CONVERSATION_OVERLAY);
      dispatch(openChannelOverlay({ channelId: channelId || '' }));
    } else {
      history.push(`${replaceParam(RouteDefinitions.Conversations, ':channelId', channelId || '')}`);
    }
  }, [channelId, dispatch, gaTrack, history, isMobile, isOverlayEnabled]);

  const sortByLastMessageEnabled = useMemo(
    () => isFeatureEnabled(FeatureFlagName.SortConversationByLastMessage) && lastMessageCreatedAtDate,
    [lastMessageCreatedAtDate, isFeatureEnabled],
  );

  return (
    // TODO: convert this to Card when it can take the drag ref
    <Box
      borderRadius="md"
      bg="background.default"
      borderWidth="1px"
      p={2}
      ref={!channelId || cardType === BoardFilterView.Automations ? null : drag}
      opacity={isDragging ? 0.5 : 1}
      w="100%"
      h="max-content"
      className="Board__Card"
      data-testid="channel-list-item"
      {...(isOverlayEnabled &&
        cardType === BoardFilterView.Conversations && {
          cursor: 'pointer',
          onClick: navigateToConversation,
          _hover: { borderColor: 'transparent', boxShadow: '0 0 0 3px var(--chakra-colors-border-default)' },
        })}
    >
      <HStack className="Board__Card-Header" justify="space-between" align="flex-start">
        <HStack className="Board__Card-Header--Left" justify="space-between" mb={2}>
          {!!petParents?.length ? (
            <AvatarGroup petParentNames={petParentDisplayNames} firstPetParentId={petParents[0]?.id} max={1}>
              {petParents.map((petParent, index) => (
                <PetParentAvatar
                  className="Board__CardHeader-Avatar"
                  key={petParent.id}
                  clinicPetParent={petParent}
                  isGrouped
                  index={index}
                />
              ))}
            </AvatarGroup>
          ) : (
            <Avatar />
          )}
          <VStack className="Board__CardHeader-Names" justify="center" align="left" spacing="0">
            <Heading className="Board__CardHeader--PetParent" size="sm" isTruncated>
              {petParentDisplayNames || 'No Client'}
            </Heading>
            {!!pets?.length ? (
              <Text className="Board__CardHeader--Pet" as="p" size="sm" variant="subtle" isTruncated>
                {pets.map(({ name }) => name).join(', ')}
              </Text>
            ) : (
              <Text className="ChannelListItem__HeaderNames--Pet" as="p" size="sm" variant="subtle" isTruncated>
                No Patient
              </Text>
            )}
          </VStack>
        </HStack>
        <HStack className="Board__Card-Header--Right" justifySelf="flex-end">
          {hasUnreadMessages && <NotificationCounter size="xs" />}
          {cardType === BoardFilterView.Conversations && (
            <TimeStamp date={sortByLastMessageEnabled ? lastMessageCreatedAtDate : channelUpdatedAtDate} />
          )}

          {channelStatus && channelId && (
            <ChannelStatusSelect
              currentStatus={channelStatus as ChannelListChannelStatusFragment}
              channelId={channelId || ''}
              placement={Placement.BottomEnd}
              maxW="100px"
            />
          )}
        </HStack>
      </HStack>
      <Box my={4} className="Board__Card-Body">
        {cardType === BoardFilterView.Appointments && appointmentData && (
          <AppointmentCardBody appointmentData={appointmentData} />
        )}
        {cardType === BoardFilterView.Automations && automationRunData?.id && (
          <AutomationCardBody automationRunData={automationRunData} />
        )}
        {cardType === BoardFilterView.Conversations && channelData && (
          <ConversationCardBody messageContent={channelData.lastMessage?.body} />
        )}
      </Box>

      <HStack mt={2} className="Board__Card-AppointmentsFooter">
        {(cardType !== BoardFilterView.Conversations || !isOverlayEnabled) && channelId && (
          <Button isFullWidth size="sm" variant="tertiary" onClick={navigateToConversation}>
            View Conversation
          </Button>
        )}
        {cardType !== BoardFilterView.Conversations && (
          <Button isFullWidth size="sm" variant="tertiary" onClick={openEditAppointmentModal}>
            View Appointment
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default BoardCard;

interface TimeStampProps {
  date: string;
}

const TimeStamp = ({ date }: TimeStampProps): JSX.Element => {
  const _date = date ? new Date(date) : new Date();
  const stamp = format(_date, isToday(_date) ? 'h:mma' : 'M/d/yy');

  return (
    <Text className="ChannelListItem__HeaderTimestamp" variant="subtle" size="xs" textTransform="lowercase">
      <Tooltip titleCaseLabel={false} label={`Last updated ${format(_date || '', "cccc',' PP 'at' p")}`}>
        {stamp}
      </Tooltip>
    </Text>
  );
};
