import { Box } from '@televet/kibble-ui/build/chakra';
import { Text } from '@televet/kibble-ui/build/components/Text';
import React, { useMemo } from 'react';
import { EmailCampaign, EmailCampaignSegmentationType } from 'shared/types/graphql';
import ConditionalSegmentSummary from './ConditionalSegmentSummary';
import RecipientCount from './RecipientCount';

type SegmentSummaryProps = {
  loading: boolean;
  recipientCount?: number | null;
  campaign?: EmailCampaign | null;
  additionalSegmentProps?: Record<string, unknown>;
};

export const SegmentSummary = ({
  campaign,
  loading,
  recipientCount,
  additionalSegmentProps,
}: SegmentSummaryProps): JSX.Element => {
  const hasConditionalSegments = useMemo(
    () => !!campaign?.segmentArgs && !!campaign?.segmentArgs?.conditionalSegments,
    [campaign],
  );

  const { title, summary } = useMemo(() => {
    const isCustomList =
      campaign?.segmentationType === EmailCampaignSegmentationType.CustomList && campaign?.customList;
    const isSegmented =
      campaign?.segmentationType === EmailCampaignSegmentationType.ClinicPetParentPrismaWhere && hasConditionalSegments;

    if (isCustomList) {
      return {
        title: 'Custom List',
        summary: (
          <Text fontWeight="normal" color="text.subtle">
            {campaign?.customList?.fileName}
          </Text>
        ),
      };
    }

    if (isSegmented) {
      return {
        title: 'Filter All Clients',
        summary: (
          <Box ml="2" mb="2" color="text.subtle">
            <ConditionalSegmentSummary
              conditionalSegments={campaign?.segmentArgs}
              additionalSegmentProps={additionalSegmentProps}
            />
          </Box>
        ),
      };
    }

    return {
      title: 'Active Clients',
      summary: (
        <Text fontWeight="normal" color="text.subtle">
          Active clients have had an appointment in the last 18 months, or have an appointment scheduled in the future.
        </Text>
      ),
    };
  }, [
    additionalSegmentProps,
    campaign?.customList,
    campaign?.segmentArgs,
    campaign?.segmentationType,
    hasConditionalSegments,
  ]);

  return (
    <Box>
      <Text as="p" fontWeight="bold">
        {title}{' '}
        <Text fontWeight="normal">
          <RecipientCount loading={loading} recipientCount={recipientCount} />
        </Text>
      </Text>
      {summary}
    </Box>
  );
};
