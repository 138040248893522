import React, { useMemo, useCallback } from 'react';
import {
  Icon,
  Select,
  useDisclosure,
  VStack,
  HStack,
  Text,
  Tooltip,
  Alert,
  Center,
  Box,
  SelectOptionProps,
  TextInput,
} from '@televet/kibble-ui';
import {
  AutomationRunActionPromptType,
  useGetAutomationsListQuery,
  useGetGlobalAutomationsListQuery,
  WorkflowEvent,
  WorkflowEventTriggerType,
} from 'shared/types/graphql';
import { AddNewAutomationModal } from 'pages/Automations/components/Modals';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  updateAutomationPromptButtonErrors,
  updateAutomationPromptErrors,
  updateAutomationPromptButton,
  updateAutomationPromptNextStep,
  updateAutomationPromptFollowupStep,
  updateAutomationPromptConfig,
} from 'pages/Automations/state/automationsSlice';
import partial from 'lodash-es/partial';
import useClinicUser from 'shared/hooks/useClinicUser';
import { childAutomationEventTypes } from 'pages/Automations/state/utils/draft';
import { DELETED, errorDetails, PromptButtonExternalUrlError } from 'pages/Automations/types/AutomationValidation';
import { GraphQLFetchPolicies } from 'shared/enums';
import { NextStepSelectValue } from 'pages/Automations/types/NextStepSelectValue';
import { useNextStepValidation } from 'pages/Automations/hooks/useNextStepValidation';
import { useGetNextStepDisplayProps } from 'pages/Automations/hooks/useGetNextStepDisplayProps';
import { getStepValueFromConfig } from 'pages/Automations/utils/getStepValueFromConfig';
import { FeatureFlagName } from 'shared/enums';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { isConditionalChildAutomation } from 'pages/Automations/utils/isConditionalChildAutomation';
import { IMessageButtonOption } from '@televet/shared-types';

interface NextStepSelectProps {
  promptIndex: number;
  buttonIndex?: number;
  showLabel?: boolean;
  buttonUrlErrors?: PromptButtonExternalUrlError[];
}

export const NextStepSelect = ({
  promptIndex,
  buttonIndex,
  showLabel = true,
  buttonUrlErrors,
}: NextStepSelectProps): JSX.Element => {
  const { isFeatureEnabled } = useFeatureFlag();
  const isGlobalEditor = useAppSelector((state) => state.automations.isGlobalEditor);
  const prompt = useAppSelector((state) => state.automations.automationDraft.prompts[promptIndex]);
  const { promptType } = prompt;

  const isStatementPrompt = useMemo(() => {
    return promptType === AutomationRunActionPromptType.Statement;
  }, [promptType]);

  const isButtonPrompt = useMemo(() => {
    return promptType === AutomationRunActionPromptType.Buttons;
  }, [promptType]);

  const dispatch = useAppDispatch();
  const addNewAutomationModal = useDisclosure();
  const { currentClinicId } = useClinicUser();

  const { fieldNextStepIsInvalid, nextStepErrorText, fieldFollowupStepIsInvalid, followupStepErrorText } =
    useNextStepValidation({
      promptIndex,
      buttonIndex,
      isButtonPrompt,
      skipValidation: isButtonPrompt, // For button prompts, validation is already triggered from the Buttons prompt field component
    });

  const button = useMemo(
    () => (typeof buttonIndex === 'number' ? prompt.config.buttons?.[buttonIndex] : undefined),
    [buttonIndex, prompt],
  );
  const sendButtonMessage = button?.doNothing;

  const nextStepTriggerActionNumber =
    isButtonPrompt && button ? button.workflowEventTriggerActionNumber : prompt.triggerActionNumber;

  const nextStepTriggerWorkflowId =
    isButtonPrompt && button
      ? button.nextWorkflowEventTriggerSettingId
      : prompt.config.nextWorkflowEventTriggerSettingId;

  const followupStepTriggerActionNumber =
    isButtonPrompt && button
      ? button.conditionalNextStepConfig?.fallbackNextActionNumber
      : prompt.config.conditionalNextStepConfig?.fallbackNextActionNumber;

  const followupStepTriggerWorkflowId =
    isButtonPrompt && button
      ? button.conditionalNextStepConfig?.fallbackNextWorkflowEventSettingId
      : prompt.config.conditionalNextStepConfig?.fallbackNextWorkflowEventSettingId;

  const externalUrl = !isButtonPrompt || button?.externalUrl === undefined ? undefined : button.externalUrl;

  const eventTypes = useMemo(() => {
    const types = [...childAutomationEventTypes];

    // Add for H2H automations
    if (isGlobalEditor) {
      types.push(WorkflowEvent.InvoiceLineItemCreated);
    }

    return types;
  }, [isGlobalEditor]);

  // TODO: Create seperate lazy query for this list and only call when the menu is opened (see comment here: https://github.com/TeleVet/clinic-web/pull/1663/files/512d1fb1fe2d97be26274238902ed0dd2889505e#r1060984205)
  const { data: childAutomationsData } = useGetAutomationsListQuery({
    variables: {
      clinicId: currentClinicId || '',
      triggerType: WorkflowEventTriggerType.Manual,
      inEventType: eventTypes as WorkflowEvent[],
    },
    skip: !currentClinicId || isGlobalEditor,
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
  });

  const { data: globalChildAutomationsData } = useGetGlobalAutomationsListQuery({
    variables: {
      triggerType: WorkflowEventTriggerType.Manual,
      inEventType: eventTypes as WorkflowEvent[],
    },
    skip: !isGlobalEditor,
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
  });

  const childAutomations = useMemo(
    () =>
      isGlobalEditor
        ? globalChildAutomationsData?.findManyWorkflowEventSetting || []
        : childAutomationsData?.findManyWorkflowEventSetting || [],
    [childAutomationsData, globalChildAutomationsData, isGlobalEditor],
  );

  const nextStepValue = useMemo(
    () =>
      getStepValueFromConfig({
        actionNumber: nextStepTriggerActionNumber,
        workflowId: nextStepTriggerWorkflowId,
        isStatementPrompt,
        externalUrl,
        sendButtonMessage,
      }),
    [externalUrl, nextStepTriggerActionNumber, nextStepTriggerWorkflowId, isStatementPrompt, sendButtonMessage],
  );

  const followupStepValue = useMemo(
    () =>
      getStepValueFromConfig({
        // for external link buttons, we display the next step as the followup step
        actionNumber: externalUrl === undefined ? followupStepTriggerActionNumber : nextStepTriggerActionNumber,
        workflowId: externalUrl === undefined ? followupStepTriggerWorkflowId : nextStepTriggerWorkflowId,
        isStatementPrompt,
        externalUrl: undefined,
      }),
    [
      externalUrl,
      followupStepTriggerActionNumber,
      followupStepTriggerWorkflowId,
      isStatementPrompt,
      nextStepTriggerActionNumber,
      nextStepTriggerWorkflowId,
    ],
  );

  const isLinkButton = useMemo(() => nextStepValue === NextStepSelectValue.OpenLink, [nextStepValue]);

  const handleSelect = useCallback(
    ({
      value,
      isConditionalChild,
      clearValidation = true,
      isFollowupStep,
    }: {
      value: NextStepSelectValue | string;
      isConditionalChild?: boolean;
      clearValidation?: boolean;
      isFollowupStep?: boolean;
    }): void => {
      let triggerActionNumber: number | null = null;
      let triggerWorkflowId: string | null = null;
      let url: string | undefined = undefined;

      if (!value) {
        return;
      }

      if (value === NextStepSelectValue.NewAutomation) {
        addNewAutomationModal.onOpen();
        return;
      }

      const isChildAutomationSelected = !Object.values(NextStepSelectValue).includes(value as NextStepSelectValue);
      if (isChildAutomationSelected) {
        triggerWorkflowId = value;
        triggerActionNumber = null;

        // don't reset externalUrl if changing followup for external link button
        if (isFollowupStep && !isConditionalChild) {
          url = externalUrl;
        }
      } else if (value === NextStepSelectValue.NextPrompt) {
        triggerWorkflowId = null;
        triggerActionNumber = promptIndex + 2;

        // don't reset externalUrl if changing followup for external link button
        if (isFollowupStep && !isConditionalChild) {
          url = externalUrl;
        }
      } else if (value === NextStepSelectValue.OpenLink) {
        triggerWorkflowId = null;
        triggerActionNumber = null;
        url = '';
      } else if (value === NextStepSelectValue.EndAutomation) {
        // don't reset externalUrl if changing followup for external link button
        if (isFollowupStep && !isConditionalChild) {
          url = externalUrl;
        }
      }

      if (isButtonPrompt && buttonIndex !== undefined) {
        const buttonConfig: Partial<IMessageButtonOption> =
          isFollowupStep && !isLinkButton
            ? {
                conditionalNextStepConfig: {
                  fallbackNextActionNumber: triggerActionNumber,
                  fallbackNextWorkflowEventSettingId: triggerWorkflowId,
                },
              }
            : {
                workflowEventTriggerActionNumber: triggerActionNumber,
                nextWorkflowEventTriggerSettingId: triggerWorkflowId,
                externalUrl: url,
                doNothing: undefined, // This field is deprecated, so we ensure the user can't set it in the new builder
                conditionalNextStepConfig: isConditionalChild
                  ? {
                      fallbackNextActionNumber: nextStepTriggerActionNumber ?? null,
                      fallbackNextWorkflowEventSettingId: nextStepTriggerWorkflowId ?? null,
                    }
                  : undefined,
              };
        dispatch(
          updateAutomationPromptButton({
            promptIndex,
            buttonIndex,
            button: buttonConfig,
          }),
        );

        if ((fieldNextStepIsInvalid || fieldFollowupStepIsInvalid) && clearValidation) {
          dispatch(
            updateAutomationPromptButtonErrors({
              promptIndex,
              buttonIndex,
              button: { [isFollowupStep ? 'followupStep' : 'nextStep']: [] },
            }),
          );
        }
      } else {
        if (isFollowupStep) {
          // For external link buttons, followup step actually acts as next step
          // since the immediate action just opens the link in another tab while
          // the AutomationRun continues with the followup step right away
          if (isLinkButton) {
            dispatch(
              updateAutomationPromptNextStep({
                promptIndex,
                triggerActionNumber,
                triggerWorkflowId,
              }),
            );
          } else {
            dispatch(
              updateAutomationPromptFollowupStep({
                promptIndex,
                fallbackNextActionNumber: triggerActionNumber,
                fallbackNextWorkflowEventSettingId: triggerWorkflowId,
              }),
            );
          }
        } else {
          dispatch(
            updateAutomationPromptNextStep({
              promptIndex,
              triggerActionNumber,
              triggerWorkflowId,
            }),
          );
          if (isConditionalChild) {
            dispatch(
              updateAutomationPromptFollowupStep({
                promptIndex,
                // TODO (future PR): Address case when switching from one conditional child to another, though this is not possible today
                fallbackNextActionNumber: nextStepTriggerActionNumber,
                fallbackNextWorkflowEventSettingId: nextStepTriggerWorkflowId,
              }),
            );
          } else {
            dispatch(updateAutomationPromptConfig({ promptIndex, config: { conditionalNextStepConfig: undefined } }));
          }
        }

        if ((fieldNextStepIsInvalid || fieldFollowupStepIsInvalid) && clearValidation) {
          dispatch(
            updateAutomationPromptErrors({
              promptIndex,
              prompt: { [isFollowupStep ? 'followupStep' : 'nextStep']: [] },
            }),
          );
        }
      }
    },
    [
      promptIndex,
      fieldNextStepIsInvalid,
      fieldFollowupStepIsInvalid,
      dispatch,
      buttonIndex,
      isButtonPrompt,
      nextStepTriggerActionNumber,
      nextStepTriggerWorkflowId,
      addNewAutomationModal,
      isLinkButton,
      externalUrl,
    ],
  );

  const hasUrlError = useMemo(() => !!buttonUrlErrors?.length, [buttonUrlErrors]);

  const urlErrorText = useMemo(
    () => buttonUrlErrors?.map((errorName) => errorDetails[errorName].errorMessage).join(' '),
    [buttonUrlErrors],
  );

  const handleExternalUrlChange = useCallback(
    (url: string) => {
      if (!isButtonPrompt || buttonIndex === undefined) {
        return;
      }

      if (hasUrlError) {
        dispatch(
          updateAutomationPromptButtonErrors({
            promptIndex,
            buttonIndex,
            button: { externalUrl: [] },
          }),
        );
      }

      dispatch(
        updateAutomationPromptButton({
          promptIndex,
          buttonIndex,
          button: { externalUrl: url },
        }),
      );
    },
    [isButtonPrompt, buttonIndex, hasUrlError, dispatch, promptIndex],
  );

  // Show the follow-up step input if the selected automation is set to "Evaluate" and has at least one Condition
  const showFollowupStep = useMemo(() => {
    const nextStepAutomation = childAutomations?.find(({ id }) => id === nextStepTriggerWorkflowId);
    return isConditionalChildAutomation(nextStepAutomation);
  }, [childAutomations, nextStepTriggerWorkflowId]);

  const followupStepTooltipLabel = useMemo(() => {
    return isLinkButton
      ? 'Follow-up Step will occur after the client opens the website link and will be visible when the client returns to the Otto app.'
      : 'Follow-up Step will occur after the conditional child automation (whether a client meets the requirements of the conditional child automation or not).';
  }, [isLinkButton]);

  const { options: nextStepDisplayOptions, isLoading: isLoadingNextStepOptions } = useGetNextStepDisplayProps({
    promptType,
  });

  const baseOptions: SelectOptionProps[] = useMemo(() => {
    return nextStepDisplayOptions
      .filter(
        (option) =>
          !(
            option.isConditionalChild && !isFeatureEnabled(FeatureFlagName.AutomationsConditionalChildOptionVisibility)
          ) && !(option.isConditionalChild && !isButtonPrompt),
      )
      .filter((option) => isButtonPrompt || option.value !== NextStepSelectValue.OpenLink)
      .map((option): SelectOptionProps => {
        return {
          ...option,
          leftElement: option.iconName && <Icon size="sm" name={option.iconName} />,
          onSelect: partial(handleSelect, { value: option.value, isConditionalChild: option.isConditionalChild }),
        };
      });
  }, [nextStepDisplayOptions, isButtonPrompt, isFeatureEnabled, handleSelect]);

  const nextStepOptions: SelectOptionProps[] = useMemo(() => {
    const isAutomationDeleted = !baseOptions.some(({ value }) => value === nextStepValue);
    if (isAutomationDeleted && !isLoadingNextStepOptions) {
      if (nextStepValue !== DELETED) {
        handleSelect({ value: DELETED, clearValidation: false });
      }
      return baseOptions.concat({
        label: '[Deleted Automation]',
        value: DELETED,
        isDisabled: true,
        leftElement: <Icon name="chainLink" size="sm" />,
      });
    }
    return baseOptions;
  }, [nextStepValue, isLoadingNextStepOptions, baseOptions, handleSelect]);

  const followupStepOptions: SelectOptionProps[] = useMemo(() => {
    const options = baseOptions
      .filter((option) => {
        if (!isLinkButton) {
          return [NextStepSelectValue.NextPrompt, NextStepSelectValue.EndAutomation].includes(
            option.value as NextStepSelectValue,
          );
        }

        return !nextStepDisplayOptions.find((nextOption) => nextOption.value === option.value)?.isConditionalChild;
      })
      .filter((option) => option.value !== NextStepSelectValue.OpenLink)
      .map((option) => {
        return {
          ...option,
          onSelect: partial(handleSelect, { value: option.value || '', isFollowupStep: true }),
        };
      });

    const isAutomationDeleted = !baseOptions.some(({ value }) => value === followupStepValue);
    if (isAutomationDeleted && !isLoadingNextStepOptions) {
      if (followupStepValue !== DELETED) {
        handleSelect({ value: DELETED, clearValidation: false, isFollowupStep: true });
      }
      return options.concat({
        label: '[Deleted Automation]',
        value: DELETED,
        isDisabled: true,
        leftElement: <Icon name="chainLink" size="sm" />,
        onSelect: () => {
          null;
        },
      });
    }

    return options;
  }, [baseOptions, isLoadingNextStepOptions, isLinkButton, nextStepDisplayOptions, handleSelect, followupStepValue]);

  return (
    <>
      <VStack spacing={4} align="stretch">
        <Box>
          <Select
            className="Automations__Select-NextStep"
            value={nextStepValue}
            label={showLabel ? 'Next Step' : undefined}
            errorText={nextStepErrorText}
            isInvalid={fieldNextStepIsInvalid}
            options={nextStepOptions}
            listProps={{
              isSearchable: !isStatementPrompt,
              maxW: '276px',
              zIndex: '3',
            }}
          />
          {showFollowupStep && (
            <Alert
              mt={2}
              size="sm"
              status="info"
              hideIcon={true}
              hideCloseButton={true}
              description="You've selected a conditional automation. Clients will only see it if they meet the conditions set within that automation."
            />
          )}
        </Box>
        {isLinkButton && (
          <TextInput
            className="Automations__Select-ExternalUrl"
            value={externalUrl}
            label="External Website Link"
            labelStyle={{
              fontWeight: 'semibold',
            }}
            onChange={(e): void => handleExternalUrlChange(e.currentTarget.value)}
            isInvalid={hasUrlError}
            errorText={urlErrorText}
          />
        )}
        {(showFollowupStep || isLinkButton) && (
          <Select
            className="Automations__Select-FollowUpStep"
            value={followupStepValue}
            label={
              <HStack spacing={1}>
                <Text fontWeight="semibold">Follow-up Step</Text>
                <Tooltip label={followupStepTooltipLabel} placement="right" titleCaseLabel={false}>
                  <Center>
                    <Icon name="infoCircle" size="xs" />
                  </Center>
                </Tooltip>
              </HStack>
            }
            errorText={followupStepErrorText}
            isInvalid={fieldFollowupStepIsInvalid}
            options={followupStepOptions}
            listProps={{
              isSearchable: !isStatementPrompt,
              maxW: '276px',
              zIndex: '2',
            }}
          />
        )}
        {isLinkButton && (
          <Alert
            mt={2}
            status="info"
            hideIcon={true}
            hideCloseButton={true}
            description="When clicking this button, the client will exit the automation and be taken to the website provided above. If the Follow-up Step doesn’t end the automation, they will have to navigate back to the Otto app to complete the automation."
            descriptionProps={{
              fontSize: 'sm',
            }}
          />
        )}
      </VStack>
      <AddNewAutomationModal
        handleUpdateSelection={handleSelect}
        isOpen={addNewAutomationModal.isOpen}
        onClose={addNewAutomationModal.onClose}
      />
    </>
  );
};
