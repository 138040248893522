import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  SidePanelParentPetDataFragment,
  useGetClinicUsersQuery,
  useGetClinicCarePlansQuery,
  ClinicCarePlanFragment,
  CareSubscriptionFragment,
  useCreateEnrollmentSupportEventMutation,
  ManageMembershipRequestType,
  useUpdateCarePlanEnrollmentMutation,
  PaymentSchedule,
  SupportEventStatus,
  useTransferCareSubscriptionMutation,
  usePerformCarePlanEnrollmentUpgradeMutation,
  CareEnrollmentFragment,
} from 'shared/types/graphql';
import { StripeTerminalClinicPetParent } from 'shared/providers/StripeTerminalProvider/types/StripeTerminalClinicPetParent';
import useClinicUser from 'shared/hooks/useClinicUser';
import PetInfoHeader from '../CareWizardModal/PaymentHelperComponents/PetInfoHeader';
import { IFormValues, IManageMembershipRequestInfo, PaymentMethod } from './types';
import { GA4Events } from 'shared/enums/GA4Events';
import useGA from 'shared/hooks/useGA';
import useZendesk, { ZENDESK_TICKET_BASE_URL } from 'shared/hooks/useZendesk';
import { parseManageMembershipRequestInfoForZendesk } from './utils';
import SupportForm from './components/SupportForm';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import { Modal, ModalCloseButton } from '@televet/kibble-ui/build/components/Modal';
import CancelMembershipStep from './components/CancelMembershipStep';
import CancellationCalculatorStep from './components/CancellationCalculatorStep';
import { Box } from '@televet/kibble-ui/build/chakra';
import { ManageMembershipStep, useManageMembershipContext } from './context/ManageMembershipContext';
import GetHelpStep from './components/GetHelpStep';
import { usePetBenefits } from '../../hooks/usePetBenefits';
import { dollarsToCents } from '../../utils/currency';
import { PaymentStep } from './components/PaymentStep';

export interface IManageMembershipModalProps {
  isOpen: boolean;
  onClose: () => void;
  pet?: SidePanelParentPetDataFragment;
  clinicPetParent?: StripeTerminalClinicPetParent;
  enrollment?: CareEnrollmentFragment;
  subscription?: CareSubscriptionFragment;
  stripeCustomerId?: string | null;
  pets?: SidePanelParentPetDataFragment[];
  refetchPetData: () => void;
  onSelectPet: (petId: string) => void;
}

interface ISubmitValues {
  requestInfo: IManageMembershipRequestInfo;
}

const ManageMembershipModal = ({
  isOpen,
  onClose,
  pet,
  clinicPetParent,
  enrollment,
  subscription,
  stripeCustomerId,
  pets,
  refetchPetData,
  onSelectPet,
}: IManageMembershipModalProps): JSX.Element => {
  const { clinicUser, currentClinicId, currentClinic } = useClinicUser();
  const {
    currentStep,
    handleNext,
    submitText,
    setSubmitText,
    form,
    setForm,
    balanceDue,
    resetContext,
    setIsSubmitting,
    setHasBenefitUsages,
  } = useManageMembershipContext();

  const toast = useToast();
  const { gaTrack } = useGA();
  const { benefits, hasBenefitUsages } = usePetBenefits(pet?.organizationPet?.id || '');

  useEffect(() => {
    setHasBenefitUsages(hasBenefitUsages);
  }, [hasBenefitUsages, setHasBenefitUsages]);

  const { data: clinicUserData } = useGetClinicUsersQuery({
    variables: {
      clinicId: currentClinicId || '',
    },
  });

  const { data: carePlanData } = useGetClinicCarePlansQuery({
    variables: {
      where: {
        participatingClinics: {
          some: {
            id: {
              equals: currentClinicId || '',
            },
          },
        },
      },
    },
    skip: !currentClinicId,
  });

  const { createTicket, createTicketComment } = useZendesk(clinicUser);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { isValid },
  } = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues: {
      ...form,
      pointOfContactId: form.pointOfContactId || clinicUser?.id,
    },
    reValidateMode: 'onBlur',
    validateCriteriaMode: 'all',
  });

  const watchSelectedUser = watch('pointOfContactId');
  const watchRequestType = watch('requestType');
  const watchCancelReason = watch('cancelReason');
  const [inputFieldTextError, setInputFieldTextError] = useState('');

  const updateSubmitText = useCallback(
    (submitText: string): void => {
      setSubmitText(submitText);
      setValue('requestInfo.inputFieldText', submitText);
    },
    [setSubmitText, setValue],
  );

  const handleClose = (): void => {
    gaTrack(GA4Events.MEMBERSHIP_MANAGE_CLOSE);
    onClose();
  };

  const resetAndClose = (): void => {
    reset();
    resetContext();
    onClose();
  };

  const selectedEmail = useMemo(() => {
    return clinicUserData?.users?.find((user) => user?.id === watchSelectedUser)?.email || '';
  }, [clinicUserData?.users, watchSelectedUser]);

  const createTicketAndComment = async (data: IFormValues): Promise<void> => {
    setIsSubmitting(true);

    const currentPlan = carePlanData?.findFirstCarePlanProviderGroup?.associatedPlans?.find(
      (plan: ClinicCarePlanFragment) => plan.id === enrollment?.planId,
    );

    const newPlan = carePlanData?.findFirstCarePlanProviderGroup?.associatedPlans?.find(
      (plan: ClinicCarePlanFragment) => plan.id === data.upgradePlanId,
    );

    const submitData: ISubmitValues = {
      requestInfo: {
        clinic: {
          clinicName: currentClinic?.name || '',
          clinicId: currentClinicId || '',
        },
        clinicUser: {
          name: clinicUser?.displayName || '',
          email: clinicUser?.email || '',
          id: clinicUser?.id || '',
        },
        pointOfContact: {
          name: clinicUserData?.users?.find((user) => user?.id === watchSelectedUser)?.displayName || '',
          email: selectedEmail || '',
          id: watchSelectedUser || '',
        },
        client: {
          clientName: `${clinicPetParent?.firstName} ${clinicPetParent?.lastName}` || '',
          clientId: clinicPetParent?.id || '',
        },
        stripeCustomer: {
          stripeCustomerId: stripeCustomerId || '',
        },
        trueUpInfo: {
          // because textInput doesn't take have an options prop to take an onChange when it is in the Controller component we must use a state variable outside of the form
          trueUpAmount: balanceDue || 0.0,
          last4: subscription?.stripePaymentMethod?.last4 || '',
        },
        patient: {
          patientName: pet?.name || '',
          patientId: pet?.id || '',
        },
        currentPlan: {
          planId: enrollment?.planId || '',
          planName: currentPlan?.flowAlias || currentPlan?.title || '',
          enrollmentStatus: enrollment?.status,
          enrollmentId: enrollment?.id,
          subscriptionStatus: subscription?.paymentStatus || null,
          careStripeSubscriptionId: subscription?.id || '',
        },
        newPlan: {
          planId: data.upgradePlanId || '',
          planName: newPlan?.flowAlias || newPlan?.title || '',
        },
        transferPet: {
          transferPetId: data.transferPetId || '',
          transferPetName: pets?.find((pet) => pet.id === data.transferPetId)?.name || '',
        },
        requestType: data.requestType,
        cancelReason: data.cancelReason,
        inputFieldText: submitText,
        paymentSchedule:
          data.paymentMethod === PaymentMethod.SendInvoiceLink ? PaymentSchedule.Full : data.paymentSchedule,
        paymentMethod:
          data.paymentSchedule === PaymentSchedule.OverTime ? PaymentMethod.CardOnFile : data.paymentMethod,
      },
    };

    try {
      const response = await createTicket(parseManageMembershipRequestInfoForZendesk(submitData.requestInfo, true));
      // get the ticket id from the response ex: { ... url: 'https://care.zendesk.com/api/v2/tickets/123.json'}
      const ticketId = response?.url?.split('/')?.[6]?.split('.')?.[0];
      if (ticketId) {
        try {
          await createTicketComment(
            ticketId,
            parseManageMembershipRequestInfoForZendesk(submitData.requestInfo, false),
          );

          try {
            const { requestInfo } = submitData;

            await createEnrollmentSupportEvent({
              variables: {
                data: {
                  requester: { connect: { id: requestInfo.clinicUser.id } },
                  pointOfContact: { connect: { id: requestInfo.pointOfContact.id } },
                  manageMembershipRequestType: requestInfo.requestType,
                  cancelReason: requestInfo.cancelReason,
                  manageMembershipDescription: submitText,
                  amount: requestInfo.trueUpInfo.trueUpAmount,
                  paymentSchedule: requestInfo.paymentSchedule,
                  carePlanEnrollment: { connect: { id: enrollment?.id } },
                  zendeskTicketUrl: `${ZENDESK_TICKET_BASE_URL}/${ticketId}`,
                  currentPlan: {
                    connect: {
                      id: requestInfo.currentPlan.planId || undefined,
                    },
                  },
                  ...(!!requestInfo.newPlan?.planId.length && {
                    newPlan: {
                      connect: {
                        id: requestInfo.newPlan.planId,
                      },
                    },
                  }),
                },
              },
            });

            resetAndClose();
            gaTrack(GA4Events.MEMBERSHIP_MANAGE_SUBMIT);
            toast({
              title: 'Success!',
              status: 'success',
              description: 'Support request received.',
              duration: 5000,
              buttonProps: {
                buttonText: 'Dismiss',
                buttonPosition: 'bottomLeft',
                onClick: (): void => {
                  toast.closeAll();
                },
              },
              onClose: (): void => {
                gaTrack(GA4Events.MEMBERSHIP_MANAGE_SUCCESS_DISMISS);
              },
            });
          } catch (error) {
            console.error('Error creating EnrollmentSupportEvent', error);
          }
        } catch (error) {
          console.error('Error creating ticket comment', error);
        }
      }
    } catch (error) {
      console.error('Error creating ticket', error);
      toast({
        title: 'Error',
        status: 'error',
        description: 'Error creating ticket. Please try again.',
        duration: 5000,
        buttonProps: {
          buttonText: 'Dismiss',
          buttonPosition: 'bottomLeft',
          onClick: (): void => {
            toast.closeAll();
          },
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const [createEnrollmentSupportEvent] = useCreateEnrollmentSupportEventMutation();
  const [updateCarePlanEnrollment] = useUpdateCarePlanEnrollmentMutation();

  const showMissingInformationErrorToast = (): void => {
    setIsSubmitting(false);
    toast({
      title: 'Something went wrong',
      status: 'warning',
      description: 'Some information needed to submit your request is missing. Please fill it out and try again.',
      duration: 5000,
      buttonProps: {
        buttonText: 'Dismiss',
        buttonPosition: 'bottomLeft',
        onClick: (): void => {
          toast.closeAll();
        },
      },
    });
  };

  const handleRenewalOptOut = async (data: IFormValues): Promise<void> => {
    setIsSubmitting(true);
    if (!clinicUser || !data.cancelReason || !watchSelectedUser) {
      showMissingInformationErrorToast();
      return;
    }

    try {
      await Promise.all([
        createEnrollmentSupportEvent({
          variables: {
            data: {
              requester: { connect: { id: clinicUser.id } },
              pointOfContact: { connect: { id: watchSelectedUser } },
              manageMembershipRequestType: data.requestType,
              cancelReason: data.cancelReason,
              manageMembershipDescription: submitText,
              carePlanEnrollment: { connect: { id: enrollment?.id } },
              status: SupportEventStatus.Completed,
            },
          },
        }),
        updateCarePlanEnrollment({
          variables: {
            where: { id: enrollment?.id },
            data: { autoRenews: false },
          },
        }),
      ]);

      toast({
        title: 'Success!',
        status: 'success',
        description: 'Renewal Opt-out has been recorded. No further action is needed.',
        duration: 5000,
        buttonProps: {
          buttonText: 'Dismiss',
          buttonPosition: 'bottomLeft',
          onClick: (): void => {
            toast.closeAll();
          },
        },
      });
      await refetchPetData();
      resetAndClose();
    } catch (error) {
      toast({
        title: 'Something went wrong',
        status: 'warning',
        description: 'Renewal Opt-out was not recorded. Please try again.',
        duration: 5000,
        buttonProps: {
          buttonText: 'Dismiss',
          buttonPosition: 'bottomLeft',
          onClick: (): void => {
            toast.closeAll();
          },
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const [performCarePlanEnrollmentUpgrade] = usePerformCarePlanEnrollmentUpgradeMutation();

  const handleUpgrade = async (data: IFormValues): Promise<void> => {
    setIsSubmitting(true);
    if (!enrollment?.id || !clinicUser || !watchSelectedUser || balanceDue === undefined) {
      showMissingInformationErrorToast();
      return;
    }

    const newPlan = carePlanData?.findFirstCarePlanProviderGroup?.associatedPlans?.find(
      (plan: ClinicCarePlanFragment) => plan.id === data.upgradePlanId,
    );

    if (!newPlan) {
      showMissingInformationErrorToast();
      return;
    }

    try {
      const res = await performCarePlanEnrollmentUpgrade({
        variables: {
          data: {
            carePlanEnrollmentId: enrollment?.id,
            newPlanId: newPlan.id,
            feeAmount: dollarsToCents(balanceDue),
          },
        },
      });

      const success = res.data?.performCarePlanEnrollmentUpgrade?.success;
      const upgradeInvoiceId = res.data?.performCarePlanEnrollmentUpgrade?.upgradeInvoiceId;

      if (!!success) {
        await createEnrollmentSupportEvent({
          variables: {
            data: {
              requester: { connect: { id: clinicUser.id } },
              pointOfContact: { connect: { id: watchSelectedUser } },
              manageMembershipRequestType: data.requestType,
              cancelReason: data.cancelReason,
              manageMembershipDescription: submitText,
              carePlanEnrollment: { connect: { id: enrollment?.id } },
              status: SupportEventStatus.Completed,
              ...(enrollment.planId && {
                currentPlan: {
                  connect: {
                    id: enrollment.planId,
                  },
                },
              }),
              newPlan: {
                connect: {
                  id: newPlan.id,
                },
              },
              amount: balanceDue,
              relatedInvoice: {
                connect: {
                  id: upgradeInvoiceId,
                },
              },
            },
          },
        });

        toast({
          title: 'Success!',
          status: 'success',
          description: 'Care Membership has been upgraded successfully.',
          duration: 5000,
          buttonProps: {
            buttonText: 'Dismiss',
            buttonPosition: 'bottomLeft',
            onClick: (): void => {
              toast.closeAll();
            },
          },
        });

        refetchPetData();
        resetAndClose();
      } else {
        const message = res.data?.performCarePlanEnrollmentUpgrade?.message;
        toast({
          title: 'Something went wrong',
          status: 'warning',
          description: `Care membership upgrade failed. ${message}`,
          duration: 5000,
          buttonProps: {
            buttonText: 'Dismiss',
            buttonPosition: 'bottomLeft',
            onClick: (): void => {
              toast.closeAll();
            },
          },
        });
      }
    } catch {
      toast({
        title: 'Something went wrong',
        status: 'warning',
        description: `Care membership upgrade failed. Please try again.`,
        duration: 5000,
        buttonProps: {
          buttonText: 'Dismiss',
          buttonPosition: 'bottomLeft',
          onClick: (): void => {
            toast.closeAll();
          },
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const [transferCareMembership] = useTransferCareSubscriptionMutation();

  const handleTransfer = async (data: IFormValues): Promise<void> => {
    setIsSubmitting(true);
    if (!clinicUser || !watchSelectedUser || !enrollment?.id || !pet || !data.transferPetId) {
      showMissingInformationErrorToast();
      return;
    }

    const transferPet = pets?.find((pet) => pet.id === data.transferPetId);

    try {
      await Promise.all([
        transferCareMembership({
          variables: {
            data: {
              careStripeSubscriptionId: subscription?.id || '',
              carePlanEnrollmentId: enrollment?.id,
              currentClinicPetId: pet.id,
              newClinicPetId: data.transferPetId,
            },
          },
        }),
        createEnrollmentSupportEvent({
          variables: {
            data: {
              requester: { connect: { id: clinicUser.id } },
              pointOfContact: { connect: { id: watchSelectedUser } },
              manageMembershipRequestType: data.requestType,
              cancelReason: data.cancelReason,
              manageMembershipDescription: submitText,
              carePlanEnrollment: { connect: { id: enrollment?.id } },
              status: SupportEventStatus.Completed,
              transferFromPet: { connect: { id: pet.organizationPet?.id } },
              transferToPet: { connect: { id: transferPet?.organizationPet?.id } },
            },
          },
        }),
      ]);

      toast({
        title: 'Success!',
        status: 'success',
        description: 'Membership has been transferred successfully. No further action is needed.',
        duration: 5000,
        buttonProps: {
          buttonText: 'Dismiss',
          buttonPosition: 'bottomLeft',
          onClick: (): void => {
            toast.closeAll();
          },
        },
      });

      resetAndClose();
      onSelectPet(data.transferPetId);
    } catch (error) {
      toast({
        title: 'Something went wrong',
        status: 'warning',
        description: 'Membership transfer failed. Please try again.',
        duration: 5000,
        buttonProps: {
          buttonText: 'Dismiss',
          buttonPosition: 'bottomLeft',
          onClick: (): void => {
            toast.closeAll();
          },
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const submitForm = async (data: IFormValues): Promise<void> => {
    setForm(data);

    if (watchRequestType === ManageMembershipRequestType.Cancel) {
      if (!hasBenefitUsages) {
        gaTrack(GA4Events.MEMBERSHIP_MANAGE_CALCULATOR_SKIPPED);
        handleNext(ManageMembershipStep.CancelMembership);
      } else {
        handleNext(ManageMembershipStep.CancellationCalculator);
      }
      return;
    }

    if (isValid) {
      if (watchRequestType === ManageMembershipRequestType.Upgrade) {
        await handleUpgrade(data);
        return;
      }

      if (watchRequestType === ManageMembershipRequestType.Transfer) {
        await handleTransfer(data);
        return;
      }

      if (submitText.length > 0) {
        if (watchRequestType === ManageMembershipRequestType.OptOut) {
          await handleRenewalOptOut(data);
          return;
        }

        // All other request types
        await createTicketAndComment(data);
      } else {
        setIsSubmitting(false);
        setInputFieldTextError('Please add a description before submitting');
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} scrollBehavior="outside" isCentered={false}>
      <PetInfoHeader
        currentStep={1}
        // steps are not shown but currentStep cannot be undefined
        accountCredit={undefined}
        percentDiscount={undefined}
        title="Manage Care Membership"
        pet={pet}
      />
      <ModalCloseButton data-testid="manage-membership-close-button" />
      <Box as="form">
        <Box display={currentStep === ManageMembershipStep.SupportForm ? 'block' : 'none'}>
          <SupportForm
            carePlanId={enrollment?.planId}
            careEnrollment={enrollment}
            plans={carePlanData?.findFirstCarePlanProviderGroup?.associatedPlans}
            pets={pets}
            subscription={subscription}
            updateSubmitText={(text): void => updateSubmitText(text)}
            inputFieldTextError={inputFieldTextError}
            setInputFieldTextError={(error): void => setInputFieldTextError(error)}
            selectedEmail={selectedEmail}
            watchRequestType={watchRequestType}
            watchCancelReason={watchCancelReason}
            control={control}
            setValue={setValue}
            submitForm={handleSubmit(submitForm)}
            isValid={isValid}
            handleSubmit={handleSubmit}
          />
        </Box>
        {currentStep === ManageMembershipStep.CancellationCalculator && (
          <CancellationCalculatorStep
            enrollmentId={enrollment?.id || ''}
            benefitUsages={benefits}
            organizationPetId={pet?.organizationPet?.id || ''}
            pet={pet}
            setValue={setValue}
          />
        )}
        {currentStep === ManageMembershipStep.PaymentStep && (
          <PaymentStep
            stripeCustomerId={stripeCustomerId}
            subscriptionPaymentMethodId={subscription?.stripePaymentMethod?.stripeId}
            nextBillingDate={subscription?.nextBillingDate}
            enrollmentId={enrollment?.id}
            clinicPetParent={clinicPetParent}
            setValue={setValue}
          />
        )}
        {currentStep === ManageMembershipStep.CancelMembership && (
          <CancelMembershipStep
            clinicPetId={pet?.id || ''}
            careStripeSubscriptionId={subscription?.id || ''}
            enrollmentId={enrollment?.id || ''}
            enrollment={enrollment}
            requesterId={clinicUser?.id || ''}
            handleClose={resetAndClose}
            refetchPetData={refetchPetData}
          />
        )}
        {currentStep === ManageMembershipStep.GetHelp && (
          <GetHelpStep
            selectedEmail={selectedEmail}
            submitRequest={(): Promise<void> => createTicketAndComment(form)}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ManageMembershipModal;
