import { AppointmentDateSegment, AppointmentDateSegmentConditionValue } from '../components/AppointmentDateSegment';
import { AppointmentTypeSegment } from '../components/AppointmentTypeSegment';
import { PetAgeSegment } from '../components/PetAgeSegment';
import { PetSpeciesSegment } from '../components/PetSpeciesSegment';
import {
  ClinicConditionalSegment,
  SegmentPetAgeYoungerOrOlder,
  SegmentPetAgeUnit,
  AvailableSegments,
} from '../types/conditionalSegments.types';
import { SegmentIds } from '@televet/shared-types/segment';

export const availableSegments: AvailableSegments = {
  [ClinicConditionalSegment.petSpecies]: {
    segmentId: SegmentIds.petSpecies,
    name: ClinicConditionalSegment.petSpecies,
    label: 'Patient Species',
    component: PetSpeciesSegment,
    initialValues: {
      species: [],
    },
    hasValidData: (data) => {
      return data?.species && data.species.length > 0;
    },
  },
  [ClinicConditionalSegment.petAge]: {
    segmentId: SegmentIds.petAge,
    name: ClinicConditionalSegment.petAge,
    label: 'Patient Age',
    component: PetAgeSegment,
    initialValues: {
      unit: SegmentPetAgeUnit.months,
      youngerOrOlder: SegmentPetAgeYoungerOrOlder.younger,
      amount: 12,
    },
    hasValidData: (data) => {
      return data?.amount > 0 && !!data?.unit && data?.youngerOrOlder;
    },
  },
  [ClinicConditionalSegment.appointmentDate]: {
    segmentId: SegmentIds.appointmentDate,
    label: 'Appointment Date',
    name: ClinicConditionalSegment.appointmentDate,
    component: AppointmentDateSegment,
    initialValues: {
      appointmentGte: null,
      appointmentLte: null,
      relationFilter: 'some',
    },
    parser: ({
      appointmentGte,
      appointmentLte,
      relationFilter,
    }: {
      appointmentGte: string;
      appointmentLte: string;
      relationFilter: string;
    }): AppointmentDateSegmentConditionValue => {
      return {
        appointmentGte: new Date(appointmentGte),
        appointmentLte: new Date(appointmentLte),
        relationFilter,
      };
    },
    hasValidData: (data) => {
      return !!data?.appointmentGte && !!data?.appointmentLte;
    },
  },
  [ClinicConditionalSegment.appointmentType]: {
    segmentId: SegmentIds.appointmentType,
    name: ClinicConditionalSegment.appointmentType,
    label: 'Appointment Type',
    component: AppointmentTypeSegment,
    initialValues: {
      appointmentType: [],
    },
    hasValidData: (data) => {
      return data?.appointmentType && data.appointmentType.length > 0;
    },
  },
};

export const availableSegmentsValues = Object.values(availableSegments);

export const MAX_RECIPIENTS = 15000;
